import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router'
import { JWTService } from '../../services/jwt.service'

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private _router: Router, private _jwtService: JWTService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const jwtToken = this._jwtService.getToken
    if (jwtToken) {
      return true
    }
    let cc = localStorage.getItem('currentLan')
    let lang = cc && cc !== 'en' ? cc : ''
    this._router.navigate([`/${lang}`]) //NAV TO HOME
  }
}
