import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { CookieService } from 'ngx-cookie-service'
import { LocalStorageService } from '../../../services/localStorage'

@Component({
  templateUrl: './login-admin.component.html',
  styleUrls: ['./login-admin.component.scss'],
})
export class LoginAdminComponent implements OnInit, OnDestroy {
  constructor(private activeRouter: ActivatedRoute, private router: Router, private _cookieService: CookieService, private localStorageService: LocalStorageService) {}
  ngOnDestroy(): void {}

  /**
   * lOGIN USER BY ADMIN PANEL
   */
  ngOnInit(): void {
    if (this.localStorageService.getItem('accessToken') === null) {
      const { accessToken, refreshToken } = this.activeRouter.snapshot.params

      this.localStorageService.setItem('accessToken', accessToken)
      this._cookieService.set('refreshToken', refreshToken, 1, '/')
      this.router.navigate(['/profile/lessons'])
    } else {
      this.localStorageService.clear()
      this._cookieService.delete('refreshToken')

      const { accessToken, refreshToken } = this.activeRouter.snapshot.params

      this.localStorageService.setItem('accessToken', accessToken)
      this._cookieService.set('refreshToken', refreshToken, 1, '/')

      this.router.navigate(['/profile/lessons'])
    }
  }
}
