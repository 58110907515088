import { Component, OnDestroy } from '@angular/core'
import { AddStudentDialogComponent } from '../../../shared/popups/Student-Dialogs/add-student/add-student.component'
import { AddClassroomDialogComponent } from '../../../shared/popups/Classroom-Dialogs/add-classroom/add-classroom.component'
import { IClassroom } from '../../../models/Classroom.model'
import { EditClassroomDialogComponent } from '../../../shared/popups/Classroom-Dialogs/edit-classroom/edit-classroom.component'
import { ConfirmationPopupComponent } from '../../../shared/popups/Control-Dialogs/confirmation/confirmation.component'
import { GetClassrooms, RemoveClassroom } from '../../../store/actions/classrooms.actions'
import { AddTeacherDialogComponent } from '../../../shared/popups/Teacher-Dialogs/add-teacher/add-teacher.component'
import { openPdfLink } from '../../../helpers/utils/LessonUtil/lessonTest.util'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { select, Store } from '@ngrx/store'
import { IAppState } from '../../../store/state/app.state'
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar'
import { ActivatedRoute, Router } from '@angular/router'
import { SchoolService } from '../../../services/school.service'
import { TranslateService } from '@ngx-translate/core'
import { filter, map, takeUntil } from 'rxjs/operators'
import { EStudentActions, GetStudents, GetStudentsSuccess } from '../../../store/actions/students.actions'
import { interval, Subject } from 'rxjs'
import { ISchool } from '../../../models/School.model'
import { selectClassrooms } from '../../../store/selectors/classrooms.selectors'
import { Actions, ofType } from '@ngrx/effects'

@Component({
  templateUrl: './classrooms.component.html',
  styleUrls: ['./classrooms.component.scss'],
})
export class ClassroomsComponent implements OnDestroy {
  public loaded = false

  public user: any = null

  public school: ISchool

  public classrooms: Array<IClassroom> = []

  private unsubscribe$ = new Subject()

  constructor(
    private _dialog: MatDialog,
    private _store: Store<IAppState>,
    private _actions$: Actions,
    private _snackBar: MatSnackBar,
    private _router: Router,
    private _schoolService: SchoolService,
    private _actr: ActivatedRoute,
    public translate: TranslateService
  ) {
    this._actr.parent.parent.data.pipe(map((res) => res.user.payload)).subscribe((user: any) => {
      this.user = user
    })

    this._actr.parent.data.pipe(map((res) => res.school)).subscribe((school: ISchool) => {
      this.school = school

      this._store.dispatch(new GetClassrooms(school.id))
      this._store
        .pipe(
          select(selectClassrooms),
          takeUntil(this.unsubscribe$),
          filter((res) => !!res.classrooms.length),
          map((res) => res.classrooms)
        )
        .subscribe((classrooms: Array<IClassroom>) => {
          this.classrooms = classrooms
        })
    })

    this.loadStudents()

    // poll for student changes
    interval(1000 * 60)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.loadStudents())
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  loadStudents() {
    this._store.dispatch(new GetStudents(this.school.id))
    this._actions$.pipe(ofType<GetStudentsSuccess>(EStudentActions.GetStudentsSuccess)).subscribe(() => (this.loaded = true))
  }

  openAddStudentDialog() {
    this._dialog.open(AddStudentDialogComponent, {
      disableClose: false,
      hasBackdrop: true,
      width: '28rem',
      data: {
        schoolId: this.school.id,
        classrooms: this.classrooms,
      },
    })
  }

  openAddClassroomDialog() {
    this._dialog.open(AddClassroomDialogComponent, {
      disableClose: false,
      hasBackdrop: true,
      width: '28rem',
      data: this.school,
    })
  }

  openEditClassroomDialog(classroom: IClassroom) {
    this._dialog.open(EditClassroomDialogComponent, {
      disableClose: false,
      hasBackdrop: true,
      width: '28rem',
      data: { classroom, school: this.school },
    })
  }

  openDeleteClassroomDialog(classroom: IClassroom) {
    const dialog = this._dialog.open(ConfirmationPopupComponent, {
      panelClass: 'medium-adaptive-popup',
      closeOnNavigation: true,
      hasBackdrop: true,
      data: {
        question: 'CLASSROOM.REMOVE_CONFIRM',
        extraConfirmation: true,
      },
    })

    dialog.afterClosed().subscribe((answer) => {
      if (answer === true) {
        this._store.dispatch(new RemoveClassroom(classroom))

        this._snackBar.open(this.translate.instant('CLASSROOM.REMOVE_SUCCESS'), 'cancel', {
          duration: 2000,
        })
      }
    })
  }

  openAddTeacherDialog() {
    this._dialog.open(AddTeacherDialogComponent, {
      disableClose: false,
      hasBackdrop: true,
      width: '28rem',
      data: {
        schoolId: this.school.id,
      },
    })
  }

  goToBulkAddStudents(classroom: any) {
    this._router.navigate(['/profile/teachers/students/bulk-create'], {
      queryParams: classroom,
      skipLocationChange: true,
    })
  }

  showPdf(classroom: any) {
    this._schoolService.getStudentListPdf(classroom.schoolId, classroom.id).subscribe((res: any) => {
      if (res) {
        openPdfLink(res.pdf)
      }
    })
  }
}
