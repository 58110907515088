import { Component, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { select, Store } from '@ngrx/store'
import { IAppState } from '../../../../store/state/app.state'
import { TranslateService } from '@ngx-translate/core'
import { AllTimeLogsComponent } from '../../../../shared/popups/ProgressReports-Dialogs/all-time-logs/all-time-logs.component'
import { rangomSpinner } from '../../../../helpers/utils/spiner.util'
import { GetSubuserTimeLogs, GetStudentsTimeLogs, ResetReports } from '../../../../store/actions/progress-reports.actions'
import { selectTimeLogs } from '../../../../store/selectors/progress-reports.selectors'
import { filter, takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'
import { SortProgressReportsUtil } from '../../../../helpers/utils/sortProgressReports.util.js'
import { DeviceDetectorService } from 'ngx-device-detector'

@Component({
  selector: 'app-practice-time',
  templateUrl: './practice-time.component.html',
  styleUrls: ['./practice-time.component.scss'],
})
export class PracticeTimeComponent implements OnInit, OnDestroy, OnChanges {
  @Input() type
  @Input() classroom
  @Input() sortMethod

  public loaded = false
  public loaderClass: string = rangomSpinner()
  public timeLogs

  private unsubscribe$ = new Subject<void>()

  constructor(private dialog: MatDialog, private _store: Store<IAppState>, public translate: TranslateService, private deviceService: DeviceDetectorService) {}

  isMobileScreen: boolean = false

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    if (this.deviceService.isMobile()) {
      this.checkScreenSize()
    }
  }

  private checkScreenSize(): void {
    this.isMobileScreen = window.outerHeight < 500 || window.outerWidth < 500
  }

  ngOnInit() {
    if (this.deviceService.isMobile()) {
      this.checkScreenSize()
    }
    this._store
      .pipe(
        select(selectTimeLogs),
        filter((timeLogs) => timeLogs.length !== 0),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((res) => {
        this.timeLogs = SortProgressReportsUtil.sort(this.sortMethod, res)
        this.loaded = true
      })
  }

  ngOnDestroy() {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()

    // reset state
    this._store.dispatch(new ResetReports())
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loadReports()
  }

  loadReports() {
    this.loaded = false

    if (this.type === 'parent') {
      this._store.dispatch(new GetSubuserTimeLogs())
    } else if (this.type === 'school') {
      this._store.dispatch(new GetStudentsTimeLogs(this.classroom.schoolId, this.classroom.id))
    }
  }

  openAllPracticeTimePopup(log) {
    this.dialog.open(AllTimeLogsComponent, {
      panelClass: 'all-test-reports-popup',
      closeOnNavigation: true,
      hasBackdrop: true,
      data: { log },
    })
  }
}
