import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, ElementRef, ViewChild } from '@angular/core'
import { IQuestion } from '../../../models/Question.model'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { IAnswer } from '../../../models/Answer.model'
import { AudioService } from 'src/app/services/audio.service'
import { ReportProblemPopupComponent } from '../../popups/Report-Dialogs/report-problem/report-problem.component'
import { DefaultPopupSettings } from '../../../models/Dialog.model'
import { RandomizeService } from '../../../services/randomize.service'
import { ICourse } from '../../../models/Course.model'
import { TranslateService } from '@ngx-translate/core'
import { getLanguageMap } from '../../../helpers/utils/languageMap.util'
import { LocalizHelperService } from 'src/app/services/localizHelper.service'

@Component({
  selector: 'text-to-text',
  templateUrl: './text-to-text.component.html',
  styleUrls: ['./text-to-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextToTextGameComponent implements OnInit, OnChanges {
  @Input() question: IQuestion
  @Input() test: boolean
  @Input() game: boolean
  @Input() translation: string
  @Input() course: ICourse
  @Input() localizations: Array<object>
  @Output() success = new EventEmitter()
  @Output() failed = new EventEmitter()

  @ViewChild('audiohelp') audiohelp: ElementRef

  public currentlang

  constructor(private _dialog: MatDialog, private _RandomizeService: RandomizeService, private translate: TranslateService, private _lhServise: LocalizHelperService, private audioService: AudioService) {}

  public questionId: number
  public answers = []
  public selectedAnswer: IAnswer = null
  public checked = false
  public languageMap = getLanguageMap()

  public dir = 'ltr'
  public dir2 = 'ltr'

  ngOnChanges() {
    if (this.translation === 'ar' || this.translation === 'fa' || this.translation === 'he') {
      this.dir2 = 'rtl'
    } else {
      this.dir2 = 'ltr'
    }

    this.selectedAnswer = null
    this.checked = false

    if (this.answers.length > 0 && this.question.id != this.questionId) {
      this.answers = []
      this.createAnswersArray(this.question)
    }
  }

  ngOnInit(): void {
    if (this.course.slug === 'arabic' || this.course.slug === 'persian' || this.course.slug === 'hebrew') {
      this.dir = 'rtl'
    }

    this.currentlang = this._lhServise.getLangForced()

    this.questionId = this.question.id
    this.createAnswersArray(this.question)
  }

  public audiohelpPlay() {
    this.audiohelp.nativeElement.play()
  }

  public createAnswersArray(question) {
    for (let i = 0; i < question.answers.length; i++) {
      const answer = {
        correct: question.answers[i].correct,
        id: question.answers[i].id,
        name: question.answers[i].name,
        pictureSrc: question.answers[i].pictureSrc,
        questionId: question.answers[i].questionId,
        text: question.answers[i].text,
        localization: question.answers[i].localization,
        localizationId: question.answers[i].localizationId,
      }
      this.answers.push(answer)
    }

    this._RandomizeService.shuffle(this.answers)
  }

  public selectAnswer(answer) {
    if (this.checked) return
    this.selectedAnswer = answer
  }

  public setAnswer(answer) {
    if (answer.correct) {
      this.success.emit({ question: this.question, answer })
    } else {
      this.failed.emit({ question: this.question, answer })
    }
  }

  public checkAnswer() {
    this.checked = true
    if (this.selectedAnswer.correct) {
      this.audioService.playSoundCorrect()
    } else {
      this.audioService.playSoundIncorrect()
    }
  }

  public get checkRightAnswer() {
    return this.selectedAnswer.correct
  }

  public openReportPopup(question) {
    this._dialog.open(ReportProblemPopupComponent, new DefaultPopupSettings(question))
  }

  public get helpText() {
    let helpText = ''

    if (this.question.questionType.slug === 'ttt_target') {
      helpText = this.translate.instant('GAME.TEXT_TO_TEXT.HELP_TEXT_TARGET')
    } else {
      helpText = this.translate.instant('GAME.TEXT_TO_TEXT.HELP_TEXT_LOCAL')
    }

    const translation = this.translation ? this.languageMap.find((l) => l.abbr === this.translation)?.name : 'English'
    return helpText.replace('%target%', this.course.name).replace('%translation%', translation)
  }
}
