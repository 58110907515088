import { Component, Inject, OnInit, OnDestroy } from '@angular/core'
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog'
import { IAppState } from 'src/app/store/state/app.state'
import { Store, select } from '@ngrx/store'
import { selectCurrentLesson } from 'src/app/store/selectors/lessons.selectors'
import { Router } from '@angular/router'
import { ILesson } from 'src/app/models/Lesson.model'
import { IUser } from 'src/app/models/User.model'
import { selectCurrentUser } from 'src/app/store/selectors/user.selectors'
import { NotificationService } from 'src/app/services/notification.service'
import { GetLesson } from 'src/app/store/actions/lessons.actions'
import { ISubuser } from 'src/app/models/Subuser.model'
import { selectCurrentSubuser } from 'src/app/store/selectors/subuser.selectors'
import { TranslateService } from '@ngx-translate/core'
// import { playAudio } from 'src/app/utils/audio.util';
import { AudioService } from 'src/app/services/audio.service'
import { Subject, Subscription } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { TestResultComponent } from '../test-result/test-result.component'
import { selectTestReport } from 'src/app/store/selectors/statistics.selectors'
import { GetTestReport } from 'src/app/store/actions/statistics.actions'
import { TestReportService } from 'src/app/services/testReport.service'
import { LocalStorageService } from '../../../../services/localStorage'

@Component({
  templateUrl: 'lesson-end.component.html',
  styleUrls: ['lesson-end.component.scss'],
})
export class LessonEndPopupComponent implements OnInit, OnDestroy {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<LessonEndPopupComponent>,
    private _store: Store<IAppState>,
    private _router: Router,
    private notification: NotificationService,
    public translate: TranslateService,
    private dialog: MatDialog,
    private testReport: TestReportService,
    private localStorageService: LocalStorageService,
    private audioService: AudioService
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
    clearTimeout(this.timer)
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }

  private subscription: Subscription

  public user: IUser
  public subuser: ISubuser
  private unsubscribe$ = new Subject()
  public subuserStars = this.data.subuserStars
  public progress = this.data.progress
  public newDino = this.data.dino
  public completed = this.data.completed
  public test = []
  public reports
  public isHasReports = false
  public timer
  public backLink = '/profile/lessons'
  public isTeacherArea = false

  ngOnInit() {
    this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentUser)).subscribe((user) => {
      if (user) {
        this.user = user
        if (['teacherAdmin', 'teacher'].includes(this.user.userType)) {
          this.backLink = '/profile/lesson-plan'
          this.isTeacherArea = true
        }
      }
    })

    this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentSubuser)).subscribe((subuser) => {
      if (subuser) this.subuser = subuser
    })

    this.getTestReport()

    if (this.progress >= 80) {
      // playAudio('/assets/images/sounds/COMPLETE.mp3');
      this.audioService.playAudio('complete', '/assets/images/sounds/COMPLETE.mp3', true, false)
    }
  }

  public lesson: ILesson
  public lesson$ = this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentLesson)).subscribe((lesson) => {
    this.lesson = lesson
  })

  repeat() {
    this.dialogRef.close({ status: 'repeat' })
  }

  back() {
    this.dialogRef.close({ status: 'back' })
    this._router.navigateByUrl(this.backLink)
  }

  public showResult = () => {
    const dialog = this.dialog.open(TestResultComponent, {
      panelClass: 'big-popup',
      closeOnNavigation: true,
      hasBackdrop: true,
      data: { reportData: this.test[0], lessonName: this.lesson.name, nickname: this.subuser.name },
    })
  }

  getTestReport = () => {
    const course = JSON.parse(this.localStorageService.getItem(`${this.subuser.id}`))

    const currentSubuser = {
      subuserId: this.subuser.id,
      courseId: course.id,
    }
    setTimeout(() => {
      this.subscription = this.testReport.getTestReport(currentSubuser).subscribe((report) => {
        if (report) {
          this.reports = report
          for (let i = 0; i < this.reports.length; i++) {
            for (let k = 0; k < this.reports[i].lessons.length; k++) {
              if (this.reports[i].lessons[k].name == this.lesson.name && this.reports[i].lessons[k].reports) {
                const lastReport = this.reports[i].lessons[k].reports.length - 1
                this.test.push(this.reports[i].lessons[k].reports[lastReport])
                this.isHasReports = true
              }
            }
          }
        }
      })
    }, 2000)
  }
}
