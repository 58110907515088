<div class="dinosaur">
  <div class="actions">
    <div class="close" (click)="dialogRef.close()"><img src="/assets/images/icons/vectorCross.svg" alt="vectorCross" /></div>
    <div class="title">{{ 'DINOS.TITLE' | translate }}</div>
  </div>
  <div class="container scroll-modern">
    <div class="inner-box">
      <div appSounds *ngFor="let dino of dinosaurs; let i = index" (click)="openDinosaurViewPopup(dino)" class="dino">
        <ng-container *ngIf="dino.completed.length">
          <ng-lottie *ngIf="options" class="lottie_p" [options]="options[i]"></ng-lottie>
          <span class="color-white">{{ dino.name }}</span>
        </ng-container>
        <ng-container *ngIf="!dino.completed.length">
          <img src="/assets/images/icons/blueEgg.svg" [alt]="dino.name" />
          <span class="color-white">{{ dino.name }}</span>
        </ng-container>
      </div>
    </div>
  </div>
</div>
