import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from 'src/environments/environment'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  private gatewayUrl: string
  private mode: string

  constructor(private http: HttpClient) {
    this.gatewayUrl = environment.gatewayUrl
    this.mode = environment.production ? 'live' : 'stage'
  }

  public activateStoppedSubscription(request): Observable<any> {
    return this.http.post(`${this.gatewayUrl}stripe/activateStoppedSubscription`, request)
  }

  // Send request for process the payment
  public handlePayment(request: any): Observable<any> {
    return this.http.post(`${this.gatewayUrl}stripe/subscription/create`, request)
  }

  public getCoupon(code: string) {
    return this.http.get(`${this.gatewayUrl}stripe/coupon/${code}`)
  }

  public getPromotionCode(code: string) {
    return this.http.get(`${this.gatewayUrl}stripe/promotionCode/${code}`)
  }

  // Get list of active plans
  public getPlans() {
    return this.http.get(`${this.gatewayUrl}plans`)
  }

  // Get list of active plans
  public getAllPlans(method, period = 'month') {
    return this.http.get(`${this.gatewayUrl}managePayments/getPlans/all`, {
      params: {
        gateway: method,
        period: period,
        mode: this.mode,
      },
    })
  }

  // Update User Plan
  public updatePlan(request: any) {
    return this.http.post(`${this.gatewayUrl}updateSubscription`, request)
  }

  public getPlanById(planId: number) {
    return this.http.get(`${this.gatewayUrl}plan/${planId}`)
  }

  public getCustomer(userId) {
    return this.http.get(`${this.gatewayUrl}managePayments/getCustomer/`, {
      params: { userId },
    })
  }

  public getPaymentMethod(customerStripeId: string) {
    return this.http.get(`${this.gatewayUrl}stripe/customerPaymentMethod/${customerStripeId}`)
  }

  public updatePaymentMethod(request: any) {
    return this.http.post(`${this.gatewayUrl}stripe/updateCustomerPaymentMethod`, request)
  }

  public upgradeSubscription(request: any) {
    return this.http.post(`${this.gatewayUrl}stripe/subscription/upgrade`, request)
  }

  public getStripeAndPaypalCourseId(courseId) {
    return this.http.get(`${this.gatewayUrl}managePayments/getStripeAndPaypalCourseId`, {
      params: { courseId },
    })
  }

  public getStripeAndPaypalNull(courseId) {
    return this.http.get(`${this.gatewayUrl}managePayments/getStripeAndPaypalNull`, {
      params: { courseId },
    })
  }

  public cancelSubscriptionStripe(originSubId) {
    return this.http.delete(`${this.gatewayUrl}stripe/subscription/renewal/disable`, {
      params: { id: originSubId },
    })
  }

  public cancelSubscriptionPayPal(originSubId) {
    return this.http.delete(`${this.gatewayUrl}payPal/subscription/cancel`, {
      params: { id: originSubId },
    })
  }

  public downloadInvoices({ transactionId, fullName }) {
    return this.http.get(`${this.gatewayUrl}managePayments/getInvoice`, {
      params: { transactionId, fullName },
    })
  }

  public createCheckoutSession(request: any) {
    return this.http.post(`${this.gatewayUrl}stripe/createCheckoutSession`, request)
  }
}
