import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { CookiesComponent } from './cookies.component'
import { TranslateModule } from '@ngx-translate/core'
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog'
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatIconModule } from '@angular/material/icon'

@NgModule({
  declarations: [CookiesComponent],
  imports: [TranslateModule, MatDialogModule, MatInputModule, MatButtonModule, MatIconModule, CommonModule],
  exports: [CookiesComponent],
})
export class CookiesModule {}
