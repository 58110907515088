import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from 'src/environments/environment'
import { Observable } from 'rxjs'
import { CookieService } from 'ngx-cookie-service'
import { IUser, IUserLogin } from '../models/User.model'
import { Router } from '@angular/router'
import { LocalStorageService } from './localStorage'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private gatewayUrl: string

  constructor(private http: HttpClient, private _cookieService: CookieService, private _router: Router, private localStorageService: LocalStorageService) {
    this.gatewayUrl = `${environment.gatewayUrl}auth/`
  }

  public getUserInfo(): Observable<any> {
    return this.http.get(`${this.gatewayUrl}user`)
  }

  public updateUser(request: any) {
    return this.http.post(`${this.gatewayUrl}updateUser`, request)
  }

  public signIn(request: IUserLogin): Observable<any> {
    return this.http.post(`${this.gatewayUrl}login`, request)
  }

  public restorePass(data) {
    return this.http.post(`${this.gatewayUrl}restore/password`, data)
  }

  public checkPassword(data) {
    return this.http.post(`${this.gatewayUrl}check/password`, data)
  }

  public setNewPassword(data) {
    return this.http.put(`${this.gatewayUrl}update/password`, data)
  }

  public signInGoogle(request: any): Observable<any> {
    return this.http.post(`${this.gatewayUrl}google`, request)
  }

  public signUp(request: IUser): Observable<any> {
    return this.http.post(`${this.gatewayUrl}register`, request)
  }

  public refreshToken(): Observable<any> {
    return this.http.get(`${this.gatewayUrl}refresh`, {
      params: { token: this._cookieService.get('refreshToken') },
    })
  }

  public logout() {
    let t = this.localStorageService.getItem('currentLan') || null
    this.localStorageService.clear()
    this._cookieService.delete('refreshToken', '/')
    this._cookieService.delete('gotLimitContent', '/')
    if (t) {
      this.localStorageService.setItem('currentLan', t)
    }
  }
}
