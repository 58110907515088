import { Component, Inject } from '@angular/core'
import { Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { DOCUMENT } from '@angular/common'
import { LocalStorageService } from '../../../../../services/localStorage'

@Component({
  templateUrl: './successfully.component.html',
  styleUrls: ['./successfully.component.scss'],
})
export class SubscriptionsSuccessfullyComponent {

  constructor(public translate: TranslateService, public router: Router, @Inject(DOCUMENT) private dom, private localStorageService: LocalStorageService) {}

  ngOnInit() {
    const script = this.dom.createElement('script')
    script.id = 'conversion-script'
    script.text = `{gtag('event', 'conversion', {'send_to': 'AW-957010986/2TLICMCQ-I4DEKqoq8gD'});}`
    this.dom.head.appendChild(script)
  }
}
