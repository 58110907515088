<div class="landing">
  <section class="mainComponent">
    <img src="assets/images/pictures/trees.png" class="trees" alt="trees" title="Trees" />
    <img src="assets/images/pictures/mountain.png" class="mountain" alt="mountain" title="Mountain" />
    <span class="rectRhombus orange"></span>
    <span class="rectRhombus small green"></span>
    <span class="rectCicrle red"></span>
    <span class="rectRhombus extraSmall orange"></span>
    <img src="assets/images/dinosaurs/oneDino.png" class="dino svgImage" />
    <article class="contentWrapper">
      <div class="text">
        <svg-icon class="svgImage" src="assets/images/dinosaurs/oneDino.svg"></svg-icon>
        <div class="titles">
          <h1 class="white baloo2">{{ formatWithForm('HOME.LEARN_FOR_KIDS.HEAD' | translate) }}</h1>

          <span class="white baloo2">{{ isAdvText ? ('HOME.LEARN_FOR_KIDS.HEAD_SPAN_LOCAL.' + language | translate) : formatWithForm('HOME.LEARN_FOR_KIDS.HEAD_SPAN' | translate) }}</span>

          <button class="primary orange">
            <a class="dinoLink" routerLink="{{ getURLLangPart() }}/auth/registration">{{ 'HOME.BUTTONS.FREE' | translate }} </a>
          </button>
        </div>
      </div>
    </article>
  </section>

  <section class="gray">
    <img src="assets/images/pictures/borderWhite.png" class="border bottom" alt="" />
  </section>

  <section class="learnForKids">
    <article class="contentWrapper">
      <h2 class="blue baloo2">{{ formatWithForm('HOME.LEARN_FOR_KIDS.TITLE_1' | translate) }}</h2>
      <div class="twoCols">
        <div class="text">
          <div class="description">
            <p [innerHTML]="formatWithForm('HOME.LEARN_FOR_KIDS.TEXT_1' | translate)"></p>
            <button class="primary orange">
              <a class="dinoLink" routerLink="{{ getURLLangPart() }}/auth/registration">{{ 'HOME.BUTTONS.FREE' | translate }}</a>
            </button>
          </div>
        </div>

        <div class="image">
          <img class="background" src="assets/images/pictures/langBg.svg" alt="" />
          <img class="dino" src="assets/images/dinosaurs/langDino.svg" alt="" />
          <!-- baloo2ns -->
          <img class="baloon" src="assets/images/languageBaloons/{{ language }}.png" alt="baloon-with-{{ language }}-flag" />
          <!-- baloo2ns end -->
        </div>
      </div>
    </article>
  </section>

  <section class="learnForKidsReverse">
    <article class="contentWrapper">
      <div class="twoCols reverse">
        <div class="text">
          <div class="description">
            <p>{{ formatWithForm('HOME.LEARN_FOR_KIDS.TEXT_2' | translate) }}</p>
            <button class="primary orange">
              <a class="dinoLink" routerLink="{{ getURLLangPart() }}/auth/registration">{{ 'HOME.BUTTONS.FREE' | translate }}</a>
            </button>
          </div>
        </div>

        <div class="image">
          <img src="assets/images/dinosaurs/dinoComputer.svg" alt="" />
        </div>
      </div>
    </article>
  </section>

  <section class="video">
    <img src="assets/images/pictures/borderWhite.png" alt="border" class="border top" />
    <img src="assets/images/pictures/borderWhite.png" alt="border" class="border bottom" />
    <article class="contentWrapper">
      <video controls controlsList="nodownload" *ngIf="currentLang == 'ua'">
        <source src="https://dinolingo-shared.s3.amazonaws.com/video/local/wid_ukrainian.mp4" type="video/mp4" />
      </video>
      <video controls controlsList="nodownload" *ngIf="currentLang == 'ru'">
        <source src="https://dinolingo-shared.s3.amazonaws.com/video/local/wid_russian.mp4" type="video/mp4" />
      </video>
      <video controls controlsList="nodownload" *ngIf="currentLang == 'zh'">
        <source src="https://dinolingo-shared.s3.amazonaws.com/video/local/wid_chinese_mandarin.mp4" type="video/mp4" />
      </video>
      <video controls controlsList="nodownload" *ngIf="currentLang == 'fr'">
        <source src="https://dinolingo-shared.s3.amazonaws.com/video/local/wid_french.mp4" type="video/mp4" />
      </video>
      <video controls controlsList="nodownload" *ngIf="currentLang == 'hi'">
        <source src="https://dinolingo-shared.s3.amazonaws.com/video/local/wid_hindi.mp4" type="video/mp4" />
      </video>
      <video controls controlsList="nodownload" *ngIf="currentLang == 'pl'">
        <source src="https://dinolingo-shared.s3.amazonaws.com/video/local/wid_polish.mp4" type="video/mp4" />
      </video>
      <video controls controlsList="nodownload" *ngIf="currentLang == 'pt'">
        <source src="https://dinolingo-shared.s3.amazonaws.com/video/local/wid_portuguese_br.mp4" type="video/mp4" />
      </video>
      <video controls controlsList="nodownload" *ngIf="currentLang == 'es'">
        <source src="https://dinolingo-shared.s3.amazonaws.com/video/local/wid_spanish.mp4" type="video/mp4" />
      </video>
      <video controls controlsList="nodownload" *ngIf="currentLang == 'tr'">
        <source src="https://dinolingo-shared.s3.amazonaws.com/video/local/wid_turkish.mp4" type="video/mp4" />
      </video>
      <video controls controlsList="nodownload" *ngIf="currentLang == 'ar'">
        <source src="https://dinolingo-shared.s3.amazonaws.com/video/local/wid_arabic.mp4" type="video/mp4" />
      </video>
      <video controls controlsList="nodownload" *ngIf="currentLang == 'jp'">
        <source src="https://dinolingo-shared.s3.amazonaws.com/video/local/wid_japanese.mp4" type="video/mp4" />
      </video>
      <video controls controlsList="nodownload" *ngIf="currentLang == 'it'">
        <source src="https://dinolingo-shared.s3.amazonaws.com/video/local/wid_italian.mp4" type="video/mp4" />
      </video>
      <video
        controls
        controlsList="nodownload"
        *ngIf="
          currentLang !== 'ru' &&
          currentLang !== 'ua' &&
          currentLang !== 'zh' &&
          currentLang !== 'fr' &&
          currentLang !== 'hi' &&
          currentLang !== 'pl' &&
          currentLang !== 'pt' &&
          currentLang !== 'es' &&
          currentLang !== 'tr' &&
          currentLang !== 'ar' &&
          currentLang !== 'jp' &&
          currentLang !== 'it'
        "
      >
        <source src="https://dinolingo-shared.s3.amazonaws.com/video/dinolingo_welcome.mp4" type="video/mp4" />
      </video>
    </article>
  </section>

  <section class="howItWorks">
    <article class="contentWrapper">
      <h2 class="blue baloo2">{{ formatWithForm('HOME.LEARN_FOR_KIDS.HOW_KIDS_LEARN_TITLE' | translate) }}</h2>
      <div class="content">
        <div class="cart">
          <img src="assets/images/wrapElements/lessons-egg.png" alt="lesson icon" />

          <div class="info">
            <h4 class="red">{{ formatWithForm('HOME.LEARN_FOR_KIDS.HOW_KIDS_LEARN_TEXT1_TITLE' | translate) }}</h4>
            <p>{{ formatWithForm('HOME.LEARN_FOR_KIDS.HOW_KIDS_LEARN_TEXT1' | translate) }}</p>
          </div>
        </div>

        <div class="cart">
          <img src="assets/images/wrapElements/games-egg.png" alt="lesson icon" />

          <div class="info">
            <h4 class="red">{{ formatWithForm('HOME.LEARN_FOR_KIDS.HOW_KIDS_LEARN_TEXT2_TITLE' | translate) }}</h4>
            <p>{{ formatWithForm('HOME.LEARN_FOR_KIDS.HOW_KIDS_LEARN_TEXT2' | translate) }}</p>
          </div>
        </div>

        <div class="cart">
          <img src="assets/images/wrapElements/books-egg.png" alt="lesson icon" />

          <div class="info">
            <h4 class="red">{{ formatWithForm('HOME.LEARN_FOR_KIDS.HOW_KIDS_LEARN_TEXT3_TITLE' | translate) }}</h4>
            <p>{{ formatWithForm('HOME.LEARN_FOR_KIDS.HOW_KIDS_LEARN_TEXT3' | translate) }}</p>
          </div>
        </div>

        <div class="cart">
          <img src="assets/images/wrapElements/worksheets-egg.png" alt="lesson icon" />

          <div class="info">
            <h4 class="red">{{ formatWithForm('HOME.LEARN_FOR_KIDS.HOW_KIDS_LEARN_TEXT4_TITLE' | translate) }}</h4>
            <p>{{ formatWithForm('HOME.LEARN_FOR_KIDS.HOW_KIDS_LEARN_TEXT4' | translate) }}</p>
          </div>
        </div>
      </div>
      <span class="rectRhombus extraSmall red"></span>
      <span class="rectRhombus extraSmall green"></span>
      <span class="rectRhombus small orange"></span>
      <button class="primary orange">
        <a class="dinoLink" routerLink="{{ getURLLangPart() }}/auth/registration">{{ 'HOME.BUTTONS.FREE' | translate }}</a>
      </button>
    </article>
  </section>

  <section class="ourCourses noPadding appBlock">
    <img src="assets/images/pictures/borderWhite.png" class="border bottom" alt="" />
    <div class="image">
      <img src="assets/images/pictures/app-image.png" alt="Dinolingo review app" title="Courses subuser" />
    </div>
  </section>

  <section class="whatParentsSay">
    <article class="contentWrapper">
      <testimonials-section></testimonials-section>
    </article>
  </section>

  <section class="bestMethods">
    <article class="contentWrapper">
      <h2 class="blue baloo2">{{ formatWithForm('HOME.LEARN_FOR_KIDS.BEST_METHODS.TITLE' | translate) }}</h2>
      <p>{{ formatWithForm('HOME.LEARN_FOR_KIDS.BEST_METHODS.SUB_TITLE' | translate) }}</p>
      <ul>
        <ol>
          {{
            formatWithForm('HOME.LEARN_FOR_KIDS.BEST_METHODS.ITEM_1' | translate)
          }}
        </ol>
        <ol>
          {{
            formatWithForm('HOME.LEARN_FOR_KIDS.BEST_METHODS.ITEM_2' | translate)
          }}
        </ol>
        <ol>
          {{
            formatWithForm('HOME.LEARN_FOR_KIDS.BEST_METHODS.ITEM_3' | translate)
          }}
        </ol>
        <ol>
          {{
            formatWithForm('HOME.LEARN_FOR_KIDS.BEST_METHODS.ITEM_4' | translate)
          }}
        </ol>
        <ol>
          {{
            formatWithForm('HOME.LEARN_FOR_KIDS.BEST_METHODS.ITEM_5' | translate)
          }}
        </ol>
        <ol>
          {{
            formatWithForm('HOME.LEARN_FOR_KIDS.BEST_METHODS.ITEM_6' | translate)
          }}
        </ol>
        <ol>
          {{
            formatWithForm('HOME.LEARN_FOR_KIDS.BEST_METHODS.ITEM_7' | translate)
          }}
        </ol>
        <ol>
          {{
            formatWithForm('HOME.LEARN_FOR_KIDS.BEST_METHODS.ITEM_8' | translate)
          }}
        </ol>
        <ol>
          {{
            formatWithForm('HOME.LEARN_FOR_KIDS.BEST_METHODS.ITEM_9' | translate)
          }}
        </ol>
        <ol>
          {{
            formatWithForm('HOME.LEARN_FOR_KIDS.BEST_METHODS.ITEM_10' | translate)
          }}
        </ol>
      </ul>
    </article>
  </section>

  <section class="ourCourses white">
    <article class="contentWrapper">
      <h2 class="blue baloo2">{{ 'HOME.BLOCK_3.TITLE' | translate }}</h2>
      <p [innerHTML]="'HOME.BLOCK_3.TEXT' | translate"></p>
      <h4 class="red">{{ 'HOME.BLOCK_3A.TITLE' | translate }}</h4>
      <p [innerHTML]="formatWithForm('HOME.BLOCK_3A.TEXT' | translate)"></p>
      <h4 class="red">{{ formatWithForm('HOME.BLOCK_3B.TITLE' | translate) }}</h4>
      <p [innerHTML]="formatWithForm('HOME.BLOCK_3B.TEXT' | translate)"></p>
      <h4 class="red">{{ formatWithForm('HOME.BLOCK_3C.TITLE' | translate) }}</h4>
      <p [innerHTML]="formatWithForm('HOME.BLOCK_3C.TEXT' | translate)"></p>
      <h4 class="red">{{ formatWithForm('HOME.BLOCK_3D.TITLE' | translate) }}</h4>
      <p [innerHTML]="formatWithForm('HOME.BLOCK_3D.TEXT' | translate)"></p>
      <h4 class="red">{{ formatWithForm('HOME.BLOCK_3E.TITLE' | translate) }}</h4>
      <p [innerHTML]="formatWithForm('HOME.BLOCK_3E.TEXT' | translate)"></p>
      <h4 class="red">{{ formatWithForm('HOME.BLOCK_3F.TITLE' | translate) }}</h4>
      <p [innerHTML]="formatWithForm('HOME.BLOCK_3F.TEXT' | translate)"></p>
      <h4 class="red" [ngClass]="{ hideBlock: currentLang !== 'en' }">{{ formatText('HOME.BLOCK_3G.TITLE' | translate, lang) }}</h4>
      <p [innerHTML]="formatText('HOME.BLOCK_3G.TEXT' | translate, lang)" [ngClass]="{ hideBlock: currentLang !== 'en' }"></p>
      <h4 class="red">{{ formatWithForm('HOME.BLOCK_3H.TITLE' | translate) }}</h4>
      <p [innerHTML]="formatWithForm('HOME.BLOCK_3H.TEXT' | translate)"></p>
      <h4 class="red">{{ formatWithForm('HOME.BLOCK_3I.TITLE' | translate) }}</h4>
      <p [innerHTML]="formatWithForm('HOME.BLOCK_3I.TEXT' | translate)"></p>
      <h4 class="red">{{ formatWithForm('HOME.BLOCK_3J.TITLE' | translate) }}</h4>
      <p [innerHTML]="formatWithForm('HOME.BLOCK_3J.TEXT' | translate)"></p>
      <h4 class="red">{{ formatWithForm('HOME.BLOCK_3K.TITLE' | translate) }}</h4>
      <p [innerHTML]="formatWithForm('HOME.BLOCK_3K.TEXT' | translate)"></p>
    </article>
    <p *ngIf="videoTutorial[language]" class="video-link" (click)="openVideoPopup(videoTutorial[language].url)">
      <button class="primary blue">{{ videoTutorial[language].text }}</button>
    </p>
  </section>

  <section class="ourCourses noPadding">
    <img src="assets/images/pictures/borderWhite.png" class="border bottom" alt="" />
    <div class="image">
      <img src="assets/images/pictures/computer-image.png" alt="Dinolingo review website" />
    </div>
  </section>

  <div class="buttonBetweenBlocks">
    <span class="rectCicrle blue"></span>
    <button class="primary orange">
      <a class="dinoLink" routerLink="{{ getURLLangPart() }}/auth/registration">{{ 'HOME.BUTTONS.FREE' | translate }}</a>
    </button>
  </div>

  <section class="forSchools gray">
    <img src="assets/images/borders/borderWhite.png" class="border top" alt="" />
    <article class="contentWrapper">
      <h2 class="baloo2 blue">
        {{ formatWithForm('HOME.BLOCK_4.TITLE' | translate) }}
      </h2>
      <div class="twoCols">
        <div class="text">
          <div class="description">
            <p [innerHTML]="formatWithForm('HOME.BLOCK_4.TEXT' | translate)"></p>
            <!-- ??? вопрос с приставкой /en в урлах страницы -->
            <a title="schools" routerLink="{{ getURLLangPart() }}/schools-teachers">{{ 'HOME.LINKS.DINO' | translate }} </a>
            <button class="primary orange">
              <a class="dinoLink" routerLink="{{ getURLLangPart() }}/auth/registration">{{ 'HOME.BUTTONS.FREE' | translate }}</a>
            </button>
          </div>
        </div>

        <div class="image">
          <svg-icon src="assets/images/dinosaurs/schoolDinos.svg"></svg-icon>
        </div>
      </div>
    </article>
    <review-stars [schemeTitle]="metaTitle"></review-stars>
  </section>
</div>
