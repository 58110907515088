import { Component, HostListener, Inject, OnInit } from '@angular/core'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { Router } from '@angular/router'
import { AuthService } from 'src/app/services/auth.service'
import { select, Store } from '@ngrx/store'
import { IAppState } from '../../../../store/state/app.state'
import { take } from 'rxjs/operators'
import { selectCurrentUser } from '../../../../store/selectors/user.selectors'
// import { ConfirmationPopupComponent } from 'src/app/shared/popups/Control-Dialogs/confirmation/confirmation.component';
import { ConfirmationPopupComponent } from '../../../../shared/popups/Control-Dialogs/confirmation/confirmation.component'
import { DeviceDetectorService } from 'ngx-device-detector'
import { AppMessageComponent } from 'src/app/shared/components/app-message/app-message.component'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'app-mobile-dashboard',
  templateUrl: 'mobile-dashboard.component.html',
  styleUrls: ['mobile-dashboard.component.scss'],
})
export class MobileDashboardComponent implements OnInit {
  constructor(private _dialog: MatDialog, public _authService: AuthService, public router: Router, private _store: Store<IAppState>, private deviceService: DeviceDetectorService) { }

  public user
  isMobileScreen: boolean = false

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    if (this.deviceService.isMobile()) {
      this.checkScreenSize()
    }
  }

  ngOnInit(): void {
    this.checkScreenSize()

    if (!this.isMobileScreen) {
      this.router.navigate(['/profile/lessons'])
    }

    this._store.pipe(take(1), select(selectCurrentUser)).subscribe((user) => (this.user = user))
  }

  private checkScreenSize(): void {
    this.isMobileScreen = window.outerHeight < 500 || window.outerWidth < 500
  }

  goToHelp() {
    window.open('https://help.dinolingo.com', '_blank').focus()
  }

  public openMobileAppMessage() {
    this._dialog.open(AppMessageComponent, {
      hasBackdrop: true,
      width: '32rem',
      height: '40rem',
    })
  }

  logout() {
    const dialog = this._dialog.open(ConfirmationPopupComponent, {
      panelClass: 'medium-adaptive-popup',
      closeOnNavigation: true,
      hasBackdrop: true,
      data: 'MODAL_CONFIRM.LOG_OUT',
    })

    dialog.afterClosed().subscribe((answer) => {
      if (!answer) return

      this._authService.logout()
      window.location.href = environment.logoutRedirectUrl
    })
  }

  get teacherOrParent() {
    if (['teacherAdmin', 'teacher'].includes(this.user.userType)) {
      return 'teachers'
    } else if (this.user.userType === 'parent') {
      return 'parents'
    }

    return false
  }
}
