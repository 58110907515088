import { Injectable } from '@angular/core'
import { langForms } from 'src/assets/i18n/langforms'
import { courseNames } from 'src/assets/i18n/courseNames'
import { LocalStorageService } from '../services/localStorage'

@Injectable({
  providedIn: 'root',
})
export class FormatTextService {
  private currentLang = this.localStorageService.getItem('currentLan') || 'en'

  constructor(private localStorageService: LocalStorageService) {}

  public formatText(text, replacement) {
    return text.replace(/%s/g, replacement)
  }

  public getFullLangName(lang) {
    if (lang == 'en') {
      return 'English'
    } else if (lang == 'ru') {
      return 'Русский'
    } else if (lang == 'ua') {
      return 'Українська'
    } else if (lang == 'de') {
      return 'Deutsch'
    } else if (lang == 'fr') {
      return 'Français'
    } else if (lang == 'ar') {
      return 'العربية'
    } else if (lang == 'pl') {
      return 'Polski'
    } else if (lang == 'pt') {
      return 'Português'
    } else if (lang == 'hi') {
      return 'हिंदी'
    } else if (lang == 'tr') {
      return 'Türkçe'
    } else if (lang == 'es') {
      return 'Español'
    } else if (lang == 'it') {
      return 'italiano'
    } else if (lang == 'zh') {
      return '中文'
    } else if (lang == 'ko') {
      return '한국어'
    } else if (lang == 'jp') {
      return '日本語'
    } else {
      return lang
    }
  }

  public getTransLang(loc, lang_slug) {
    let slug = lang_slug
    if (slug === 'portuguese-eu') {
      slug = 'euportuguese'
    } else if (slug === 'portuguese-br') {
      slug = 'portuguese'
    }
    if (undefined === langForms[loc] || undefined === langForms[loc][slug] || undefined === langForms[loc][slug]['form1']) {
      return null
    } else {
      return langForms[loc][slug]['form1']
    }
  }

  public capitalizeFirstLetter(string) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    } else {
      return string
    }
  }

  public formatLangName(course) {
    if (!course) {
      return ''
    }
    this.currentLang = this.localStorageService.getItem('currentLan') || 'en'
    let trans = this.getTransLang(this.currentLang, course.slug)
    if (trans) {
      return this.capitalizeFirstLetter(trans)
    } else {
      return this.capitalizeFirstLetter(course.name)
    }
  }

  public formatCourseName(name) {
    let n = name.trim()
    this.currentLang = this.localStorageService.getItem('currentLan') || 'en'
    if (undefined === courseNames[this.currentLang] || undefined === courseNames[this.currentLang][n]) {
      return n
    } else {
      return courseNames[this.currentLang][n]
    }
  }

  public formatMultiName(name) {
    let n = name.trim()
    this.currentLang = this.localStorageService.getItem('currentLan') || 'en'
    let firstMatch

    if (!/[0-9]/.test(n)) {
      firstMatch = n.match(/[A-z]+[A-z\-]+/g)
      if (firstMatch) {
        let result
        if (undefined === courseNames[this.currentLang][firstMatch[firstMatch.length - 1].trim()]) {
          result = n
        } else {
          result = n.replace(firstMatch[firstMatch.length - 1].trim(), courseNames[this.currentLang][firstMatch[firstMatch.length - 1].trim()])
        }
        let repl = n.replace(firstMatch[firstMatch.length - 1].trim(), '')
        if (undefined !== courseNames[this.currentLang][repl.trim()]) {
          result = result.replace(repl.trim(), courseNames[this.currentLang][repl.trim()])
        }
        return result
      } else {
        return n
      }
    } else {
      firstMatch = n.match(/[A-z\s][A-z\s\-]+/g)
      if (!firstMatch || undefined === courseNames[this.currentLang] || undefined === courseNames[this.currentLang][firstMatch[0].trim()]) {
        return n
      } else {
        let result = n.replace(firstMatch[0].trim(), courseNames[this.currentLang][firstMatch[0].trim()])
        if (firstMatch.length > 1 && undefined !== courseNames[this.currentLang][firstMatch[1].trim()]) {
          result = result.replace(firstMatch[1].trim(), courseNames[this.currentLang][firstMatch[1].trim()])
        }
        return result
      }
    }
  }
}
