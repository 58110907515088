<section class="profile">
  <article class="contentWrapper">
    <div class="profile">
      <div class="info">
        <div class="info-container">
          <div class="title">
            {{ 'PROFILE.TITLE' | translate }}
          </div>
          <localization></localization>
        </div>
        
        <img class="avatar" (click)="openUpdateAvatarPopup()" *ngIf="(parent$ | async)?.thumbnail" [src]="(parent$ | async).user?.thumbnail.src" [alt]="(parent$ | async).user?.firstName" />
        <div class="text">
          <div class="form-fields-container full-width">
            <div class="form-field-container">
              <label>Name<span class="required">*</span></label>
              <input type="text" value="{{ (parent$ | async).user?.firstName }} {{ (parent$ | async).user?.lastName }}" disabled />
            </div>
          </div>
          <div class="form-fields-container full-width">
            <div class="form-field-container">
              <label>{{ 'PROFILE.EMAIL' | translate }}<span class="required">*</span></label>
              <input type="text" value="{{ (parent$ | async).user?.email }}" disabled />
            </div>
          </div>
          <div class="form-fields-container full-width">
            <div class="form-field-container">
              <label>{{ 'PROFILE.PIN' | translate }}<span class="required">*</span></label>
              <input type="text" value="{{ (parent$ | async).user?.pin }}" disabled />
            </div>
          </div>
          <div class="form-fields-container full-width">
            <div class="form-field-container">
              <label>{{ 'PROFILE.TIME_ZONE' | translate }}<span class="required">*</span></label>
              <input type="text" value="{{ (parent$ | async).user?.timeZone }}" disabled />
            </div>
          </div>
        </div>

        <button mat-raised-button color="primary" (click)="openEditProfileDialog()">{{ 'BUTTONS.EDIT' | translate }}</button>
        <button mat-raised-button color="accent" (click)="openChangePasswordDialog()">{{ 'PROFILE.PASSWORD_CHANGE' | translate }}</button>
        <button *ngIf="user?.userType === 'parent' || user?.userType == 'student'" mat-raised-button class="green-btn" (click)="addNewLanguage()">Add a New Language</button>
        <button *ngIf="['teacher', 'teacherAdmin'].includes(user?.userType)" mat-raised-button class="green-btn" (click)="redirectTo()">Add a New Language</button>
        <p class="off">*In order to add a new language, click edit user</p>

        <button mat-raised-button color="accent" (click)="logout()">Logout</button>
      </div>
    </div>
  </article>
</section>
