import { Component, ViewChild, ElementRef, AfterViewInit, OnInit } from '@angular/core'
import { LangChangeEvent, TranslateService } from '@ngx-translate/core'
import { CookieService } from 'ngx-cookie-service'
import { Router, ActivatedRoute } from '@angular/router'
import { SEOService } from 'src/app/services/seo.service'
import { LocalizHelperService } from 'src/app/services/localizHelper.service'

@Component({
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss'],
})
export class ReviewsComponent implements AfterViewInit, OnInit {
  public acceptCookie = null
  public slideIndex: number
  public videoIndex: number
  public currentLang: string

  @ViewChild('sliderWrapper', { static: true }) slider: ElementRef
  @ViewChild('dotsWrapper', { static: true }) dots: ElementRef
  @ViewChild('sliderVideo', { static: true }) video: ElementRef

  constructor(public translate: TranslateService, private _cookieService: CookieService, private router: Router, private _seoService: SEOService, private route: ActivatedRoute, private _lhServise: LocalizHelperService) {
    this.slideIndex = 1
    this.videoIndex = 1
  }
  public title: string
  public description: string
  public name: string
  public displayCookiePopup = true
  public metaTitle = ''
  public url: string = this.router.url

  public langParam = this.route.snapshot.params && this.route.snapshot.params.lang ? this.route.snapshot.params.lang : ''

  public lURL

  ngOnInit() {
    const { meta } = this.route.snapshot.data

    this._lhServise.setURLLang(this.langParam)
    this.currentLang = this._lhServise.getLang()
    this.lURL = this._lhServise.getURLLang()

    const localMeta = meta[this.currentLang] == undefined ? meta['en'] : meta[this.currentLang]

    this._seoService.updateTitle(localMeta.title)
    this._seoService.updateDescription(localMeta.description)
    this._seoService.createCanonicalLink(localMeta.canonical)
    this._lhServise.createLangMetaLinks()

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this._seoService.updateTitle(meta[event.lang]['title'])
      this._seoService.updateDescription(meta[event.lang]['description'])
      this._seoService.createCanonicalLink(meta[event.lang]['canonical'])
      this.lURL = this._lhServise.getURLLang()
    })

    // cookie pop up
    this.acceptCookie = this._cookieService.get('acceptCookie')
    if (this.acceptCookie) {
      this.displayCookiePopup = false
    }
  }

  ngAfterViewInit() {
    this.showSlides(this.slideIndex)
    this.showVideo(this.videoIndex)
  }

  plusSlides(n: number): void {
    this.showSlides((this.slideIndex += n))
  }

  currentSlide(n: number): void {
    this.showSlides((this.slideIndex = n))
  }

  prev(n: number): void {
    this.showVideo(--this.videoIndex)
  }

  next(n: number): void {
    this.showVideo((this.videoIndex += n))
  }

  showVideos(n: number): void {
    const getContent = this.video.nativeElement.getElementsByClassName('slideBlock')
    const block = getContent[n - 1].querySelector('.showVideo')
    const link = getContent[n - 1].querySelector('a')
    block.style.display = 'block'
    link.style.display = 'none'
  }

  showSlides(n: number): void {
    const slides = this.slider.nativeElement.getElementsByClassName('mySlides')
    const dots = this.dots.nativeElement.getElementsByClassName('dot')

    if (n > slides.length) this.slideIndex = 1

    if (n < 1) this.slideIndex = slides.length

    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = 'none'
    }
    for (let i = 0; i < dots.length; i++) {
      dots[i].classList.remove('active')
    }

    slides[this.slideIndex - 1].style.display = 'flex'
    slides[this.slideIndex - 1].style.height = '100%'
    dots[this.slideIndex - 1].classList.add('active')
  }

  showVideo(n: number): void {
    const slides = this.video.nativeElement.getElementsByClassName('slideBlock')

    if (n > slides.length) this.videoIndex = 1

    if (n < 1) this.videoIndex = slides.length
    for (let i = 0; i < slides.length; i++) {
      slides[i].style.display = 'none'
    }

    slides[this.videoIndex - 1].style.display = 'flex'
    slides[this.videoIndex - 1].style.height = '100%'
  }

  public getURLLangPart() {
    let p = this.lURL
    return p ? `/${p}` : ''
  }
}
