import { Component, OnInit } from '@angular/core'
import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog'
import { TranslateService, LangChangeEvent } from '@ngx-translate/core'
import { Router } from '@angular/router'
import { CheckParentPinComponent } from '../../Parent-Dialogs/checkParentPin/checkParentPin.component'
import { Store, select } from '@ngrx/store'
import { IAppState } from 'src/app/store/state/app.state'
import { takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'
import { SiteBlockService } from '../../../../services/siteBlock.service'
import { GetUser } from 'src/app/store/actions/user.actions'
import { selectCurrentUser } from 'src/app/store/selectors/user.selectors'
import { LocalStorageService } from '../../../../services/localStorage'

@Component({
  templateUrl: 'lesson-subscribe.component.html',
  styleUrls: ['lesson-subscribe.component.scss'],
})
export class LessonSubscribePopupComponent implements OnInit {
  public user: any
  private unsubscribe$ = new Subject()

  public dbBlockTitle = null
  public dbBlockText = null
  public dbBlockData = null
  public currentLan: string

  constructor(
    public dialogRef: MatDialogRef<LessonSubscribePopupComponent>,
    public translate: TranslateService,
    public dialog: MatDialog,
    public router: Router,
    private _store: Store<IAppState>,
    private blockServise: SiteBlockService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.currentLan = this.localStorageService.getItem('currentLan') || 'en'

    this.blockServise.getBlock('subscribe_popup').subscribe(
      (data: any) => {
        if (data) {
          this.dbBlockData = data
          this._getDBText(data)
        }
      },
      (err) => {}
    )

    this._store.dispatch(new GetUser())
    this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentUser)).subscribe((user) => {
      if (user) this.user = user.user
    })

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLan = event.lang

      if (this.dbBlockData) {
        this._getDBText(this.dbBlockData)
      }
    })
  }

  private _getDBText(data) {
    let index = data.data.findIndex((el) => el.lang == this.currentLan)

    if (index >= 0 && data.data[index].blocks[0].content) {
      this.dbBlockTitle = data.data[index].blocks[0].content
      this.dbBlockText = data.data[index].blocks[1].content
    } else {
      this.dbBlockTitle = data.data[0].blocks[0].content
      this.dbBlockText = data.data[0].blocks[1].content
    }
  }

  close() {
    this.dialogRef.close()
  }

  openCheckParentPinDialog() {
    if (this.user.pin && !this.router.url.includes('profile/lesson-plan')) {
      const dialog = this.dialog.open(CheckParentPinComponent, {
        closeOnNavigation: true,
        disableClose: true,
        hasBackdrop: true,
        data: 'PARENT_PIN.GO_TO',
      })

      dialog.afterClosed().subscribe((result) => {
        if (result) {
          this.router.navigate(['/profile/parents/subscriptions/stripe'])
          this.dialogRef.close()
        }
      })
    } else {
      this.router.navigate(['/profile/parents/subscriptions/stripe'])
      this.dialogRef.close()
    }
  }
}
