import { Injectable } from '@angular/core'
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router'
import { LocalizHelperService } from 'src/app/services/localizHelper.service'

@Injectable()
export class langURLLocalizationsResolver implements Resolve<null> {
  constructor(private router: Router, private localizeHelperService: LocalizHelperService) { }

  public resolve(route: ActivatedRouteSnapshot, router) {
    let language = route.paramMap.get('lang')
    language = this.localizeHelperService.localizationCountry[language] || language

    if (this.localizeHelperService.localizationAllowed.includes(language)) {
      this.localizeHelperService.setURLLang(language, false)

      return null
    }

    this.router.navigate(['404'])
  }
}
