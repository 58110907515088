import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ElementRef, ViewChild } from '@angular/core'
import { IQuestion } from '../../../models/Question.model'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { RandomizeService } from '../../../services/randomize.service'
import { IAnswer } from '../../../models/Answer.model'
import { ICourse } from '../../../models/Course.model'
import { AudioService } from 'src/app/services/audio.service'
import { ReportProblemPopupComponent } from '../../popups/Report-Dialogs/report-problem/report-problem.component'
import { LocalizHelperService } from 'src/app/services/localizHelper.service'

@Component({
  selector: 'arrange-text',
  templateUrl: './arrange-text.component.html',
  styleUrls: ['./arrange-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArrangeTextGameComponent implements OnInit, OnChanges, OnDestroy {
  @Input() question: IQuestion
  @Input() test: boolean
  @Input() game: boolean
  @Input() course: ICourse
  @Output() success = new EventEmitter()
  @Output() failed = new EventEmitter()

  @ViewChild('audiohelp') audiohelp: ElementRef

  public currentlang

  public dir = 'ltr'

  constructor(private _dialog: MatDialog, private _RandomizeService: RandomizeService, private _lhServise: LocalizHelperService, private audioService: AudioService) {}

  public questionId: number
  public textOptions = []
  public selectedTextOption: IAnswer = null
  public checked = false
  public timerId

  ngOnChanges() {
    this.selectedTextOption = null
    this.checked = false

    if (this.textOptions.length > 0 && this.question.id != this.questionId) {
      this.textOptions = []
      this.createTextOptionsArray(this.question)
    }
  }

  ngOnInit(): void {
    if (this.course.slug === 'arabic' || this.course.slug === 'persian' || this.course.slug === 'hebrew') {
      this.dir = 'rtl'
    }

    this.currentlang = this._lhServise.getLangForced()

    this.questionId = this.question.id
    this.createTextOptionsArray(this.question)
  }

  ngOnDestroy() {
    this.audioService.stopAllAudio()
  }

  public audiohelpPlay() {
    this.audiohelp.nativeElement.play()
  }

  public createTextOptionsArray(question) {
    for (let i = 0; i < question.answers.length; i++) {
      const answer = {
        audioSrc: question.answers[i].audioSrc,
        correct: question.answers[i].correct,
        id: question.answers[i].id,
        name: question.answers[i].name,
        questionId: question.answers[i].questionId,
        text: question.answers[i].text,
        order: question.answers[i].order,
        placedOrder: null,
      }
      this.textOptions.push(answer)
    }

    this._RandomizeService.shuffle(this.textOptions)
  }

  public playAudio(src) {
    this.audioService.playAudio(src, src)
  }

  public setAnswer() {
    const answers = []

    this.textOptions.forEach((t) => {
      answers.push({
        order: t.placedOrder,
        text: t.text,
        correct: t.placedOrder === t.order,
        audioSrc: t.audioSrc,
        name: t.name,
        id: t.id,
        questionId: t.questionId,
      })
    })

    const data = { question: this.question, answer: answers }
    if (this.checkRightAnswer) {
      this.success.emit(data)
    } else {
      this.failed.emit(data)
    }
  }

  public checkAnswer() {
    this.checked = true

    if (this.checkRightAnswer) {
      this.audioService.playSoundCorrect()
    } else {
      this.audioService.playSoundIncorrect()
    }
  }

  public get checkRightAnswer() {
    return this.textOptions.filter((t) => t.placedOrder !== t.order).length === 0
  }

  public openReportPopup(question) {
    this._dialog.open(ReportProblemPopupComponent, {
      closeOnNavigation: true,
      hasBackdrop: true,
      data: question,
      panelClass: 'medium-adaptive-popup',
    })
  }

  public placeAnswer(textOption) {
    if (this.checked) {
      return
    }

    const availableAnswers = this.textOptions.map((t) => t.order).sort()
    const placedAnswers = this.textOptions.filter((t) => t.placedOrder !== null).map((t) => t.placedOrder)

    if (textOption.placedOrder !== null) {
      const maxPlaced = Math.max(...this.textOptions.filter((t) => t.placedOrder !== null).map((t) => t.placedOrder))

      if (textOption.placedOrder === maxPlaced) {
        textOption.placedOrder = null
        return
      }

      this.textOptions.filter((t) => t.placedOrder !== null && t.id !== textOption.id && t.placedOrder > textOption.placedOrder).forEach((t) => t.placedOrder--)

      textOption.placedOrder = null
      return
    }

    textOption.placedOrder = Math.min(
      ...availableAnswers.filter((a) => {
        return placedAnswers.find((p) => p === a) === undefined
      })
    )
  }

  public get placedAnswers(): number[] {
    return this.textOptions.filter((t) => t.placedOrder !== null)
  }

  public get allAnswersPlaced(): boolean {
    return this.placedAnswers.length === this.textOptions.length
  }
}
