import { isPlatformBrowser } from '@angular/common'
import { Component, Inject, PLATFORM_ID } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { BehaviorSubject } from 'rxjs'
import { SiteStatusService } from './services/siteStatus.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  static isBrowser = new BehaviorSubject<boolean>(null)

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    public translate: TranslateService,
    public siteStatusService: SiteStatusService,
  ) {
    AppComponent.isBrowser.next(isPlatformBrowser(platformId))

    translate.addLangs(['en', 'es', 'it', 'fr', 'de', 'pt', 'pl', 'tr', 'ru', 'ua', 'hi', 'ar', 'zh', 'jp', 'ko'])

    translate.setDefaultLang('en')

    const browserLang = translate.getBrowserLang()

    let lsLang = localStorage.getItem('currentLan')
    if (!lsLang) {
      if (browserLang.match(/en|ru|ua|fr|de|ar|pt|hi|pl|es|tr|zh|ko|it|jp/)) {
        localStorage.setItem('currentLan', browserLang)
      } else {
        localStorage.setItem('currentLan', 'en')
      }
    }
  }
}
