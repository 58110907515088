import { Injectable } from '@angular/core'
import * as moment from 'moment'
import { Router } from '@angular/router'
import { HttpClient } from '@angular/common/http'
import { IAppState } from 'src/app/store/state/app.state'
import { Store, select } from '@ngrx/store'
import { selectSubuser, selectCurrentSubuser } from '../store/selectors/subuser.selectors'
import { combineLatest } from 'rxjs'
import { selectCurrentUser } from '../store/selectors/user.selectors'
import { filter } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import { registerLocaleData } from '@angular/common'
import { IUser } from '../models/User.model'
import { ISubuser } from '../models/Subuser.model'

@Injectable({
  providedIn: 'root',
})
export class TimeLogService {
  private gatewayUrl: string
  constructor(private router: Router, private http: HttpClient, private _store: Store<IAppState>) {
    this.gatewayUrl = `${environment.gatewayUrl}log/`
  }

  public dateStart
  public currentUrl: string
  public currentUser: IUser
  public currentSubuser: ISubuser

  public logNgOnInit() {
    this.dateStart = moment()
    this.currentUrl = this.router.url
  }

  public logNgOnDestroy(subuserId = null) {
    // TODO: Remove logs after tests on Stage
    const period = moment.utc(moment().diff(moment(this.dateStart))).format('ss')

    if(subuserId) {
      const request = {
        subuser: {
          id: subuserId
        },
        url: this.currentUrl,
        period,
      }
      this.http.post(`${this.gatewayUrl}save`, request).subscribe()
    } else { // Remove it if NEW LOGIC IS OK
      console.log('logNgOnDestroy OLD Logic');
      combineLatest([this._store.pipe(select(selectCurrentSubuser))])
      .pipe(filter(([subuser]) => !!subuser))
      .subscribe(([subuser]) => {
        this.currentSubuser = subuser
        if (this.currentUrl && this.currentSubuser) {
          const request = {
            subuser: this.currentSubuser,
            url: this.currentUrl,
            period,
          }
          this.http.post(`${this.gatewayUrl}save`, request).subscribe()
        }
      })
    }
  }

  public getTimeLog(request) {
    return this.http.get(`${this.gatewayUrl}get`, {
      params: {
        subuserId: request,
      },
    })
  }

  public getSelectedTimeLog(request) {
    return this.http.get(`${this.gatewayUrl}get`, {
      params: {
        subuserId: request.subuserId,
        firstDay: request.firstDay,
        lastDay: request.lastDay,
      },
    })
  }
}
