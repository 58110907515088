import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'
import { LocalStorageService } from '../services/localStorage'

@Injectable({
  providedIn: 'root',
})
export class TestReportService {
  private gatewayUrl: string
  constructor(private http: HttpClient, private localStorageService: LocalStorageService) {
    this.gatewayUrl = `${environment.gatewayUrl}log/`
  }

  public testReport = []

  public saveAnswerToReport(answerInfo) {
    // Find element in answers array where id === subuserAnswerId
    const answerIndex = answerInfo.question.answers.findIndex((element) => element.id === answerInfo.answer.id)

    // Add property subuserAnswer to the answer that the subuser chose
    const answers = answerInfo.question.answers.map(function (item, index) {
      if (index === answerIndex) {
        return {
          ...item,
          subuserAnswer: true,
        }
      }
      if (answerIndex == -1) {
        return {
          ...item,
          subuserAnswer: false,
        }
      }

      return item
    })

    // Create and push new object with test result
    this.testReport.push({
      subuserAnswer: answerInfo.answer,
      question: {
        answers,
        audioSrc: answerInfo.question.audioSrc,
        id: answerInfo.question.id,
        lessonId: answerInfo.question.lessonId,
        questionType: answerInfo.question.questionType,
        text: answerInfo.question.text,
        order: answerInfo.question.order,
      },
    })
  }

  public storeTestReport(lessonId, subuserId, progress) {
    const request = {
      reports: this.testReport,
      lessonId,
      subuserId,
      progress,
    }
    this.http.post(`${this.gatewayUrl}testReport`, request).subscribe((response) => {})

    this.testReport = []
  }

  public getTestReport(request) {
    const { courseId, subuserId } = request

    return this.http.get(`${this.gatewayUrl}testReport`, {
      params: {
        courseId,
        subuserId,
      },
    })
  }

  public saveTestReport(request) {
    return this.http.post(`${this.gatewayUrl}testReport`, request)
  }

  public getTestResultPdf(body) {
    return this.http.post(`${this.gatewayUrl}testReportPdf`, body)
  }

  public saveGameProgress(lessonId, subuserId, correct, incorrect) {
    let saveData = {
      lessonId: lessonId,
      progress: btoa(unescape(encodeURIComponent(JSON.stringify([correct, incorrect])))),
      // progress: JSON.stringify([correct, incorrect])
    }
    this.localStorageService.setItem(`lessonSave_${subuserId}`, JSON.stringify(saveData))
  }

  public loadGameProgress(lessonId, subuserId) {
    let save = this.localStorageService.getItem(`lessonSave_${subuserId}`)
    if (save) {
      let saveData = JSON.parse(save)
      if (lessonId == saveData.lessonId) {
        try {
          let progress = decodeURIComponent(escape(atob(saveData.progress)))
          // let progress = saveData.progress;
          if (progress) {
            let encodedProgress = JSON.parse(progress)
            if (Array.isArray(encodedProgress)) {
              let answers = {
                correct: encodedProgress[0],
                incorrect: encodedProgress[1],
              }
              return answers
            }
          }
        } catch (err) {
          return false
        }
      }
    }
    return false
  }

  public saveTestProgress(lessonId, subuserId, current) {
    let saveData = {
      lessonId: lessonId,
      progress: btoa(unescape(encodeURIComponent(JSON.stringify(this.testReport)))),
      current: btoa(unescape(encodeURIComponent(JSON.stringify(current)))),
      // current: JSON.stringify(current),
    }
    this.localStorageService.setItem(`lessonSave_${subuserId}`, JSON.stringify(saveData))
  }

  public loadTestProgress(lessonId, subuserId) {
    let save = this.localStorageService.getItem(`lessonSave_${subuserId}`)
    if (save) {
      let saveData = JSON.parse(save)
      if (lessonId == saveData.lessonId) {
        try {
          let progress = decodeURIComponent(escape(atob(saveData.progress)))
          // let progress = saveData.progress;
          if (progress) {
            let encodedProgress = JSON.parse(progress)
            if (Array.isArray(encodedProgress)) {
              this.testReport = encodedProgress
              let answers = {
                correct: 0,
                incorrect: 0,
                current: JSON.parse(decodeURIComponent(escape(atob(saveData.current)))),
                // current: JSON.parse(saveData.current)
              }
              this.testReport.forEach((el, i) => {
                if (el.question.questionType.id == 3) {
                  if (el.question.answers[0].subuserAnswer) {
                    answers.correct++
                  } else {
                    answers.incorrect++
                  }
                } else {
                  if (el.subuserAnswer.correct) {
                    answers.correct++
                  } else {
                    answers.incorrect++
                  }
                }
              })
              return answers
            }
          }
        } catch (err) {
          return false
        }
      } else {
        this.clearTestProgress()
      }
    }
    return false
  }

  public clearTestProgress() {
    this.testReport = []
  }

  public deleteTestProgress(subuserId) {
    this.clearTestProgress()
    this.localStorageService.removeItem(`lessonSave_${subuserId}`)
  }
}
