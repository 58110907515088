<section class="teachers" [ngClass]="{ loader: !data }">
  <article class="contentWrapper">
    <div class="title">{{ 'LINKS.TEACHERS' | translate }}</div>
    <button type="button" class="btn-add-teacher" (click)="openAddTeacherDialog()">
      <img src="assets/images/icons/green-plus.svg" alt="add teacher" /> <span>{{ 'TEACHER.ADD_NEW_TEACHER' | translate }}</span>
    </button>

    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
      <ng-container matColumnDef="index">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'STUDENT.INDEX' | translate }}</th>
        <td mat-cell *matCellDef="let element; let i = index">{{ i + 1 }}</td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'TEACHER.NAME' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.firstName }} {{ element.lastName }}</td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'TEACHER.EMAIL' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          {{ element.email }}
        </td>
      </ng-container>

      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'TEACHER.ROLE' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          {{ element.role === 'teacherAdmin' ? 'Teacher Admin' : 'Teacher' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>{{ 'STUDENT.ACTIONS' | translate }}</th>
        <td mat-cell *matCellDef="let teacher">
          <mat-form-field>
            <mat-label>{{ 'STUDENT.ACTIONS' | translate }}</mat-label>
            <mat-select disableRipple>
              <mat-option (click)="editTeacher(teacher)">
                <mat-icon>edit</mat-icon>
                <span>{{ 'TEACHER.EDIT' | translate }}</span>
              </mat-option>
              <mat-option (click)="teacher.role !== 'teacherAdmin' && removeTeacher(teacher)" [disabled]="teacher.role === 'teacherAdmin'">
                <mat-icon>cancel</mat-icon>
                <span>{{ 'TEACHER.REMOVE' | translate }}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>
        <td></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index"></tr>
    </table>
  </article>
</section>
