import { Component, OnInit } from '@angular/core'
import { LangChangeEvent, TranslateService } from '@ngx-translate/core'
import { CookieService } from 'ngx-cookie-service'
import { Router, ActivatedRoute } from '@angular/router'
import { SEOService } from 'src/app/services/seo.service'
import { LocalizHelperService } from 'src/app/services/localizHelper.service'

@Component({
  templateUrl: './terms2.component.html',
  styleUrls: ['./terms2.component.scss'],
})
export class Terms2Component implements OnInit {
  public acceptCookie = null

  constructor(public translate: TranslateService, private _cookieService: CookieService, private router: Router, private _seoService: SEOService, private route: ActivatedRoute, private _lhServise: LocalizHelperService) {}
  public currentLang: string
  public name: string
  public title: string
  public description: string
  public displayCookiePopup = true
  public metaTitle = ''
  public url: string = this.router.url

  public langParam = this.route.snapshot.params && this.route.snapshot.params.lang ? this.route.snapshot.params.lang : ''

  public lURL

  ngOnInit() {
    window.location.href = 'https://help.dinolingo.com/article/494-terms'
  }
}
