import { Component, Inject, OnInit } from '@angular/core'
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'
import { Actions, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { forkJoin, Subject } from 'rxjs'
import { map, takeUntil } from 'rxjs/operators'
import { selectCurrentLesson } from 'src/app/store/selectors/lessons.selectors'
import { IAssignment } from '../../../../models/Assignment.model'
import { LessonsService } from '../../../../services/lessons.service'
import { PrintsService } from '../../../../services/prints.service'
import { SchoolService } from '../../../../services/school.service'
import { EAssignmentActions, GetStudentAssignments, GetStudentAssignmentsSuccess } from '../../../../store/actions/assignment.actions'
import { GetLesson } from '../../../../store/actions/lessons.actions'
import { IAppState } from '../../../../store/state/app.state'

@Component({
  templateUrl: './student-assignments.component.html',
  styleUrls: ['./student-assignments.component.scss'],
})
export class StudentAssignmentsDialogComponent implements OnInit {
  public assignments
  public loaded = false
  public inDashboard = false

  private destroyed$ = new Subject()

  constructor(
    public dialogRef: MatDialogRef<StudentAssignmentsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _store: Store<IAppState>,
    private _actions$: Actions,
    private _printService: PrintsService,
    public _lessonsService: LessonsService,
    public _schoolService: SchoolService
  ) {
    if (data.inDashboard) {
      this.inDashboard = data.inDashboard
    }
  }

  ngOnInit(): void {
    this._store.dispatch(new GetStudentAssignments(this.data.schoolId, this.data.classroomId, this.data.student.id))

    this._actions$
      .pipe(
        takeUntil(this.destroyed$),
        ofType<GetStudentAssignmentsSuccess>(EAssignmentActions.GetStudentAssignmentsSuccess),
        map((res: GetStudentAssignmentsSuccess) => res.assignments)
      )
      .subscribe((assignments: Array<IAssignment>) => {
        this.assignments = assignments
        this.loaded = true
      })
  }

  completePrint(printId) {
    if (this.inDashboard) return

    const request = {
      subuserId: this.data.student.subuser.id,
      printId,
    }

    this._printService.completedPrint(request).subscribe(() => {
      this.assignments = this.assignments.map((assignment) => {
        const a = { ...assignment }
        if (a.lesson.prints) {
          const prints = [...a.lesson.prints]
          const printIndex = prints.findIndex((print) => print.id === printId)

          if (printIndex !== -1) {
            prints.splice(printIndex, 1)
          }

          a.lesson = { ...a.lesson, prints }
        }

        return a
      })
    })
  }

  openPrint(printId) {
    this._store.dispatch(
      new GetLesson({
        lessonId: printId,
        subuserId: this.data.student.subuser.id,
      })
    )
    this._store.pipe(takeUntil(this.destroyed$), select(selectCurrentLesson)).subscribe((lesson) => {
      if (lesson) {
        window.open(lesson.questions[0].src, '_blank')
        const progress = 100
        const repeats = lesson.completed ? lesson.completed.repeat : 0
        const request = {
          subuserStars: 0,
          subuserId: this.data.student.subuser.id,
          lessonId: printId,
          progress,
          courseId: this.data.student.classroom.courseId,
          dinosaurId: null,
          repeat: repeats + 1,
        }
        forkJoin([
          this._lessonsService.completeLesson(request),
          this._schoolService.completeAssignment(this.data.schoolId, this.data.classroomId, {
            studentId: this.data.student.id,
            lessonId: printId,
            progress,
            repeat: repeats + 1,
          }),
        ]).subscribe(() => {
          this._store.dispatch(new GetStudentAssignments(this.data.schoolId, this.data.classroomId, this.data.student.id))
        })
      }
    })
  }
}
