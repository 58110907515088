<div class="student-gradebook" *ngIf="loaded">
  <div>
    <div class="header">
      <div class="left">
        <h3 class="classroom-name">Class: {{ student.classroom.classroomName }}</h3>
        <h3 class="student-name">Student: {{ student.subuser.name }}</h3>
      </div>
      <button type="button" class="btn-export-gradebook" (click)="openExportGradebookDialog()"><img src="assets/images/icons/download.svg" alt="export gradebook" />{{ 'GRADEBOOK.EXPORT.TITLE' | translate }}</button>
    </div>

    <table class="table__grade-breakdown">
      <tbody>
        <tr>
          <th>{{ 'GRADEBOOK.GRADE.OVERALL' | translate }}</th>
          <td colspan="2">{{ grades.overallGrade }}%</td>
        </tr>
        <tr>
          <th class="grade-category">{{ 'GRADEBOOK.GRADE.PRINT' | translate }}</th>
          <td class="grade">
            <span *ngIf="grades.worksheetGrade !== null">{{ grades.worksheetGrade }}%</span>
            <span *ngIf="grades.worksheetGrade === null">N/A</span>
          </td>
          <td class="of-grade">{{ ('GRADEBOOK.GRADE.PERCENT_OF_GRADE' | translate).replace('%num%', 15) }}</td>
        </tr>
        <tr>
          <th class="grade-category">{{ 'GRADEBOOK.GRADE.ACTIVITY' | translate }}</th>
          <td class="grade">
            <span *ngIf="grades.activityGrade !== null">{{ grades.activityGrade }}%</span>
            <span *ngIf="grades.activityGrade === null">N/A</span>
          </td>
          <td class="of-grade">{{ ('GRADEBOOK.GRADE.PERCENT_OF_GRADE' | translate).replace('%num%', 35) }}</td>
        </tr>
        <tr>
          <th class="grade-category">{{ 'GRADEBOOK.GRADE.TEST' | translate }}</th>
          <td class="grade">
            <span *ngIf="grades.testGrade !== null">{{ grades.testGrade }}%</span>
            <span *ngIf="grades.testGrade === null">N/A</span>
          </td>
          <td class="of-grade">{{ ('GRADEBOOK.GRADE.PERCENT_OF_GRADE' | translate).replace('%num%', 50) }}</td>
        </tr>
        <tr>
          <td class="grade-disclaimer" colspan="3">{{ 'GRADEBOOK.GRADE.DISCLAIMER' | translate }}</td>
        </tr>
      </tbody>
    </table>

    <table mat-table [dataSource]="studentAssignments" matSort class="table__student-assignments mat-elevation-z8">
      <ng-container matColumnDef="dueDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'STUDENT.ASSIGNMENTS.HEAD_DUEDATE' | translate }}</th>
        <td mat-cell *matCellDef="let assignment">{{ assignment.dueDate | date : 'MM/dd/yy' }}</td>
      </ng-container>

      <ng-container matColumnDef="assignment">
        <th mat-header-cell *matHeaderCellDef>{{ 'STUDENT.ASSIGNMENTS.HEAD_LESSON' | translate }}</th>
        <td mat-cell *matCellDef="let assignment">{{ assignment.lesson.name }}</td>
      </ng-container>

      <ng-container matColumnDef="score">
        <th mat-header-cell *matHeaderCellDef>{{ 'STUDENT.ASSIGNMENTS.HEAD_SCORE' | translate }}</th>
        <td mat-cell *matCellDef="let assignment">{{ getScore(assignment) }}</td>
      </ng-container>

      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef>{{ 'STUDENT.ASSIGNMENTS.HEAD_CATEGORY' | translate }}</th>
        <td mat-cell *matCellDef="let assignment">{{ getCategory(assignment) }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr mat-row *matRowDef="let row; columns: columns"></tr>
    </table>
  </div>
</div>
