import { Component, OnInit, Input } from '@angular/core'
import { LangChangeEvent, TranslateService } from '@ngx-translate/core'
import { LocalStorageService } from '../../../services/localStorage'
import { SiteBlockService } from '../../../services/siteBlock.service'
import { ViewEncapsulation } from '@angular/core'

@Component({
  selector: 'topbanner',
  templateUrl: './topbanner.component.html',
  styleUrls: ['./topbanner.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TopBannerComponent implements OnInit {
  public currentLan: string

  public topBannerText = null
  public topBannerData = null
  public topBannerStyle = ''

  @Input() hideLDD: boolean

  constructor(public translate: TranslateService, private localStorageService: LocalStorageService, private blockServise: SiteBlockService) {}
  ngOnInit(): void {
    this.currentLan = this.localStorageService.getItem('currentLan') || 'en'

    this.blockServise.getBlock('landing_top_banner').subscribe(
      (data: any) => {
        if (data) {
          this.topBannerData = data
          this._getBannerText(data)
          if (this.topBannerData.data[0].blocks[1]) {
            this.topBannerStyle = this.topBannerData.data[0].blocks[1].content
          }
        }
      },
      (err) => {}
    )

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLan = event.lang

      if (this.topBannerData) {
        this._getBannerText(this.topBannerData)
      }
    })
  }

  private _getBannerText(data) {
    let index = data.data.findIndex((el) => el.lang == this.currentLan)

    if (index >= 0 && data.data[index].blocks[0].content) {
      this.topBannerText = data.data[index].blocks[0].content
    } else {
      this.topBannerText = data.data[0].blocks[0].content
    }
  }
}
