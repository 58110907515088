import { Component, OnInit, OnDestroy, HostListener, Input, ChangeDetectionStrategy } from '@angular/core'
import { Location } from '@angular/common'
import { IAppState } from 'src/app/store/state/app.state'
import { Store, select } from '@ngrx/store'
import { ILesson } from 'src/app/models/Lesson.model'
import { CompleteLesson, HideHeaderLesson, DisplayHeaderLesson, CompleteUnit, GetAvailableLocales, ELessonsActions, GetAvailableLocalesSuccess, GetLocalizations, GetLocalizationsSuccess } from 'src/app/store/actions/lessons.actions'
import { selectCurrentSubuser } from 'src/app/store/selectors/subuser.selectors'
import { Observable, Subject } from 'rxjs'
import { selectCurrentLesson } from 'src/app/store/selectors/lessons.selectors'
import { filter, map, takeUntil } from 'rxjs/operators'
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog'
import { ActivatedRoute, Router } from '@angular/router'
import { selectCurrentCourse } from 'src/app/store/selectors/courses.selectors'
import { SetHeaderValue } from 'src/app/store/actions/header.actions'
import { selectCurrentUser } from 'src/app/store/selectors/user.selectors'
import { NotificationService } from 'src/app/services/notification.service'
import { TranslateService } from '@ngx-translate/core'
import { SetCurrentUnit } from 'src/app/store/actions/subuser.actions'
import { TimeLogService } from 'src/app/services/time-log.service'
import { TestReportService } from 'src/app/services/testReport.service'
import { RandomizeService } from 'src/app/services/randomize.service'
import { ISubuser } from 'src/app/models/Subuser.model'
import { IUser } from 'src/app/models/User.model'
import { LessonEndPopupComponent } from 'src/app/shared/popups/Lesson-Dialogs/lesson-end/lesson-end.component'
import { ReportProblemPopupComponent } from 'src/app/shared/popups/Report-Dialogs/report-problem/report-problem.component'
import { setCoins } from 'src/app/helpers/utils/LessonUtil/lesson.util'
import { GetOpenDinosaurs } from 'src/app/store/actions/statistics.actions'
import { selectOpenDinosaurs } from 'src/app/store/selectors/statistics.selectors'
import { ICourse } from '../../../../models/Course.model'
import { LocalStorageService } from '../../../../services/localStorage'
import { FormatTextService } from '../../../../services/formatText.service'
import { selectCurrentStudent } from '../../../../store/selectors/students.selectors'
import { IStudent } from '../../../../models/Student.model'
import { CompleteAssignment } from '../../../../store/actions/assignment.actions'
import { ConfirmationPopupComponent } from 'src/app/shared/popups/Control-Dialogs/confirmation/confirmation.component'
import { SubuserService } from 'src/app/services/subuser.service'
import { Actions, ofType } from '@ngrx/effects'
import { AudioService } from 'src/app/services/audio.service'

@Component({
  templateUrl: './lesson.component.html',
  styleUrls: ['./lesson.component.scss'],
})
export class LessonComponent implements OnInit, OnDestroy {
  constructor(
    private _store: Store<IAppState>,
    private _dialog: MatDialog,
    private _route: ActivatedRoute,
    public router: Router,
    private notification: NotificationService,
    public translate: TranslateService,
    public timeLogService: TimeLogService,
    public testReportService: TestReportService,
    public randomService: RandomizeService,
    private localStorageService: LocalStorageService,
    private textService: FormatTextService,
    private _subuserService: SubuserService,
    private _actions$: Actions,
    private location: Location,
    private audioService: AudioService
  ) {}

  @HostListener('window:beforeunload', ['$event'])
  public isFromCurriculum = false

  public lesson: ILesson // Current working lesson

  public subuserId: number // Subuser ID

  public subuser: ISubuser

  public courseId: number // Course id for easy calculation current course progress

  public unitId: number

  public subuserStars = 100 // Coins that subuser will receive

  public progress: number = null // Test / Game score from 0 to 100 percents

  public dinosaurId: number = null // Dinosaur that subuser will receive after TEST!!!

  public repeats: number // Amount of lesson repeats

  public user: IUser

  public repeatBook = false

  public lessonTestType

  public currentPosition: Array<any>

  public totalPosition: Array<any>

  public lessonShuffleTestArray = []

  public lessonShuffleUnitTestArray = []

  public newArr = []

  public unsubscribe$ = new Subject()

  public unittestCount = 40
  public minitestCount = 20

  public displayTooltip = false

  public openDinosaurs

  public isTeacherArea = false
  public backLink = '/profile/lessons'

  public selectedTranslation = ''

  public exitConfirm = false

  public correctAnswers = []

  public incorrectAnswers = []

  public floatPoints = {
    video: 3,
    book: 1,
    // 'test': 4,
    activity: 5,
  }

  public pointsEachVedeoSecond = 10

  public videoTimeCounter = 0
  public videoTimerId

  public timerId
  public floatText
  public floatTextActive = false

  public useAnimComparisonFix = false

  /**
   * Counter for current game and total games amount
   * fot detect whitch game is the last and then, complete lesson
   *
   *
   */
  public currentGame = 0

  public totalGames = 0

  public course$: Observable<ICourse> = this._store.pipe(select(selectCurrentCourse))

  public lesson$: Observable<ILesson> = this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentLesson))

  public availableLocales: Array<string> = []

  public localizations: Array<object> = []

  /**
   * Subscribe on current subuser learning course
   * and the, put current course id in complete lesson request
   *
   * @return {Subscription<ICourse>}
   *
   */
  public currentCourse$ = this._store
    .pipe(
      select(selectCurrentCourse),
      filter((course) => !!course),
      takeUntil(this.unsubscribe$)
    )
    .subscribe((course) => {
      this.courseId = course.id
    })

  /**
   * Subscribe on current subuser and then, add current subuser id
   * to all necessary requests and then, call API requests to
   * fetch current lesson and current lesson games from Back-End
   *
   * @return {Subscription<ISubuser>}
   *
   */
  public subuser$ = this._store
    .pipe(
      select(selectCurrentSubuser),
      filter((subuser) => !!subuser),
      takeUntil(this.unsubscribe$)
    )
    .subscribe((subuser) => {
      this.subuser = subuser
      this.subuserId = subuser.id
      this._store.dispatch(new GetOpenDinosaurs(subuser.id))
    })

  ngOnInit() {
    let state: any = this.location.getState()
    if (undefined !== state.isFromCurriculum && state.isFromCurriculum) {
      this.isFromCurriculum = true
    }

    this.timeLogService.logNgOnInit()
    this._store.dispatch(new SetHeaderValue(`${this.router.url.split('/')[1]}#test`))
    // Hide profile header
    this._store.dispatch(new HideHeaderLesson())

    this._store.pipe(select(selectOpenDinosaurs), takeUntil(this.unsubscribe$)).subscribe((dino) => {
      this.openDinosaurs = dino
    })

    this.newArr = []
    this.lesson$.subscribe((lesson) => {
      this.lesson = lesson

      if (lesson && lesson.slug.includes('comparison')) {
        this.useAnimComparisonFix = true
      }

      this.checkSubscribtionUser()

      this.getLocalizations(lesson.id, this.getDefaultLocale())

      this.getAvailableLocales(lesson)

      this.lessonTestType = lesson
      for (let i = 0; i < lesson.questions.length; i++) {
        this.newArr.push(lesson.questions[i])
      }

      switch (lesson.type.id) {
        case 3:
          this.lessonShuffleTestArray = this.randomService.shuffle(this.newArr).slice(0, this.minitestCount)
          break
        case 4:
          this.lessonShuffleTestArray = this.randomService.shuffle(this.newArr).slice(0, this.unittestCount)
          break

        default:
          this.lessonShuffleTestArray = lesson.questions
          break
      }

      if (this.hasLessonTestType) {
        // Load test save
        let save = this.testReportService.loadTestProgress(lesson.id, this.subuserId)
        if (save) {
          this.currentGame = save.correct + save.incorrect
          this.correctAnswers = new Array(save.correct)
          this.incorrectAnswers = new Array(save.incorrect)
          this.lessonShuffleTestArray[this.currentGame] = save.current
        }
      } else if (this.hasLessonGameType) {
        // Load game save
        let save = this.testReportService.loadGameProgress(lesson.id, this.subuserId)
        if (save) {
          this.currentGame = save.correct + save.incorrect
          this.correctAnswers = new Array(save.correct)
          this.incorrectAnswers = new Array(save.incorrect)
        }
      }

      this.subuserStars = setCoins(lesson.type.slug)
      this.repeats = lesson.completed?.repeat
      this.dinosaurId = lesson.dinosaurId
      this.totalGames = this.lessonShuffleTestArray.length
      this.currentPosition = new Array(this.currentGame)
      this.totalPosition = new Array(this.lessonShuffleTestArray.length - this.currentGame)

      if (this.subuser.lessonMode || !this.hasUnitTestType) {
        this._store.dispatch(
          new SetCurrentUnit({
            subuserId: this.subuserId,
            unitId: this.lesson.unitId,
            courseId: this.courseId,
          })
        )
      }
    })
  }

  ngOnDestroy() {
    this.timeLogService.logNgOnDestroy(this.subuserId)
    this._store.dispatch(new DisplayHeaderLesson())
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
    clearTimeout(this.timerId)
    clearInterval(this.videoTimerId)
  }

  public formatCourseName(name) {
    return this.textService.formatMultiName(name)
  }

  public newBookPageOpened() {
    this._addPoints('book')
  }

  public videoStarted(element) {
    if (!this.videoTimerId) {
      this.videoTimerId = setInterval(() => {
        if (!element.paused && element.readyState === 4) {
          this.videoTimeCounter++
          if (this.videoTimeCounter === this.pointsEachVedeoSecond) {
            this._addPoints('video')
            this.videoTimeCounter = 0
          }
        }
      }, 1000)
    }
  }

  private _addPoints(lessonType) {
    this.floatTextActive = false
    clearTimeout(this.timerId)
    this._subuserService.addPoints({ subuserId: this.subuserId, points: this.floatPoints[lessonType] }).subscribe((res: any) => {
      if (res && res.success) {
        this.floatText = `+${this.floatPoints[lessonType]}`
        this.floatTextActive = true
        this.timerId = setTimeout(() => {
          this.floatTextActive = false
        }, 1800)
      }
    })
  }

  /**
   * CHECK USER ACCESS TO LESSON
   */
  checkSubscribtionUser() {
    this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentUser)).subscribe((user) => {
      if (user) {
        this.user = user
        if (['teacherAdmin', 'teacher'].includes(this.user.userType)) {
          this.isTeacherArea = true
          this.backLink = '/profile/lesson-plan'
        }

        if (this.isFromCurriculum) {
          this.backLink = '/profile/lesson-plan'
        }

        const data = {
          lesson: this.lesson,
          accessibleCourses: user.accessibleCourses,
          subuser: this.subuser,
          id: this.courseId,
        }

        if (
          !this.lesson.isFree &&
          !this.user.accessibleCourses

          // !isAccessLesson(data)
        ) {
          this.router.navigate([this.backLink])
          this.notification.showNotification('Please subscribe on this course if you want learn more!', 4)
        }
      }
    })
  }

  /**
   * This function works when any game emit event "Failed"
   * open next game, push failed question to array
   * if it's the last game -> complete lesson
   *
   * @return {void}
   *
   */
  failed(answerInfo) {
    this.incorrectAnswers.push(answerInfo.question)
    if (this.hasLessonTestType || this.hasUnitTestType) {
      this.testReportService.saveAnswerToReport(answerInfo)
    }

    if (this.checkLastGame) {
      if (this.hasLessonTestType || this.hasUnitTestType) {
        this.testReportService.storeTestReport(this.lesson.id, this.subuserId, this.lessonProgress)
      }

      this.completeLesson()
    } else {
      this.currentGame++
      this.currentPosition = new Array(this.currentGame)
      this.totalPosition = new Array(this.lessonShuffleTestArray.length - this.currentGame)

      if (this.hasLessonTestType || this.hasUnitTestType) {
        this.testReportService.saveTestProgress(this.lesson.id, this.subuserId, this.lessonShuffleTestArray[this.currentGame])
      } else if (this.hasLessonGameType) {
        this.testReportService.saveGameProgress(this.lesson.id, this.subuserId, this.correctAnswers.length, this.incorrectAnswers.length)
      }
    }
  }

  /**
   * This function works when any game emit event "Success"
   * open next game, push correct question to array
   * if it's the last game -> complete lesson
   *
   * @return {void}
   *
   */
  success(answerInfo) {
    this.correctAnswers.push(answerInfo.question)

    if (this.hasLessonTestType || this.hasUnitTestType) {
      this.testReportService.saveAnswerToReport(answerInfo)
    }

    if (this.checkLastGame) {
      if (this.hasLessonTestType || this.hasUnitTestType) {
        this.testReportService.storeTestReport(this.lesson.id, this.subuserId, this.lessonProgress)
      }

      this.completeLesson()
    } else {
      this.currentGame++
      this.currentPosition = new Array(this.currentGame)
      this.totalPosition = new Array(this.lessonShuffleTestArray.length - this.currentGame)

      if (this.hasLessonTestType || this.hasUnitTestType) {
        this.testReportService.saveTestProgress(this.lesson.id, this.subuserId, this.lessonShuffleTestArray[this.currentGame])
      } else if (this.hasLessonGameType) {
        this.testReportService.saveGameProgress(this.lesson.id, this.subuserId, this.correctAnswers.length, this.incorrectAnswers.length)
      }
    }

    this._addPoints('activity')
  }

  /**
   * API Request to complete current lesson activity (Video/Game/Test)
   * and then, redirect user to congratulations page
   *
   * @return {void}
   *
   */
  completeLesson() {
    let progress: number

    if (this.hasLessonVideoType) progress = 100 // Video and Game has 100% progress by default
    if (this.hasLessonBookType) progress = 100 // Book has 100% progress by default
    if (this.hasLessonGameType) progress = 100 // Video and Game has 100% progress by default
    if (this.hasLessonTestType) progress = this.lessonProgress
    if (this.hasUnitTestType) {
      progress = this.lessonProgress
      this._store.dispatch(
        new CompleteUnit({
          subuserId: this.subuserId,
          unitId: this.lesson.unitId,
          courseId: this.courseId,
          progress,
        })
      )

      // Update current unit for subuser
      if (!this.subuser.lessonMode) {
        this._store.dispatch(
          new SetCurrentUnit({
            subuserId: this.subuserId,
            unitId: this.lesson.unitId + 1,
            courseId: this.courseId,
          })
        )
      }
    }

    // complete assignment
    if (this.user.userType === 'student') {
      this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentStudent)).subscribe((student: IStudent) => {
        this.completeAssignment(student, this.lesson, progress, this.repeats + 1)
      })
    }

    this.testReportService.deleteTestProgress(this.subuserId)

    this.correctAnswers = []
    this.incorrectAnswers = []

    if (progress < 80) return this.openLessonEndPopup(progress, false, false) // Return if progress less then 80

    /* 
            API Request to complete current lesson if progress > 70%
    */

    if (this.lesson.type.name == 'Mini Test' || this.lesson.type.name == 'Unit Test') {
      this._store.dispatch(
        new CompleteLesson({
          subuserStars: this.subuserStars, // comment if you want to receive coins only if repeat the test
          subuserId: this.subuserId,
          lessonId: this.lesson.id,
          progress,
          courseId: this.courseId,
          dinosaurId: this.dinosaurId && progress >= 90 && (!this.openDinosaurs || (this.openDinosaurs && !this.openDinosaurs.includes(this.dinosaurId))) ? this.dinosaurId : null,
          repeat: this.repeats + 1,
        })
      )
    } else {
      if ((this.lesson.type.name == 'Video' || this.lesson.type.name == 'Video Song') && this.repeats > 2) {
        this.subuserStars = 0
      }
      this._store.dispatch(
        new CompleteLesson({
          subuserStars: this.subuserStars,
          subuserId: this.subuserId,
          lessonId: this.lesson.id,
          progress,
          courseId: this.courseId,
          dinosaurId: null,
          repeat: this.repeats + 1,
        })
      )
    }

    const hasNewDino = !!(this.dinosaurId && progress >= 90 && (!this.openDinosaurs || (this.openDinosaurs && !this.openDinosaurs.includes(this.dinosaurId))))

    /* 
            Open congratulations popup
    */
    this.openLessonEndPopup(progress, hasNewDino, true)
  }

  /**
   * SHOW END LESSON DIALOG WINDOW WHITS CHILD PROGRESS
   * @param progress
   */
  public openLessonEndPopup(progress: number, dino: boolean, completed: boolean) {
    this.audioService.deleteAllAudio()

    const dialogConfig = new MatDialogConfig()
    dialogConfig.closeOnNavigation = true
    dialogConfig.disableClose = true
    dialogConfig.hasBackdrop = true
    dialogConfig.width = '28rem'
    dialogConfig.height = '46rem'
    dialogConfig.data = {
      progress,
      subuserStars: this.subuserStars,
      dino,
      completed,
    }
    dialogConfig.panelClass = 'menuDialog'
    if (progress >= 80) {
      dialogConfig.backdropClass = 'confetti'
    }

    const dialog = this._dialog
      .open(LessonEndPopupComponent, dialogConfig)
      .afterClosed()
      .subscribe((res) => {
        if (res.status === 'repeat') {
          this.repeats = !this.repeats ? 1 : this.repeats + 1
          this.currentGame = 0
          this.currentPosition = new Array(this.currentGame)
          this.totalPosition = new Array(this.lessonShuffleTestArray.length - this.currentGame)

          // reset answers
          this.correctAnswers = []
          this.incorrectAnswers = []

          const video: any = document.getElementById('video')
          if (video) video.play()
        }
      })
  }

  /**
   * Getter that return lesson progress
   *
   * @return {boolean} // True -> if its last game, false - if not
   *
   */
  get lessonProgress(): number {
    const totalAnswers = this.correctAnswers.length + this.incorrectAnswers.length
    const correctAnswers = this.correctAnswers.length
    return Math.round((correctAnswers / totalAnswers) * 100)
  }

  /**
   * Getter that return is the current game is last game
   *
   * @return {boolean} // True -> if its last game, false - if not
   *
   */
  get checkLastGame(): boolean {
    const totalGamesIndex = this.totalGames - 1
    return this.currentGame === totalGamesIndex
  }

  /**
   * Check is current lesson has TEST type (true if yes)
   *
   * @return {boolean}
   *
   */
  get hasLessonTestType(): boolean {
    return this.lesson.type.name === 'Mini Test'
  }

  /**
   * Check is current lesson has UNIT TEST type (true if yes)
   *
   * @return {boolean}
   *
   */
  get hasUnitTestType(): boolean {
    return this.lesson.type.name === 'Unit Test'
  }

  /**
   * Check is current lesson has GAME type (true if yes)
   *
   * @return {boolean}
   *
   */
  get hasLessonGameType(): boolean {
    return this.lesson.type.name === 'Game'
  }

  /**
   * Check is current lesson has BOOK type (true if yes)
   *
   * @return {boolean}
   *
   */
  get hasLessonBookType(): boolean {
    return this.lesson.type.name === 'Book'
  }
  /**
   * Check is current lesson has VIDEO type (true if yes)
   *
   * @return {boolean}
   *
   */
  get hasLessonVideoType(): boolean {
    return this.lesson.type.name === 'Video' || this.lesson.type.name === 'Video Song'
  }

  public openReportPopup() {
    this._dialog.open(ReportProblemPopupComponent, {
      closeOnNavigation: true,
      hasBackdrop: true,
      data: this.lesson.questions,
      panelClass: 'medium-adaptive-popup',
    })
  }

  public changeToolTip(event: Event) {
    this.displayTooltip = !this.displayTooltip
    event.stopPropagation()
  }

  public closeToolTip() {
    if (this.displayTooltip) {
      this.displayTooltip = false
    }
  }

  public close() {
    const dialog = this._dialog.open(ConfirmationPopupComponent, {
      panelClass: 'medium-adaptive-popup',
      closeOnNavigation: true,
      hasBackdrop: true,
      data: 'Are you sure you want to exit?',
    })

    dialog.afterClosed().subscribe((answer) => {
      if (!answer) return

      this.router.navigate([this.backLink])
    })
  }

  public noDownload(e) {
    e.preventDefault()
    return false
  }

  public setLanguageTranslation($event) {
    this.selectedTranslation = $event.value
    const oldValue = this.localStorageService.getItem('gameLanguageTranslation')

    if ($event.value === oldValue) {
      return
    }

    this.localStorageService.setItem('gameLanguageTranslation', $event.value)
    this.getLocalizations(this.lesson.id, $event.value)
  }

  private completeAssignment(student: IStudent, lesson: ILesson, progress: number, repeat: number) {
    this._store.dispatch(
      new CompleteAssignment(student.classroom.schoolId, student.classroom.id, {
        studentId: student.id,
        lessonId: lesson.id,
        progress,
        repeat,
      })
    )
  }

  public getDefaultLocale() {
    let locale = this.localStorageService.getItem('gameLanguageTranslation')
    if (!locale) {
      locale = 'en'
    }

    return locale
  }

  private getAvailableLocales(lesson) {
    this._store.dispatch(new GetAvailableLocales(lesson.id))

    this._actions$
      .pipe(
        ofType(ELessonsActions.GetAvailableLocalesSuccess),
        map((res: GetAvailableLocalesSuccess) => res.locales),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((locales: Array<string>) => {
        this.availableLocales = locales
      })
  }

  private getLocalizations(lessonId: number, locale: string) {
    this._store.dispatch(new GetLocalizations(lessonId, locale))
    this._actions$
      .pipe(
        ofType(ELessonsActions.GetLocalizationsSuccess),
        map((res: GetLocalizationsSuccess) => res.localizations),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((localizations: Array<object>) => {
        this.localizations = localizations
      })
  }
}
