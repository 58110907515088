<div *ngIf="book" class="book content">
  <div class="book-container">
    <div class="game-help-text">{{ 'BOOK_COMPONENT.READ_BOOK' | translate }}</div>
    <div class="pageInfo">
      <div class="pageTexts">
        <div class="pageText">
          <p [dir]="dir">{{ book.pages[currentPage].text }}</p>
          <button mat-icon-button color="primary" *ngIf="book.pages[currentPage].audio">
            <audio #audio>
              <source [src]="book.pages[currentPage].audio" type="audio/mp3" />
            </audio>
            <mat-icon (click)="audioPlay(book.pages[currentPage].audio)" color="primary"> volume_down </mat-icon>
          </button>
          <div class="triangle"></div>
        </div>
      </div>
    </div>
    <div class="book-page">
      <button class="book-page-turn-back" appSounds="turnpage" [disabled]="currentPage <= 1" (click)="goToPage(currentPage - 1)">
        <img src="assets/images/lessons/items/orange-prev.png" alt="go back" />
      </button>
      <ng-container *ngFor="let page of book.pages; let i = index">
        <img *ngIf="!page.picture.includes('.json')" class="book-image" [ngClass]="{ active: i === currentPage }" [src]="page.picture" alt="{{ page.picture }}" />
        <ng-lottie *ngIf="page.picture.includes('.json')" class="lottie_p book-image" [ngClass]="{ active: i === currentPage }" [options]="{ path: page.picture }"> </ng-lottie>
      </ng-container>

      <button class="book-page-turn-next" appSounds="turnpage" (click)="goToPage(currentPage + 1)">
        <img src="/assets/images/lessons/items/orange-next.png" alt="go next" />
      </button>
    </div>
  </div>
  <div class="autoplay">
    <div>
      {{ 'MEDIA_PLAYER.AUTOPLAY' | translate }} <span *ngIf="autoplayValue">{{ autoplayValue }} sec</span><span *ngIf="!autoplayValue" class="red">OFF</span>
    </div>
    <mat-slider min="0" max="8" step="1">
      <input matSliderThumb [(ngModel)]="autoplayValue" #slider (change)="manageAutoplay()" />
    </mat-slider>
  </div>
  <div color="primary" class="currentPage">{{ 'MEDIA_PLAYER.TEXT' | translate }} {{ currentPage }}</div>
</div>

<div appSounds class="bugReports" (click)="openReportPopup(book.pages[currentPage])" *ngIf="!subuser?.studentId">
  <img src="/assets/images/lessons/items/report_mobile.png" alt="Bug report" />
</div>
