import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, ElementRef, ViewChild } from '@angular/core'
import { IQuestion } from '../../../models/Question.model'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { IAnswer } from '../../../models/Answer.model'
import { AudioService } from 'src/app/services/audio.service'
import { ReportProblemPopupComponent } from '../../popups/Report-Dialogs/report-problem/report-problem.component'
import { RandomizeService } from '../../../services/randomize.service'
import { TranslateService } from '@ngx-translate/core'
import { ICourse } from '../../../models/Course.model'
import { LocalizationPipe } from '../../../helpers/pipes/game-localization/game-localization.pipe'
import { getLanguageMap } from '../../../helpers/utils/languageMap.util'
import { of } from 'rxjs'
import { delay, take } from 'rxjs/operators'
import { LocalizHelperService } from 'src/app/services/localizHelper.service'

@Component({
  selector: 'audio-to-audio',
  templateUrl: './audio-to-audio.component.html',
  styleUrls: ['./audio-to-audio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AudioToAudioGameComponent implements OnInit, OnChanges {
  @Input() question: IQuestion
  @Input() test: boolean
  @Input() game: boolean
  @Input() translation: string
  @Input() course: ICourse
  @Input() localizations: Array<object>
  @Output() success = new EventEmitter()
  @Output() failed = new EventEmitter()

  @ViewChild('audiohelp') audiohelp: ElementRef

  public currentlang

  constructor(private _dialog: MatDialog, private _RandomizeService: RandomizeService, private translate: TranslateService, private localizationPipe: LocalizationPipe, private _lhServise: LocalizHelperService, private audioService: AudioService) {}

  public questionId: number
  public answers = []
  public selectedAnswer: IAnswer = null
  public checked = false
  public languageMap = getLanguageMap()

  ngOnChanges() {
    this.selectedAnswer = null
    this.checked = false

    if (this.answers.length > 0 && this.question.id != this.questionId) {
      this.answers = []
      this.createAnswersArray(this.question)
      this.playInitialAudio()
    }
  }

  ngOnInit(): void {
    this.currentlang = this._lhServise.getLangForced()

    this.questionId = this.question.id
    this.createAnswersArray(this.question)
    this.playInitialAudio()
  }

  ngOnDestroy() {
    this.audioService.stopAllAudio()
  }

  public audiohelpPlay() {
    this.audiohelp.nativeElement.play()
  }

  public playInitialAudio() {
    of(true)
      .pipe(delay(600), take(1))
      .subscribe(() => this.playLocalizedAudio(this.question, 'question'))
  }

  public createAnswersArray(question) {
    for (let i = 0; i < question.answers.length; i++) {
      const answer = {
        audioSrc: question.answers[i].audioSrc,
        correct: question.answers[i].correct,
        id: question.answers[i].id,
        name: question.answers[i].name,
        pictureSrc: question.answers[i].pictureSrc,
        questionId: question.answers[i].questionId,
        text: question.answers[i].text,
        localization: question.answers[i].localization,
        localizationId: question.answers[i].localizationId,
      }
      this.answers.push(answer)
      this.audioService.preloadAudio(question.answers[i].audioSrc, question.answers[i].audioSrc)
    }

    this._RandomizeService.shuffle(this.answers)
  }

  public selectAnswer(answer) {
    if (this.checked) return
    this.selectedAnswer = answer
  }

  public setAnswer(answer) {
    if (answer.correct) {
      this.success.emit({ question: this.question, answer })
    } else {
      this.failed.emit({ question: this.question, answer })
    }
    this.audioService.stopAudio(this.question.audioSrc)
  }

  public playAudio(src) {
    this.audioService.playAudio(src, src)
  }

  public playLocalizedAudio(source: any, type: 'question' | 'answer'): void {
    if (type === 'question') {
      this.playAudio(this.localizationPipe.transform(source, this.translation, this.localizations).audioSrc)
    } else {
      this.playAudio(this.localizationPipe.transform(source, this.translation, this.localizations).audioSrc)
    }
  }

  public checkAnswer() {
    this.checked = true
    if (this.selectedAnswer.correct) {
      this.audioService.playSoundCorrect()
    } else {
      this.audioService.playSoundIncorrect()
    }
  }

  public get checkRightAnswer() {
    return this.selectedAnswer.correct
  }

  public openReportPopup(question) {
    this._dialog.open(ReportProblemPopupComponent, {
      closeOnNavigation: true,
      hasBackdrop: true,
      data: question,
      panelClass: 'medium-adaptive-popup',
    })
  }

  public get helpText() {
    let helpText = ''

    if (this.question.questionType.slug === 'ata_target') {
      helpText = this.translate.instant('GAME.AUDIO_TO_AUDIO.HELP_TEXT_TARGET')
    } else {
      helpText = this.translate.instant('GAME.AUDIO_TO_AUDIO.HELP_TEXT_LOCAL')
    }

    const translation = this.translation ? this.languageMap.find((l) => l.abbr === this.translation)?.name : 'English'
    return helpText.replace('%target%', this.course.name).replace('%translation%', translation)
  }
}
