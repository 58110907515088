import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router'
import { JWTService } from '../../services/jwt.service'

@Injectable()
export class NoneAuthGuard implements CanActivate {
  constructor(private _router: Router, private _jwtService: JWTService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const jwtToken = this._jwtService.getToken
    if (!jwtToken) {
      return true
    }
    this._router.navigate(['/profile/lessons'])
  }
}
