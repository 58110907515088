import { Component, Output, EventEmitter, Input, OnInit, ChangeDetectionStrategy, OnChanges } from '@angular/core'
import { ReportProblemPopupComponent } from '../../popups/Report-Dialogs/report-problem/report-problem.component'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { playAudio } from 'src/app/helpers/utils/audio.util'
import { IQuestion } from 'src/app/models/Question.model'
import { IAnswer } from 'src/app/models/Answer.model'
import { DefaultPopupSettings } from 'src/app/models/Dialog.model'
import { take, delay } from 'rxjs/operators'
import { of } from 'rxjs'
import { RandomizeService } from 'src/app/services/randomize.service'

@Component({
  selector: 'what-come-after',
  templateUrl: './what-come-after.component.html',
  styleUrls: ['./what-come-after.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WhatComeAfterGameComponent implements OnInit, OnChanges {
  @Input() question: IQuestion
  @Input() test: boolean
  @Input() game: boolean
  @Output() success = new EventEmitter()
  @Output() failed = new EventEmitter()

  constructor(private _dialog: MatDialog, private _RandomizeService: RandomizeService) {}

  public questionId: number
  public selectedAnswer: IAnswer = null
  public checked = false
  public audio = new Audio()
  public altAnswers = []
  public cardColors = []

  /* Clear selected answer and checked answer when inputs are changed */
  ngOnChanges() {
    this.selectedAnswer = null
    this.checked = false
    of(true)
      .pipe(delay(600), take(1))
      .subscribe(() => this.playAudio(this.question.audioSrc))

    if (this.altAnswers.length > 0 && this.question.id != this.questionId) {
      this.altAnswers = []
      this.createAltImagesArray(this.question)
    }
  }

  ngOnInit() {
    this.questionId = this.question.id

    for (let i = 0; i < this.question.answers.length; i++) {
      const altImage = this._RandomizeService.getRandomAnswerImage(this.question.answers[i])
      const answer = {
        audioSrc: this.question.answers[i].audioSrc,
        correct: this.question.answers[i].correct,
        id: this.question.answers[i].id,
        name: this.question.answers[i].name,
        pictureSrc: altImage,
        questionId: this.question.answers[i].questionId,
        text: this.question.answers[i].text,
      }
      this.altAnswers.push(answer)
    }
    this._RandomizeService.shuffle(this.altAnswers)

    of(true)
      .pipe(delay(600), take(1))
      .subscribe(() => this.playAudio(this.question.audioSrc))

    this.cardColors = this._RandomizeService.getRandom12Colors()
  }

  ngOnDestroy() {
    if (this.audio) this.audio.pause()
  }

  public createAltImagesArray(question) {
    for (let i = 0; i < question.answers.length; i++) {
      const altImage = this._RandomizeService.getRandomAnswerImage(question.answers[i])
      const answer = {
        audioSrc: question.answers[i].audioSrc,
        correct: question.answers[i].correct,
        id: question.answers[i].id,
        name: question.answers[i].name,
        pictureSrc: altImage,
        questionId: question.answers[i].questionId,
        text: question.answers[i].text,
      }

      this.altAnswers.push(answer)
    }
    this._RandomizeService.shuffle(this.altAnswers)

    this.cardColors = this._RandomizeService.getRandom12Colors()
  }

  public selectAnswer(answer) {
    if (this.checked) return
    this.selectedAnswer = answer
  }

  public playAudio(src) {
    this.audio.src = src
    this.audio.load()
    this.audio.play()
  }

  public setAnswer(answer) {
    this.audio.pause()
    if (answer.correct) this.success.emit({ question: this.question, answer })
    else this.failed.emit({ question: this.question, answer })
  }

  public checkAnswer() {
    this.checked = true
    if (this.selectedAnswer.correct && this.game) {
      playAudio('/assets/images/sounds/correct.mp3')
    }
  }

  public setAnswerTest(answer) {
    this.audio.pause()
    if (answer.correct) this.success.emit({ question: this.question, answer })
    else this.failed.emit({ question: this.question, answer })
  }

  public get checkRightAnswer() {
    return this.selectedAnswer.correct
  }

  public openReportPopup(question) {
    this._dialog.open(ReportProblemPopupComponent, new DefaultPopupSettings(question))
  }
}
