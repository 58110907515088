import { Component } from '@angular/core'
import { CookieService } from 'ngx-cookie-service'
import { LocalStorageService } from '../../../../services/localStorage'
import { TranslateService } from '@ngx-translate/core'
import { Router } from '@angular/router'
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'

@Component({
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
})
export class UnauthorizedComponent {
  constructor(private _cookiesService: CookieService, private localStorageService: LocalStorageService, public translate: TranslateService, private _router: Router, public dialogRef: MatDialogRef<UnauthorizedComponent>) {}
  clear() {
    this._cookiesService.delete('refreshToken')
    this.localStorageService.clear()
    // location.reload();                   // | AUTH FIX
    this._router.navigate(['/auth/login']) // | AUTH FIX
    this.dialogRef.close() // | AUTH FIX
  }
}
