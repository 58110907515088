import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { LessonResolver } from 'src/app/helpers/resolvers/lesson.resolver'
import { ParentsComponent } from './parents/parents.component'
import { ParentsSubusersComponent } from './parents/subusers/subusers.component'
import { ProfileComponent } from './profile.component'
import { SubuserComponent } from './parents/subuser/subuser.component'
import { ParentsProfileComponent } from './parents/profile/profile.component'
import { ParentsNotificationsComponent } from './parents/notifications/notifications.component'
import { ProfileLessonsComponent } from './lessons/lessons.component'
import { LessonComponent } from './lessons/lesson/lesson.component'
import { UserResolver } from '../../helpers/resolvers/user.resolver'
import { ProgressReportsComponent } from './progress-reports/progress-reports.component'
import { CurriculumComponent } from '../curriculum/curriculum.component'
import { SubuserResolver } from '../../helpers/resolvers/subuser.resolver'
import { CheckRoleGuard } from '../../helpers/guards/check-role.guard'
import { BulkCreateComponent } from './students/bulk-create/bulk-create.component'
import { TeachersComponent } from './teachers/teachers.component'
import { CoursesResolver } from '../../helpers/resolvers/courses.resolver'
import { ClassroomsComponent } from './classrooms/classrooms.component'
import { SchoolResolver } from '../../helpers/resolvers/school.resolver'
import { GradebookComponent } from './teachers/gradebook/gradebook.component'
import { GradebookStudentComponent } from './parents/student/gradebook/gradebook.component'
import { TutorialsComponent } from './tutorials/tutorials.component'
import { MobileDashboardComponent } from 'src/app/views/profile/parents/mobile-dashboard/mobile-dashboard.component'
import { SubscriptionsComponent } from './parents/subscriptions/subscriptions.component'
import { SubscriptionsSuccessfullyComponent } from './parents/subscriptions/successfully/successfully.component'
import { SubscriptionsStripeComponent } from './parents/subscriptions/stripe/stripe.component'

const profileRoutes: Routes = [
  {
    path: '',
    component: ProfileComponent,
    children: [
      {
        path: 'lessons',
        component: ProfileLessonsComponent,
      },
      {
        path: 'dashboard',
        component: MobileDashboardComponent,
      },
      {
        path: 'lesson-plan',
        component: CurriculumComponent,
        // canActivate: [CheckRoleGuard], // TODO: CheckRoleGuard causes redirection to Auth and back
        data: {
          allowedRoles: ['parent', 'student', 'teacherAdmin', 'teacher', 'admin'],
        },
        // resolve: { // TODO: SubuserResolver prevents loading CurriculumComponent when page is reloaded
        //   subusers: SubuserResolver,
        // },
      },
      {
        path: 'lesson/:id/:type',
        resolve: {
          lesson: LessonResolver,
        },
        component: LessonComponent,
        // canDeactivate: [YourComponentCanDeactivateGuardService]
      },
      {
        path: 'parents',
        component: ParentsComponent,
        // canActivate: [CheckRoleGuard],
        // data: { allowedRoles: ['parent'] },
        resolve: {
          user: UserResolver,
        },
        children: [
          {
            path: '',
            redirectTo: 'parents/subusers',
            pathMatch: 'full',
          },
          {
            path: 'subusers',
            component: ParentsSubusersComponent,
            canActivate: [CheckRoleGuard], // TODO: CheckRoleGuard causes redirection to Auth and back
            data: {
              allowedRoles: ['parent'],
            },
          },
          {
            path: 'dashboard',
            component: MobileDashboardComponent,
            canActivate: [CheckRoleGuard],
            data: {
              allowedRoles: ['parent'],
            },
          },
          {
            path: 'subuser/:id',
            component: SubuserComponent,
            canActivate: [CheckRoleGuard],
            data: {
              allowedRoles: ['parent'],
            },
          },
          {
            path: 'tutorials',
            component: TutorialsComponent,
            data: {
              target: 'isParent',
            },
          },
          {
            path: 'studentTutorials',
            component: TutorialsComponent,
            data: {
              target: 'isStudent',
            },
          },
          {
            path: 'gradebook',
            component: GradebookStudentComponent,
          },
          {
            path: 'profile',
            component: ParentsProfileComponent,
          },
          {
            path: 'subscriptions',
            component: SubscriptionsComponent,
            children: [
              {
                path: '',
                redirectTo: 'stripe',
                pathMatch: 'full',
              },
              {
                path: 'success',
                component: SubscriptionsSuccessfullyComponent,
              },
              {
                path: 'stripe',
                component: SubscriptionsStripeComponent,
              },
            ],
          },
          {
            path: 'notifications',
            component: ParentsNotificationsComponent,
          },
          {
            path: 'progress-reports',
            component: ProgressReportsComponent,
            resolve: {
              courses: CoursesResolver,
            },
          },
        ],
      },

      // teacher aliases
      {
        path: 'teachers',
        component: ParentsComponent,
        resolve: {
          school: SchoolResolver,
        },
        canActivate: [CheckRoleGuard],
        data: { allowedRoles: ['teacher', 'teacherAdmin'] },
        children: [
          {
            path: '',
            redirectTo: 'teachers/students',
            pathMatch: 'full',
          },
          {
            path: 'students',
            component: ClassroomsComponent,
          },
          {
            path: 'student/:id',
            component: SubuserComponent,
          },
          {
            path: 'profile',
            component: ParentsProfileComponent,
          },
          {
            path: 'notifications',
            component: ParentsNotificationsComponent,
          },
          {
            path: 'progress-reports',
            component: ProgressReportsComponent,
            resolve: {
              courses: CoursesResolver,
            },
          },
          {
            path: 'students/bulk-create',
            component: BulkCreateComponent,
          },
          {
            path: 'teachers',
            component: TeachersComponent,
            data: { allowedRoles: ['teacherAdmin'] },
          },
          {
            path: 'gradebook',
            component: GradebookComponent,
          },
          {
            path: 'tutorials',
            component: TutorialsComponent,
            data: {
              target: 'isTeacher',
            },
          },
        ],
      },
      {
        path: '**',
        redirectTo: '/profile/lessons',
        pathMatch: 'full',
      },
    ],
  },
]

@NgModule({
  imports: [RouterModule.forChild(profileRoutes)],
  exports: [RouterModule],
})
export class ProfileRoutingModule {}
