import { Component, OnDestroy, OnInit } from '@angular/core'
import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { Store, select } from '@ngrx/store'
import { IAppState } from 'src/app/store/state/app.state'
import { ICourse } from 'src/app/models/Course.model'
import { Router } from '@angular/router'
import { selectSubuser, selectCurrentSubuser } from 'src/app/store/selectors/subuser.selectors'
import { selectCurrentCourse } from 'src/app/store/selectors/courses.selectors'
import { selectStatistics } from 'src/app/store/selectors/statistics.selectors'
import { SetCurrentCourse } from 'src/app/store/actions/courses.actions'
import { TranslateService } from '@ngx-translate/core'
import { GetStatistics } from 'src/app/store/actions/statistics.actions'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { GetSubuser } from 'src/app/store/actions/subuser.actions'
import { ISubuser } from 'src/app/models/Subuser.model'
import { LocalStorageService } from '../../../../services/localStorage'
import { FormatTextService } from '../../../../services/formatText.service'
import { CheckParentPinComponent } from '../../Parent-Dialogs/checkParentPin/checkParentPin.component'

import { GetUser } from 'src/app/store/actions/user.actions'
import { selectCurrentUser } from 'src/app/store/selectors/user.selectors'

@Component({
  templateUrl: './selectCourse.component.html',
  styleUrls: ['./selectCourse.component.scss'],
})
export class SelectCourseDialogComponent implements OnInit, OnDestroy {
  constructor(
    public dialogRef: MatDialogRef<SelectCourseDialogComponent>,
    private _store: Store<IAppState>,
    public translate: TranslateService,
    private localStorageService: LocalStorageService,
    private textService: FormatTextService,
    public dialog: MatDialog,
    public router: Router
  ) {}
  private unsubscribe$ = new Subject()

  public subuser$ = this._store.pipe(takeUntil(this.unsubscribe$), select(selectSubuser))
  public course$ = this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentCourse))
  public statistics$ = this._store.pipe(takeUntil(this.unsubscribe$), select(selectStatistics))
  public currentSubuser: ISubuser
  public unsubscrib$ = new Subject()
  public user: any

  ngOnInit(): void {
    const currentSubuser = JSON.parse(this.localStorageService.getItem('currentSubuser'))

    this._store.dispatch(new GetUser())
    this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentUser)).subscribe((user) => {
      if (user) this.user = user.user
    })

    if (currentSubuser) {
      this.currentSubuser = currentSubuser
      this._store.dispatch(new GetSubuser(+this.currentSubuser.id))
    } else {
      this._store.pipe(takeUntil(this.unsubscrib$), select(selectCurrentSubuser)).subscribe((subuser) => {
        this.currentSubuser = subuser
        this._store.dispatch(new GetSubuser(+this.currentSubuser.id))
      })
    }
  }

  ngOnDestroy(): void {
    this.unsubscrib$.next()
    this.unsubscrib$.complete()
  }

  public formatLangName(course) {
    return this.textService.formatLangName(course)
  }

  selectCourse(course: ICourse) {
    this._store.pipe(takeUntil(this.unsubscrib$), select(selectCurrentSubuser)).subscribe((res) => {
      this.currentSubuser = res
    })

    this.localStorageService.setItem(`${this.currentSubuser.id}`, JSON.stringify(course))

    const localCourse = this.localStorageService.getItem(`${this.currentSubuser.id}`)

    const courses = JSON.parse(localCourse)
    this._store.dispatch(new SetCurrentCourse(JSON.parse(localCourse)))

    const request = {
      subuserId: this.currentSubuser.id,
      courseId: courses.id,
    }

    this._store.dispatch(new GetStatistics(request))

    this.dialogRef.close()
  }

  openParentDashboard() {
    this.dialog.closeAll()
    this.router.navigate(['/profile/parents/subusers'])
  }
}
