import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { FormsModule } from '@angular/forms'
import { PictureToAudioGameComponent } from './picture-to-audio.component'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatIconModule } from '@angular/material/icon'
import { TranslateModule } from '@ngx-translate/core'
import { SoundsModule } from 'src/app/helpers/directives/sounds-directive/sounds.module'
import { LottieModule } from 'ngx-lottie'
import { ReportProblemModule } from "src/app/shared/components/report-problem/report-problem.module";

@NgModule({
    declarations: [PictureToAudioGameComponent],
    exports: [PictureToAudioGameComponent],
    imports: [CommonModule, FormsModule, RouterModule, MatButtonModule, MatIconModule, TranslateModule, SoundsModule, LottieModule, ReportProblemModule]
})
export class PictureToAudioGameModule {}
