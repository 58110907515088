<section class="restoreWrapper">
  <div class="header">
    <div class="titleWrapper">
      <a routerLink="{{ getURLLangPart() }}/">
        <img src="assets/images/logos/logo-wy.svg" class="logo" alt="DinoLingo Logo" />
      </a>
    </div>
    <localization></localization>
  </div>
  <div class="content">
    <div class="details">
      <mat-horizontal-stepper #matStepper linear="true" class="stepper" #stepper>
        <mat-step>
          <form class="restoreForm" [formGroup]="restoreForm" (ngSubmit)="onSubmit()">
            <div class="title-cont">
              <h1 class="baloo">{{ 'PROFILE.PASSWORD_RESTORE' | translate }}</h1>
              <span>{{ 'PROFILE.RESTORE.STEP_1' | translate }}</span>
            </div>
            <p>{{ 'PROFILE.EMAIL_ENTER' | translate }}</p>
            <div class="inputGroup">
              <input type="email" required placeholder="{{ 'PROFILE.EMAIL' | translate }}" formControlName="email" />
              <ng-container *ngIf="(restoreForm.get('email').dirty || restoreForm.get('email').touched) && restoreForm.get('email').invalid">
                <mat-error *ngIf="restoreForm.get('email').errors.required">
                  {{ 'PROFILE.EMAIL_REQUIRED' | translate }}
                </mat-error>
                <mat-error *ngIf="restoreForm.get('email').errors.email">
                  {{ 'PROFILE.EMAIL_ERROR' | translate }}
                </mat-error>
              </ng-container>
            </div>
            <div class="inputGroup nolabel">
              <button appSounds mat-raised-button class="login-btn" type="submit" [disabled]="restoreForm.invalid" color="accent">
                {{ 'BUTTONS.NEXT' | translate }}
              </button>
            </div>

            <p>
              {{ 'PROFILE.ACCOUNT_ABSENT' | translate }}
              <a routerLink="{{ getURLLangPart() }}/auth/registration">{{ 'BUTTONS.SING_UP' | translate }}</a>
            </p>

            <p>
              {{ 'PROFILE.ACCOUNT_AVAILABEL' | translate }}
              <a routerLink="{{ getURLLangPart() }}/auth/login">{{ 'BUTTONS.LOGIN' | translate }}</a>
            </p>
          </form>
        </mat-step>
        <mat-step>
          <form class="restoreForm" [formGroup]="codeForm" (ngSubmit)="setNewPassword()">
            <div class="title-cont">
              <h1 class="baloo">{{ 'PROFILE.PASSWORD_RESTORE' | translate }}</h1>
              <span>{{ 'PROFILE.RESTORE.STEP_2' | translate }}</span>
            </div>
            <p>{{ 'PROFILE.CONFIRM_MESSAGE' | translate }}</p>
            <div class="inputGroup">
              <input type="text" name="confirmCode" required placeholder="{{ 'PROFILE.CODE' | translate }}" formControlName="code" />
              <ng-container *ngIf="(codeForm.get('code').dirty || codeForm.get('code').touched) && codeForm.get('code').invalid">
                <mat-error *ngIf="codeForm.get('code').errors"> Please, enter 6 digits code </mat-error>
              </ng-container>
            </div>
            <div class="inputGroup autoheight">
              <input type="text" name="newPassword" required placeholder="{{ 'PROFILE.PASSWORD' | translate }}" formControlName="newPassword" />
              <ng-container *ngIf="(codeForm.get('newPassword').dirty || codeForm.get('newPassword').touched) && codeForm.get('newPassword').invalid">
                <mat-error class="special" *ngIf="codeForm.get('newPassword').errors.required">
                  {{ 'PROFILE.PASSWORD_REQUIRED' | translate }}
                </mat-error>
                <mat-error class="special" *ngIf="(codeForm.get('newPassword').errors.invalidPassword || codeForm.get('newPassword').errors.minlength) && !codeForm.get('newPassword').errors.maxlength">
                  The password must be a minimum of 8 characters and include at least 1 Uppercase letter, 1 lowercase letter, a number, and a symbol &#33; &#64; &#35; &#36; &#37; &#94; &#38; &#42;
                </mat-error>
                <mat-error class="special" *ngIf="codeForm.get('newPassword').errors.maxlength"> Max password length is 20 characters </mat-error>
              </ng-container>
            </div>
            <div class="inputGroup nolabel">
              <button appSounds mat-raised-button class="login-btn" type="submit" color="accent" [disabled]="codeForm.invalid">
                {{ 'PROFILE.PASSWORD_CONFIRM' | translate }}
              </button>
            </div>

            <p>
              {{ 'PROFILE.ACCOUNT_ABSENT' | translate }}
              <a routerLink="{{ getURLLangPart() }}/auth/registration">{{ 'BUTTONS.SING_UP' | translate }}</a>
            </p>

            <p>
              {{ 'PROFILE.ACCOUNT_AVAILABEL' | translate }}
              <a routerLink="{{ getURLLangPart() }}/auth/login">{{ 'BUTTONS.LOGIN' | translate }}</a>
            </p>
          </form>
        </mat-step>
        <mat-step>
          <div class="title-cont">
            <h1 class="baloo">{{ 'PROFILE.PASSWORD_RESTORE' | translate }}</h1>
            <span></span>
          </div>
          <p>{{ 'PROFILE.PASSWORD_CHANGED' | translate }}</p>
          <div class="inputGroup nolabel finish">
            <button appSounds mat-raised-button class="login-btn" routerLink="{{ getURLLangPart() }}/auth/login" color="accent">
              {{ 'BUTTONS.LOGIN' | translate }}
            </button>
          </div>

          <p>
            {{ 'PROFILE.ACCOUNT_ABSENT' | translate }} <a routerLink="{{ getURLLangPart() }}/auth/registration">{{ 'BUTTONS.SING_UP' | translate }}</a>
          </p>

          <p>
            {{ 'PROFILE.ACCOUNT_AVAILABEL' | translate }} <a routerLink="{{ getURLLangPart() }}/auth/login">{{ 'BUTTONS.LOGIN' | translate }}</a>
          </p>
        </mat-step>
      </mat-horizontal-stepper>
    </div>
    <div class="decoration">
      <div class="wrapper">
        <div class="island"><img src="assets/images/login/island.svg" class="prc" alt=" " /></div>
        <div class="animation">
          <div class="anm">
            <img src="assets/images/login/dino_s.png" class="dino" alt=" " />
          </div>
        </div>
        <div class="palms"><img src="assets/images/login/palms.svg" class="prc" alt=" " /></div>
        <div class="anm2"><img src="assets/images/anim/trt.png" class="turtle" alt=" " /></div>
      </div>
    </div>
  </div>
</section>
