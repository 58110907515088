import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { AuthRegistrationComponent } from './registration.component'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { MatStepperModule } from '@angular/material/stepper'
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatIconModule } from '@angular/material/icon'
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner'
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip'
// import { MatSelectCountryModule } from '@angular-material-extensions/select-country';
import { TranslateModule } from '@ngx-translate/core'
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select'
import { SoundsModule } from 'src/app/helpers/directives/sounds-directive/sounds.module'
import { LocalizationModule } from "../../../shared/components/localization/localization.module";

@NgModule({
    declarations: [AuthRegistrationComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatInputModule,
        MatIconModule,
        MatTooltipModule,
        MatStepperModule,
        MatProgressSpinnerModule,
        // MatSelectCountryModule,
        TranslateModule,
        MatSelectModule,
        SoundsModule,
        LocalizationModule
    ]
})
export class AuthRegistrationModule {}
