<div class="addAssignment">
  <div class="actions">
    <span class="title">{{ 'LESSON_PLAN.ASSIGNMENT.CREATE' | translate }} </span>
    <span class="close" (click)="dialogRef.close()">+</span>
  </div>

  <div class="content">
    <div class="form">
      <form [formGroup]="form">
        <mat-form-field class="dueDate">
          <mat-label>{{ 'LESSON_PLAN.ASSIGNMENT.DUE_DATE' | translate }} </mat-label>
          <input matInput [matDatepicker]="picker" required formControlName="dueDate" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </form>
    </div>
  </div>

  <div class="save">
    <button mat-raised-button color="accent" [disabled]="!form.valid" (click)="addAssignment(form)">
      {{ 'LESSON_PLAN.ASSIGNMENT.BUTTON_CREATE' | translate }}
    </button>
  </div>
</div>
