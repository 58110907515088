import { Component, Inject, OnInit } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { Subject } from 'rxjs'
import { Actions, ofType } from '@ngrx/effects'
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'
import { Store } from '@ngrx/store'
import { IAppState } from '../../../../store/state/app.state'
import { TranslateService } from '@ngx-translate/core'
import { filter, takeUntil } from 'rxjs/operators'
import { GetAllCourses } from '../../../../store/actions/courses.actions'
import { AvatarSelectPopupComponent } from '../../Subuser-Dialogs/avatar-select/avatar-select.component'
import { IStudentRequest } from '../../../../models/Student.model'
import { AddStudent, AddStudentFailure, AddStudentSuccess, EStudentActions } from '../../../../store/actions/students.actions'
import { IClassroom } from '../../../../models/Classroom.model'

@Component({
  templateUrl: './add-student.component.html',
  styleUrls: ['./add-student.component.scss'],
})
export class AddStudentDialogComponent implements OnInit {
  public studentForm: UntypedFormGroup

  private destroyed$ = new Subject<boolean>()

  public unsubscribe$ = new Subject()

  public maxStudentsReached = false

  public avatars = []

  public classrooms: IClassroom[] = []

  constructor(private updates$: Actions, private _dialog: MatDialog, public dialogRef: MatDialogRef<AddStudentDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private _store: Store<IAppState>, public translate: TranslateService) {
    this.classrooms = data.classrooms

    this.updates$.pipe(ofType<AddStudentSuccess>(EStudentActions.AddStudentSuccess), takeUntil(this.destroyed$)).subscribe((res: AddStudentSuccess) => {
      this.dialogRef.close(res)
    })

    this.updates$.pipe(ofType<AddStudentFailure>(EStudentActions.AddStudentFailure), takeUntil(this.destroyed$)).subscribe((res: any) => {
      if (res.payload.error.clientMessage === 'You have reached the maximum number of students available to your account') {
        this.maxStudentsReached = true
      }
    })
  }

  ngOnInit() {
    this._store.dispatch(new GetAllCourses())
    this.studentForm = new UntypedFormGroup({
      avatarId: new UntypedFormControl(''),
      avatar: new UntypedFormControl(''),
      schoolId: new UntypedFormControl('', [Validators.required]),
      classroomId: new UntypedFormControl('', [Validators.required]),
      nickname: new UntypedFormControl('', [Validators.required, Validators.minLength(3)]),
      studentCode: new UntypedFormControl('', [Validators.minLength(4), Validators.maxLength(10)]),
    })

    this.studentForm.patchValue({
      schoolId: this.data.schoolId,
    })
  }

  onNoClick(): void {
    this.dialogRef.close()
  }

  createStudent(form: UntypedFormGroup) {
    if (form.invalid) return
    const request: IStudentRequest = form.value
    this._store.dispatch(new AddStudent(request))
  }

  openSelectAvatarPopup() {
    const dialog = this._dialog.open(AvatarSelectPopupComponent, {
      hasBackdrop: true,
      width: '32rem',
      height: '32rem',
    })

    dialog
      .afterClosed()
      .pipe(filter((avatar) => avatar !== null))
      .subscribe((avatar) => {
        if (avatar) {
          this.avatars.unshift(avatar.src)
          this.studentForm.patchValue({
            avatar,
            avatarId: avatar.id,
          })
        }
      })
  }

  public isLottie(url) {
    if (url.includes('.json')) {
      return true
    }
    return false
  }
}
