import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { CurriculumComponent } from './curriculum.component'
import { SidebarModule } from '../profile/parents/sidebar/sidebar.module'
import { TranslateModule } from '@ngx-translate/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { DinosaursPopupModule } from '../../shared/popups/Achievement-Dialogs/dinosaurs/dinosaurs.module'
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field'
import { LessonUnavailablePopupPopupModule } from '../../shared/popups/Lesson-Dialogs/lesson-unavailable/lesson-unavailable.module'
import { RouterModule } from '@angular/router'
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar'
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select'
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table'
import { MatIconModule } from '@angular/material/icon'
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input'
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { SubuserResolver } from '../../helpers/resolvers/subuser.resolver'
import { AddAssignmentDialogModule } from '../../shared/popups/Assignment-Dialogs/add-assignment/add-assignment.module'
import { EditAssignmentDialogModule } from '../../shared/popups/Assignment-Dialogs/edit-assignment/edit-assignment.module'
import { LottieModule } from 'ngx-lottie'

@NgModule({
  declarations: [CurriculumComponent],
  imports: [
    CommonModule,
    SidebarModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatExpansionModule,
    MatIconModule,
    MatTableModule,
    MatButtonModule,
    DinosaursPopupModule,
    MatSelectModule,
    MatFormFieldModule,
    LessonUnavailablePopupPopupModule,
    MatInputModule,
    AddAssignmentDialogModule,
    EditAssignmentDialogModule,
    LottieModule,
  ],
  exports: [CurriculumComponent],
  providers: [SubuserResolver],
})
export class CurriculumModule {}
