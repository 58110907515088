import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'
import { Store } from '@ngrx/store'
import { IAppState } from '../../../../store/state/app.state'
import { TranslateService } from '@ngx-translate/core'
import { Subject } from 'rxjs'
import { ChartData, ChartOptions } from 'chart.js'
import { ISubuser } from '../../../../models/Subuser.model'
import { rangomSpinner } from '../../../../helpers/utils/spiner.util'
import { TimeLogService } from '../../../../services/time-log.service'
import { generateDate } from '../../../../helpers/utils/generateDate'

@Component({
  templateUrl: './all-time-logs.component.html',
  styleUrls: ['./all-time-logs.component.scss'],
})
export class AllTimeLogsComponent implements OnInit, OnDestroy {
  constructor(public timeLog: TimeLogService, private _dialog: MatDialog, public dialogRef: MatDialogRef<AllTimeLogsComponent>, private _store: Store<IAppState>, public translate: TranslateService, @Inject(MAT_DIALOG_DATA) public dialogData) {}

  public subuserTimeLogs
  public displayedColumns: string[] = ['days', 'label', 'value', 'totalTime']
  public dataSource: any[]
  public selectDate

  public ChartLabels: any[]

  public barChartData: ChartData<'bar'>
  public barChartOptions: ChartOptions<'bar'> = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  }

  public lineChartData: ChartData<'line'>
  public lineChartOptions: ChartOptions<'line'> = {
    plugins: {
      legend: { display: false },
    },
  }

  public currentSubuser
  public datesArray = []
  public firstSubuserDate: ISubuser
  public selected
  public data = false
  public dateArray
  public loaderClass: string = rangomSpinner()

  private unsubscribe$ = new Subject()

  public timeLogs

  ngOnInit(): void {
    this.generateDateReports(this.dialogData.log.subuser.createdAt)
    this.getSubuserTimeLog(this.dialogData.log.subuser.id)
  }

  onNoClick(): void {
    this.dialogRef.close()
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  getSubuserTimeLog(subuserId) {
    this.timeLog.getTimeLog(subuserId).subscribe((res) => {
      if (res) {
        this.data = true
        this.setData(res)
      }
    })
  }

  getSubuserReport(month) {
    const request = {
      subuserId: this.dialogData.log.subuser.id,
      firstDay: this.dateArray[month].firstDay,
      lastDay: this.dateArray[month].lastDay,
    }

    this.timeLog.getSelectedTimeLog(request).subscribe((res) => {
      if (res) {
        this.setData(res)
      }
    })
  }

  setData(subuserTimeLogs) {
    this.subuserTimeLogs = subuserTimeLogs
    this.dataSource = subuserTimeLogs.timeTable
    this.ChartLabels = subuserTimeLogs.logTimeChartData.labels

    this.lineChartData = {
      labels: subuserTimeLogs.logTimeChartData.labels,
      datasets: [
        {
          data: subuserTimeLogs.logTimeChartData.totalTime,
          borderColor: 'black',
          backgroundColor: 'rgba(255,0,0,0.3)',
          fill: 'origin',
        },
      ],
    }

    this.barChartData = {
      labels: subuserTimeLogs.logTimeChartData.labels,
      datasets: [{ data: subuserTimeLogs.logTimeChartData.values }],
    }
  }

  public generateDateReports(subuserCreate: string | number | Date) {
    const { selected, resultArray } = generateDate(subuserCreate, this.selected, this.dateArray)

    this.selected = selected
    this.dateArray = resultArray
  }
}
