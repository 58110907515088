import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'
import { UntypedFormGroup, FormControl, Validators, UntypedFormBuilder } from '@angular/forms'
import { IUser } from 'src/app/models/User.model'
import { Store, select } from '@ngrx/store'
import { IAppState } from 'src/app/store/state/app.state'
import { UpdateUser, EUserActions, UpdateUserSuccess } from 'src/app/store/actions/user.actions'
import { Actions, ofType } from '@ngrx/effects'
import { takeUntil } from 'rxjs/operators'
import { Subject, Subscription } from 'rxjs'
import { selectCurrentUser } from 'src/app/store/selectors/user.selectors'
import { NotificationService } from 'src/app/services/notification.service'
import { TranslateService } from '@ngx-translate/core'

@Component({
  templateUrl: './editProfile.component.html',
  styleUrls: ['./editProfile.component.scss'],
})
export class EditProfileDialogComponent implements OnDestroy {
  constructor(public dialogRef: MatDialogRef<EditProfileDialogComponent>, private _store: Store<IAppState>, private updates$: Actions, public notifications: NotificationService, public translate: TranslateService, private fb: UntypedFormBuilder) {
    this.updates$.pipe(ofType<UpdateUserSuccess>(EUserActions.UpdateUserSuccess), takeUntil(this.destroyed$)).subscribe((res: UpdateUserSuccess) => {
      this.dialogRef.close()
      this.notifications.showNotification('User successfully updated', 4)
    })
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  private unsubscribe$ = new Subject()
  public parent: IUser
  defTimeZone = '+00:00'

  public editProfileForm: UntypedFormGroup
  private destroyed$ = new Subject<boolean>()

  public user$: Subscription = this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentUser)).subscribe((parent) => {
    this.parent = parent.user
    this.editProfileForm = this.fb.group({
      firstName: [this.parent.firstName ? this.parent.firstName : ''],
      lastName: [this.parent.lastName ? this.parent.lastName : ''],
      email: [this.parent.email, Validators.required],
      pin: [this.parent.pin, [Validators.maxLength(4), Validators.minLength(4)]],
      timeZone: [this.parent.timeZone],
    })
  })

  updateUser(user: any) {
    user.id = this.parent.id
    this._store.dispatch(new UpdateUser(user))
  }
}
