import { Component, OnInit, ElementRef, ViewChild } from '@angular/core'
import { CookieService } from 'ngx-cookie-service'
import { Router, NavigationStart, NavigationEnd } from '@angular/router'
import { filter } from 'rxjs/operators'

@Component({
  selector: 'app-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.scss'],
})
export class AudioPlayerComponent implements OnInit {
  constructor(/* private _cookieService: CookieService, */ public _router: Router) {}

  @ViewChild('gameAudio') gameAudio: ElementRef

  @ViewChild('volControl') volControl: ElementRef

  public gameMusicVoulme = 0.25
  public musicList = [
    'https://dinolingo-shared.s3.amazonaws.com/sound/BG-MUSIC/ALPHABET-SONG.mp3',
    'https://dinolingo-shared.s3.amazonaws.com/sound/BG-MUSIC/BODY-SONG.mp3',
    'https://dinolingo-shared.s3.amazonaws.com/sound/BG-MUSIC/CLOTHES-SONG.mp3',
    'https://dinolingo-shared.s3.amazonaws.com/sound/BG-MUSIC/COLORS-SONG1.mp3',
    'https://dinolingo-shared.s3.amazonaws.com/sound/BG-MUSIC/COLORS-SONG2.mp3',
    'https://dinolingo-shared.s3.amazonaws.com/sound/BG-MUSIC/HELLO-SONG.mp3',
    'https://dinolingo-shared.s3.amazonaws.com/sound/BG-MUSIC/NATURE-SONG.mp3',
    'https://dinolingo-shared.s3.amazonaws.com/sound/BG-MUSIC/NUMBERS-SONG.mp3',
    'https://dinolingo-shared.s3.amazonaws.com/sound/BG-MUSIC/WHAT-SONG.mp3',
    'https://dinolingo-shared.s3.amazonaws.com/sound/BG-MUSIC/WHERE-SONG.mp3',
  ]
  public musicCounter = Math.floor(Math.random() * this.musicList.length)
  public musicPlays = false

  public isLessonArea = !!this._router.url.includes('parents')
  public inActivity = false

  public visible = false

  ngOnInit() {
    this.visible = this.isVisible(this._router.url.toLowerCase())

    this._router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe((e: NavigationStart) => {
      // see also
      if (e.url.includes('video') || e.url.includes('parents')) {
        this.setGameMusicVolumeValue(0)
        if (e.url.includes('parents')) {
          this.isLessonArea = true
        }
      } else {
        this.returnGameMusicVolumeValue()
        this.isLessonArea = false
      }

      this.inActivity = e.url.includes('/profile/lesson/')

      this.visible = this.isVisible(e.url.toLowerCase())
    })
  }

  playGameMusic() {
    const musicEl = this.gameAudio.nativeElement
    musicEl.volume = this.gameMusicVoulme
    if (musicEl.src === '') {
      musicEl.src = this.musicList[this.musicCounter]
      musicEl.load()
    }

    if (musicEl.paused) {
      musicEl.play()
      this.musicPlays = true
    } else {
      musicEl.pause()
      this.musicPlays = false
    }
  }

  setGameMusicVolume(el) {
    this.gameMusicVoulme = el.target.value / 100
    const { nativeElement } = this.gameAudio
    nativeElement.volume = this.gameMusicVoulme
  }

  /* Need Implement it */
  setGameMusicVolumeValue(val = 0.1) {
    if (this.gameAudio) {
      const musicEl = this.gameAudio.nativeElement
      this.gameMusicVoulme = val
      musicEl.volume = this.gameMusicVoulme
    }
  }

  /* Need Implement it */
  returnGameMusicVolumeValue() {
    this.gameMusicVoulme = 0.25
    // }

    if (this.gameAudio) {
      const musicEl = this.gameAudio.nativeElement
      musicEl.volume = this.gameMusicVoulme
    }
  }

  nextMusicTrack(el) {
    const musicEl = this.gameAudio.nativeElement
    this.musicCounter++
    if (this.musicCounter >= this.musicList.length) {
      this.musicCounter = 0
    }
    musicEl.src = this.musicList[this.musicCounter]
    musicEl.load()
    this.playGameMusic()
  }

  isVisible(url) {
    if (url.includes('parents') || url.includes('teachers') || url.includes('/lesson-plan') || url.includes('/video')) {
      return false
    }

    return true
  }
}
