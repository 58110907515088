import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { AuthRoutingModule } from './auth-routing.module'
import { AuthComponent } from './auth.component'
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { AuthLoginModule } from './login/login.module'
import { AuthRegistrationModule } from './registration/registration.module'
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card'
import { RestorePasswordModule } from './restore-password/restore-password.module'
import { CookiesModule } from 'src/app/shared/components/cookies/cookies.module'
import { CommonModule } from '@angular/common'
import { SoundsModule } from 'src/app/helpers/directives/sounds-directive/sounds.module'

@NgModule({
  declarations: [AuthComponent],
  imports: [AuthRoutingModule, FormsModule, ReactiveFormsModule, MatCardModule, MatInputModule, MatButtonModule, AuthLoginModule, AuthRegistrationModule, RestorePasswordModule, SoundsModule, CookiesModule, CommonModule],
})
export class AuthModule {}
