<section class="registrationWrapper">
  <div class="header">
    <div class="titleWrapper">
      <a routerLink="{{ getURLLangPart() }}/">
        <img src="assets/images/logos/logo-wy.svg" class="logo" alt="DinoLingo Logo" />
      </a>
    </div>
    <localization></localization>
  </div>
  <div class="content">
    <div class="details">
      <mat-horizontal-stepper #matStepper linear="true" class="stepper" #stepper>
        <mat-step>
          <form [formGroup]="signUpFormGroup" (ngSubmit)="registerUser(signUpFormGroup)" class="signUp">
            <div class="title-cont">
              <h1 class="baloo">
                <small>{{ 'PROFILE.SIGN_UP' | translate }}</small>
              </h1>
              <p>{{ 'PROFILE.STEP_1' | translate }}</p>
            </div>

            <div class="inputGroup">
              <mat-form-field class="select-dinostyle">
                <mat-select [(value)]="selectedCourse" formControlName="courseId" placeholder="{{ 'PLACEHOLDERS.SIGNUP_CHOOSE_LANGUAGE' | translate }}">
                  <ng-container *ngFor="let course of courses$ | async">
                    <mat-option [value]="course.id" *ngIf="course.active">{{ formatCourseName(course) }} </mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>
              <ng-container *ngIf="!signUpFormGroup.get('courseId').value">
                <mat-error>{{ 'PROFILE.LANG_REQUIRED' | translate }}</mat-error>
              </ng-container>
            </div>

            <div class="inputGroup">
              <input type="text" required placeholder="{{ 'PROFILE.NAME' | translate }}" maxlength="10" formControlName="nickname" />
              <ng-container *ngIf="signUpFormGroup.get('nickname').invalid">
                <mat-error *ngIf="signUpFormGroup.get('nickname').errors.required">
                  {{ 'PROFILE.NAME_REQUIRED' | translate }}
                </mat-error>
                <mat-error *ngIf="signUpFormGroup.get('nickname').errors.minlength && !signUpFormGroup.get('nickname').errors.required"> Min. length is 3 characters </mat-error>
              </ng-container>
            </div>
            <div class="separator"></div>

            <p class="center-text" [innerHTML]="'PROFILE.ADD_LANGUAGES' | translate"></p>

            <div class="separator"></div>
            <p>{{ 'PROFILE.SIGN_UP_EMAIL' | translate }}</p>

            <div class="inputGroup">
              <input type="email" required placeholder="{{ 'PROFILE.EMAIL_ENTER' | translate }}" formControlName="email" />
              <ng-container *ngIf="(signUpFormGroup.get('email').dirty || signUpFormGroup.get('email').touched) && signUpFormGroup.get('email').invalid">
                <mat-error *ngIf="signUpFormGroup.get('email').errors.required">
                  {{ 'PROFILE.EMAIL_REQUIRED' | translate }}
                </mat-error>
                <mat-error *ngIf="signUpFormGroup.get('email').errors.email">
                  {{ 'PROFILE.EMAIL_ERROR' | translate }}
                </mat-error>
              </ng-container>
            </div>

            <div class="inputGroup autoheight">
              <input type="password" [type]="hidePassword ? 'password' : 'text'" required placeholder="{{ 'PROFILE.PASSWORD' | translate }}" formControlName="password" maxlength="20" />
              <div class="hide">
                <mat-icon (click)="hidePassword = !hidePassword">{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
              </div>
              <ng-container *ngIf="(signUpFormGroup.get('password').dirty || signUpFormGroup.get('password').touched) && signUpFormGroup.get('password').invalid">
                <mat-error class="special" *ngIf="signUpFormGroup.get('password').errors.required">
                  {{ 'PROFILE.PASSWORD_REQUIRED' | translate }}
                </mat-error>
                <mat-error class="special" *ngIf="(signUpFormGroup.get('password').errors.invalidPassword || signUpFormGroup.get('password').errors.minlength) && !signUpFormGroup.get('password').errors.maxlength">
                  The password must be a minimum of 8 characters and include at least 1 Uppercase letter, 1 lowercase letter, a number, and a symbol &#33; &#64; &#35; &#36; &#37; &#94; &#38; &#42;
                </mat-error>
                <mat-error class="special" *ngIf="signUpFormGroup.get('password').errors.maxlength"> Max password length is 20 characters </mat-error>
              </ng-container>
            </div>

            <div class="separator margin-top-minus-5"></div>

            <p class="center-text">{{ 'PROFILE.SEVEN_DAYS_FREE' | translate }}</p>

            <div class="separator"></div>

            <div class="inputGroup">
              <mat-form-field class="select-dinostyle">
                <mat-select formControlName="planId" required>
                  <mat-option [value]="monthlyPlanId">{{ 'SUBSCRIPTIONS.PRICE_TITLE_MONTHLY' | translate }}</mat-option>
                  <mat-option [value]="annualPlanId">{{ 'SUBSCRIPTIONS.PRICE_TITLE_YEARLY' | translate }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="inputGroup nolabel">
              <button appSounds mat-raised-button class="login-btn" type="submit" [disabled]="signUpFormGroup.invalid || formSent" color="accent">
                {{ 'BUTTONS.CONTINUE' | translate }}
              </button>
            </div>

            <p class="terms">
              {{ 'PROFILE.TEXT_ACCEPT' | translate }}
              <a href="https://help.dinolingo.com/article/494-terms" target="_blank">{{ 'PROFILE.TEXT_TERMS' | translate }}</a>
              {{ 'PROFILE.TEXT_AND' | translate }}
              <a href="https://help.dinolingo.com/article/493-privacy" target="_blank">{{ 'PROFILE.TEXT_POLICY' | translate }}</a
              >.
            </p>

            <div class="inputGroup autoheight">
              <p>{{ 'PROFILE.ACCOUNT_AVAILABEL' | translate }}</p>

              <a appSounds mat-raised-button class="signup-btn" routerLink="{{ getURLLangPart() }}/auth/login">
                {{ 'BUTTONS.LOGIN' | translate }}
              </a>
            </div>
          </form>
        </mat-step>
      </mat-horizontal-stepper>
    </div>
    <div class="decoration">
      <div class="wrapper">
        <div class="island">
          <img src="assets/images/login/island.svg" class="prc" alt=" " />
        </div>
        <div class="animation">
          <div class="anm">
            <img src="assets/images/login/dino_s.png" class="dino" alt=" " />
          </div>
        </div>
        <div class="palms">
          <img src="assets/images/login/palms.svg" class="prc" alt=" " />
        </div>
        <div class="anm2">
          <img src="assets/images/anim/jf.png" class="jellyfish" alt=" " />
        </div>
      </div>
    </div>
  </div>
</section>
