import { ICourse } from '../../models/Course.model'

export interface ILessonsState {
  displayHeader: boolean
  lessons: any
  prints: any
  printsCount: any
  currentLesson: any
  currentLessonVideos: any
  currentLessonGames: any
  currentLessonTest: any
  // lessonWordsList: any;
  // lessonWords: any;
  unitsWithLessons: any
}

export const InitialLessonsState: ILessonsState = {
  displayHeader: true,
  lessons: null,
  prints: null,
  printsCount: null,
  currentLesson: null,
  currentLessonVideos: null,
  currentLessonGames: null,
  currentLessonTest: null,
  // lessonWordsList: null,
  // lessonWords: null,
  unitsWithLessons: null,
}
