import { OnInit, Component } from '@angular/core'
import { LangChangeEvent, TranslateService } from '@ngx-translate/core'
import { CookieService } from 'ngx-cookie-service'
import { Router, ActivatedRoute } from '@angular/router'
import { SEOService } from 'src/app/services/seo.service'
import { LocalizHelperService } from 'src/app/services/localizHelper.service'

@Component({
  selector: 'app-about-json',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
})
export class AboutUSComponent implements OnInit {
  public acceptCookie = null
  constructor(public translate: TranslateService, private _cookieService: CookieService, private router: Router, private _seoService: SEOService, private route: ActivatedRoute, private _lhServise: LocalizHelperService) {}

  public currentLang: string
  public name: string
  public displayCookiePopup = true
  public metaTitle = ''
  public url: string = this.router.url
  public langParam = this.route.snapshot.params && this.route.snapshot.params.lang ? this.route.snapshot.params.lang : ''

  public lURL

  ngOnInit(): void {
    const { meta } = this.route.snapshot.data

    this._lhServise.setURLLang(this.langParam)
    this.currentLang = this._lhServise.getLang()
    this.lURL = this._lhServise.getURLLang()

    const localMeta = meta[this.currentLang] == undefined ? meta['en'] : meta[this.currentLang]

    this._seoService.updateTitle(localMeta.title)
    this._seoService.updateDescription(localMeta.description)
    this._seoService.createCanonicalLink(localMeta.canonical)
    this._lhServise.createLangMetaLinks()

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this._seoService.updateTitle(meta[event.lang]['title'])
      this._seoService.updateDescription(meta[event.lang]['description'])
      this._seoService.createCanonicalLink(meta[event.lang]['canonical'])
      this.lURL = this._lhServise.getURLLang()
    })

    // cookie pop up
    this.acceptCookie = this._cookieService.get('acceptCookie')
    if (this.acceptCookie) {
      this.displayCookiePopup = false
    }
  }

  public getURLLangPart() {
    let p = this.lURL
    return p ? `/${p}` : ''
  }
}
