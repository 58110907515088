import { Component, OnInit } from '@angular/core'
import { CookieService } from 'ngx-cookie-service'

@Component({
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss'],
})
export class SubscriptionsComponent implements OnInit {
  constructor(private _cookieService: CookieService) {}

  ngOnInit() {}
}
