import { NgModule } from '@angular/core'
import { ProfileRoutingModule } from './profile-routing.module'
import { ProfileComponent } from './profile.component'
import { HttpClientModule } from '@angular/common/http'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { ParentsModule } from './parents/parents.module'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatIconModule } from '@angular/material/icon'
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card'
import { MatBadgeModule } from '@angular/material/badge'
import { MatRippleModule } from '@angular/material/core'
import { ProfileLessonsModule } from './lessons/lessons.module'
import { CommonModule } from '@angular/common'
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog'
import { HeaderModule } from 'src/app/shared/components/header/header.module'
import { AudioPlayerModule } from 'src/app/shared/components/audio-player/audio-player.module'
import { LessonModule } from './lessons/lesson/lesson.module'
import { LessonResolver } from 'src/app/helpers/resolvers/lesson.resolver'
import { CookiesModule } from 'src/app/shared/components/cookies/cookies.module'
import { AppMessageModule } from 'src/app/shared/components/app-message/app-message.module'
import { AddSubuserDialogModule } from 'src/app/shared/popups/Subuser-Dialogs/add-subuser/addSubuser.module'
import { SelectCourseDialogModule } from 'src/app/shared/popups/Subuser-Dialogs/select-course/selectCourse.module'
import { StarsPopupModule } from 'src/app/shared/popups/Achievement-Dialogs/stars/stars.module'
import { DinosaursPopupModule } from 'src/app/shared/popups/Achievement-Dialogs/dinosaurs/dinosaurs.module'
import { AchievementsPopupModule } from 'src/app/shared/popups/Achievement-Dialogs/achievements/achievements.module'
import { CurrentUserPopupModule } from 'src/app/shared/popups/Parent-Dialogs/current-user/current-user.module'
import { CurriculumModule } from '../curriculum/curriculum.module'
import { BulkCreateModule } from './students/bulk-create/bulk-create.module'
import { TeachersModule } from './teachers/teachers.module'
import { ClassroomsModule } from './classrooms/classrooms.module'
import { GradebookModule } from './teachers/gradebook/gradebook.module'
import { TutorialsModule } from './tutorials/tutorials.module'
import { MobileDashboardModule } from 'src/app/views/profile/parents/mobile-dashboard/mobile-dashboard.module'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'

@NgModule({
  declarations: [ProfileComponent],
  imports: [
    ProfileRoutingModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    ProfileLessonsModule,
    ParentsModule,
    MatButtonModule,
    MatIconModule,
    CurrentUserPopupModule,
    StarsPopupModule,
    DinosaursPopupModule,
    AchievementsPopupModule,
    AddSubuserDialogModule,
    MatCardModule,
    MatBadgeModule,
    MatRippleModule,
    MatDialogModule,
    HeaderModule,
    LessonModule,
    AudioPlayerModule,
    CookiesModule,
    AppMessageModule,
    SelectCourseDialogModule,
    CurriculumModule,
    BulkCreateModule,
    TeachersModule,
    ClassroomsModule,
    GradebookModule,
    TutorialsModule,
    MobileDashboardModule,
    MatProgressBarModule,
    MatSelectModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
  ],
  providers: [LessonResolver],
})
export class ProfileModule {}
