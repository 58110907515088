import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card'
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { AuthLoginComponent } from './login.component'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { MatIconModule } from '@angular/material/icon'
// import { LottieModule } from 'ngx-lottie';
import { TranslateModule } from '@ngx-translate/core'
import { SoundsModule } from 'src/app/helpers/directives/sounds-directive/sounds.module'
import { LoginStudentModule } from '../login-student/login-student.module'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { LocalizationModule } from "../../../shared/components/localization/localization.module";

@NgModule({
    declarations: [AuthLoginComponent],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        MatCardModule,
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        // LottieModule,
        CommonModule,
        TranslateModule,
        SoundsModule,
        LoginStudentModule,
        MatProgressBarModule,
        LocalizationModule
    ]
})
export class AuthLoginModule {}
