<section [ngClass]="{ test: test }" class="game textToAudio">
  <div class="game-help-text">
    <button mat-icon-button color="primary" (click)="audiohelpPlay()">
      <audio #audiohelp>
        <source src="/assets/images/sounds/instructions/{{ currentlang }}/TTA.mp3" type="audio/mp3" />
      </audio>
      <mat-icon color="primary"> volume_down </mat-icon>
    </button>
    {{ 'GAME.TEXT_TO_AUDIO.HELP_TEXT' | translate }}
  </div>
  <ng-container *ngIf="game">
    <img class="trees left" src="/assets/images/games/text-to-text/gameBackground.svg" alt=" " />
    <img class="trees right" src="/assets/images/games/text-to-text/gameBackground2.svg" alt=" " />
    <img class="trees center" src="/assets/images/games/text-to-text/gameBackground3.svg" alt=" " />
  </ng-container>
  <ng-container *ngIf="test">
    <img class="trees left" src="/assets/images/tests/testBackground.svg" alt=" " />
    <img class="trees right" src="/assets/images/tests/testBackground2.svg" alt=" " />
    <img class="trees center" src="/assets/images/tests/testBackground3.svg" alt=" " />
  </ng-container>
  <article class="contentWrapper">
    <div class="questionBlock">
      <div class="pictureWrapper">
        <img [src]="question.pictureSrc" [alt]="question.text" />
      </div>
      <div class="textBubble">
        <div class="questionText" *ngIf="question.questionType.slug === 'tta_target'" [dir]="dir">{{ question.text }}</div>
        <div class="questionText local" *ngIf="question.questionType.slug === 'tta_local'" [dir]="dir2">{{ (question | localization : translation : localizations).text }}</div>
      </div>
    </div>
    <div class="answers">
      <div class="answerAudio" *ngFor="let answer of answers; let index = index">
        <div class="answer" [ngClass]="{ answerSelected: selectedAnswer?.id === answer.id }">
          <ng-container *ngIf="checked && answer.correct">
            <button mat-fab color="primary" class="correct selected bottom"></button>
          </ng-container>
          <ng-container *ngIf="selectedAnswer?.id === answer.id">
            <button
              mat-fab
              color="primary"
              [ngClass]="{
                correct: checked && checkRightAnswer,
                incorrect: checked && !checkRightAnswer
              }"
              class="selected bottom"
            ></button>
          </ng-container>

          <img (click)="selectAnswer(answer); playAudio(answer.audioSrc)" src="/assets/images/games/picture-to-audio/audio.svg" alt="play-back-sound" />
        </div>
      </div>
    </div>
  </article>
  <div *ngIf="checked && checkRightAnswer" class="anim stars"><img src="/assets/images/anim/check_stars.png" alt="check" /></div>
  <div class="continue advanced" [ngClass]="{ 'continue-visible': selectedAnswer && !checked }" *ngIf="!checked" (click)="checkAnswer()" mat-button>
    <img src="/assets/images/icons/check.svg" alt="check" />
    <span>{{ 'BUTTONS.CHECK' | translate }}</span>
  </div>
  <div class="continue advanced" [ngClass]="{ 'continue-visible': checked }" *ngIf="checked" (click)="setAnswer(selectedAnswer)" mat-button>
    <img class="continueNext" src="/assets/images/icons/next.svg" alt="next" />
    <span>{{ 'BUTTONS.CONTINUE' | translate }}</span>
  </div>

  <app-report-problem (click)="openReportPopup(question)"></app-report-problem>
</section>
