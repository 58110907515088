import { Component, OnDestroy, OnInit } from '@angular/core'
import { MatDialog } from "@angular/material/dialog"
import { Subject } from 'rxjs'
import { takeUntil } from "rxjs/operators"
import { select, Store } from "@ngrx/store"
import { selectCurrentUser } from "src/app/store/selectors/user.selectors"
import { IAppState } from "src/app/store/state/app.state"

@Component({
  selector: 'app-report-problem',
  templateUrl: 'report-problem.component.html',
  styleUrls: ['report-problem.component.scss'],
})
export class ReportProblemComponent implements OnInit, OnDestroy {
  
  public unsubscribe$ = new Subject()
  public _user: any

  constructor(
    private _store: Store<IAppState>,
    public _dialog: MatDialog,) {}

    ngOnDestroy(): void {
      this.unsubscribe$.next()
      this.unsubscribe$.complete()
    }

    ngOnInit() {
      this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentUser)).subscribe(({ user }) => {
        if (user) {
          this._user = user.user
        }
      })
    }
  }