import { OnInit, Component } from '@angular/core'
import { CookieService } from 'ngx-cookie-service'
import { Router, ActivatedRoute } from '@angular/router'
import { SEOService } from 'src/app/services/seo.service'
import { SiteBlockService } from '../../../../services/siteBlock.service'

@Component({
  templateUrl: './blogList.component.html',
  styleUrls: ['./blogList.component.scss'],
})
export class BlogListComponent implements OnInit {
  public acceptCookie = null

  constructor(private _cookieService: CookieService, private _seoService: SEOService, private route: ActivatedRoute, private blockServise: SiteBlockService) {}
  public displayCookiePopup = true

  public list

  ngOnInit() {
    const { meta } = this.route.snapshot.data

    this.blockServise.getPages().subscribe(
      (data: any) => {
        if (data) {
          this.list = data
          this._seoService.updateTitle(meta['title'])
          this._seoService.updateDescription(meta['description'])
          this._seoService.createCanonicalLink(meta['canonical'])
        }
      },
      (err) => {}
    )

    this.acceptCookie = this._cookieService.get('acceptCookie')
    if (this.acceptCookie) {
      this.displayCookiePopup = false
    }
  }
}
