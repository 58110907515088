import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router'
import { Store } from '@ngrx/store'
import { map, take } from 'rxjs/operators'
import { selectCurrentUser } from '../../store/selectors/user.selectors'

@Injectable()
export class CheckRoleGuard implements CanActivate {
  constructor(private _router: Router, private _store: Store<any>) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this._store.select(selectCurrentUser).pipe(
      take(1),
      map((user) => {
        const isAuth = !!user
        if (isAuth) {
          const allowedRoles = route.data.allowedRoles
          if (allowedRoles.indexOf(user.userType) !== -1) {
            return true
          }
        }
        return this._router.createUrlTree(['/auth'])
      })
    )
  }
}
