import { Component, Inject, OnDestroy } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog'
import { Router } from '@angular/router'
import { Actions, ofType } from '@ngrx/effects'
import { select, Store } from '@ngrx/store'
import { TranslateService } from '@ngx-translate/core'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { ISubuser } from 'src/app/models/Subuser.model'
import { ICourse } from 'src/app/models/Course.model'
import { IReportPageBook } from 'src/app/models/Report.model'
import { NotificationService } from 'src/app/services/notification.service'
import { EReportsActions, ReportProblem, ReportProblemPage, ReportProblemSuccess } from 'src/app/store/actions/reports.actions'
import { selectCurrentSubuser } from 'src/app/store/selectors/subuser.selectors'
import { selectCurrentCourse } from 'src/app/store/selectors/courses.selectors'
import { selectCurrentUser } from 'src/app/store/selectors/user.selectors'
import { IAppState } from 'src/app/store/state/app.state'

@Component({
  templateUrl: './server-error.component.html',
  styleUrls: ['./server-error.component.scss'],
})
export class ServerErrorPopupComponent implements OnDestroy {
  public reportForm: UntypedFormGroup
  private destroyed$ = new Subject<boolean>()
  public userId: number
  public url: boolean
  public currentSubuser: ISubuser
  public currentCourse: ICourse
  private unsubscribe$ = new Subject()

  constructor(
    private updates$: Actions,
    public dialogRef: MatDialogRef<ServerErrorPopupComponent>,
    private _store: Store<IAppState>,
    public notifications: NotificationService,
    public translate: TranslateService,
    private _router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.updates$.pipe(ofType<ReportProblemSuccess>(EReportsActions.ReportProblemSuccess), takeUntil(this.destroyed$)).subscribe((res: any) => {
      this.notifications.showNotification(res.payload.text, 4)
      this.dialogRef.close()
    })

    this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentUser)).subscribe((res) => {
      this.userId = res.user.id
    })
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  ngOnInit() {
    this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentSubuser)).subscribe((subuser) => {
      this.currentSubuser = subuser
    })
    this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentCourse)).subscribe((course) => {
      this.currentCourse = course
    })
    this.reportForm = new UntypedFormGroup({
      userId: new UntypedFormControl(this.userId),
      subuserId: new UntypedFormControl(+this.currentSubuser.id),
      url: new UntypedFormControl(this._router.url),
      bookId: new UntypedFormControl(this.data.bookId),
      pageNumber: new UntypedFormControl(this.data.pageNumber),
      bookName: new UntypedFormControl(this.data.bookName),
      subuserCourse: new UntypedFormControl(this.currentCourse.name),
      reason: new UntypedFormControl('', [Validators.required]),
      description: new UntypedFormControl('', [Validators.required]),
    })
  }

  onNoClick(): void {
    this.dialogRef.close()
  }

  selectReason(reason: string) {
    this.reportForm.patchValue({ reason })
  }

  sendReport(form: UntypedFormGroup) {
    if (form.invalid) return

    const request: IReportPageBook = form.value

    this._store.dispatch(new ReportProblemPage(request))

    this.notifications.showNotification(`Report sent successfully!`, 5)

    this.dialogRef.close()
  }
}
