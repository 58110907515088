export const seometadata = {
  blog: {
    meta: {
      canonical: 'https://dinolingo.com/blog',
        title:
          'Blog - Dinolingo',
        reachName: '',
        description:
          "Blog - Dinolingo",
    }
  },
  login: {
    meta: {
      en: {
        canonical: 'https://dinolingo.com/auth/login',
        title:
          'Login - Dinolingo',
        reachName: '',
        description:
          "Login - Dinolingo",
      },
      ru: {
        canonical: 'https://dinolingo.com/ru/auth/login',
        title:
          'Войти - Dinolingo',
        reachName: '',
        description:
          "Войти - Dinolingo",
      },
	  ua: {
        canonical: 'https://dinolingo.com/ua/auth/login',
        title:
          'Увійти - Dinolingo',
        reachName: '',
        description:
          "Увійти - Dinolingo",
      },
	  de: {
        canonical: 'https://dinolingo.com/de/auth/login',
        title:
          'Login - Dinolingo',
        reachName: '',
        description:
          "Login - Dinolingo",
      },
	  fr: {
        canonical: 'https://dinolingo.com/fr/auth/login',
        title:
          'Connexion - Dinolingo',
        reachName: '',
        description:
          "Connexion - Dinolingo",
      },
	  ar: {
        canonical: 'https://dinolingo.com/ar/auth/login',
        title:
          'Lتسجيل الدخول - Dinolingo',
        reachName: '',
        description:
          "تسجيل الدخول - Dinolingo",
      },
	  pt: {
        canonical: 'https://dinolingo.com/pt/auth/login',
        title:
          'Entrar - Dinolingo',
        reachName: '',
        description:
          "Entrar - Dinolingo",
      },
	  hi: {
        canonical: 'https://dinolingo.com/hi/auth/login',
        title:
          'लॉग इन करें - Dinolingo',
        reachName: '',
        description:
          "लॉग इन करें - Dinolingo",
      },
	  pl: {
        canonical: 'https://dinolingo.com/pl/auth/login',
        title:
          'Logowanie - Dinolingo',
        reachName: '',
        description:
          "Logowanie - Dinolingo",
      },
	  es: {
        canonical: 'https://dinolingo.com/es/auth/login',
        title:
          'Iniciar Sesión - Dinolingo',
        reachName: '',
        description:
          "Iniciar Sesión - Dinolingo",
      },
	  tr: {
        canonical: 'https://dinolingo.com/tr/auth/login',
        title:
          'Giriş - Dinolingo',
        reachName: '',
        description:
          "Giriş - Dinolingo",
      },
	  zh: {
        canonical: 'https://dinolingo.com/zh/auth/login',
        title:
          '登录 - Dinolingo',
        reachName: '',
        description:
          "登录 - Dinolingo",
      },
	  it: {
        canonical: 'https://dinolingo.com/it/auth/login',
        title:
          'Accedi - Dinolingo',
        reachName: '',
        description:
          "Accedi - Dinolingo",
      },
	  ko: {
        canonical: 'https://dinolingo.com/ko/auth/login',
        title:
          '로그인 - Dinolingo',
        reachName: '',
        description:
          "로그인 - Dinolingo",
      },
	  jp: {
        canonical: 'https://dinolingo.com/jp/auth/login',
        title:
          'ログイン - Dinolingo',
        reachName: '',
        description:
          "ログイン - Dinolingo",
      },
    },
  },
  registration: {
    meta: {
      en: {
        canonical: 'https://dinolingo.com/auth/registration',
        title:
          'Signup - Subscribe - Dinolingo',
        reachName: '',
        description:
          "Signup - Subscribe - Dinolingo",
      },
      ru: {
        canonical: 'https://dinolingo.com/ru/auth/registration',
        title:
          'Подписаться - Dinolingo',
        reachName: '',
        description:
          "Подписаться - Dinolingo",
      },
	  ua: {
        canonical: 'https://dinolingo.com/ua/auth/registration',
        title:
          'Зареєструватися - Dinolingo',
        reachName: '',
        description:
          "Зареєструватися - Dinolingo",
      },
	  de: {
        canonical: 'https://dinolingo.com/de/auth/registration',
         title:
          'Registrieren - Dinolingo',
        reachName: '',
        description:
          "Registrieren - Dinolingo",
      },
	  fr: {
        canonical: 'https://dinolingo.com/fr/auth/registration',
         title:
          'Inscription - Dinolingo',
        reachName: '',
        description:
          "Inscription - Dinolingo",
      },
	  ar: {
        canonical: 'https://dinolingo.com/ar/auth/registration',
         title:
          'اشتراك - Dinolingo',
        reachName: '',
        description:
          "اشتراك - Dinolingo",
      },
	  pt: {
        canonical: 'https://dinolingo.com/pt/auth/registration',
         title:
          'Cadastre-se - Dinolingo',
        reachName: '',
        description:
          "Cadastre-se - Dinolingo",
      },
	  hi: {
        canonical: 'https://dinolingo.com/hi/auth/registration',
         title:
          'साइन अप करें - Dinolingo',
        reachName: '',
        description:
          "साइन अप करें - Dinolingo",
      },
	  pl: {
        canonical: 'https://dinolingo.com/pl/auth/registration',
         title:
          'Zarejestruj się - Dinolingo',
        reachName: '',
        description:
          "Zarejestruj się - Dinolingo",
      },
	  es: {
        canonical: 'https://dinolingo.com/es/auth/registration',
         title:
          'Registrarse - Dinolingo',
        reachName: '',
        description:
          "Registrarse - Dinolingo",
      },
	  tr: {
        canonical: 'https://dinolingo.com/tr/auth/registration',
         title:
          'Kaydol - Dinolingo',
        reachName: '',
        description:
          "Kaydol - Dinolingo",
      },
	  zh: {
        canonical: 'https://dinolingo.com/zh/auth/registration',
         title:
          '注册 - Dinolingo',
        reachName: '',
        description:
          "注册 - Dinolingo",
      },
	  it: {
        canonical: 'https://dinolingo.com/it/auth/registration',
         title:
          'Iscriviti - Dinolingo',
        reachName: '',
        description:
          "Iscriviti - Dinolingo",
      },
	  ko: {
        canonical: 'https://dinolingo.com/ko/auth/registration',
         title:
          '가입하기 - Dinolingo',
        reachName: '',
        description:
          "가입하기 - Dinolingo",
      },
	  jp: {
        canonical: 'https://dinolingo.com/jp/auth/registration',
         title:
          '登録 - Dinolingo',
        reachName: '',
        description:
          "登録 - Dinolingo",
      },
    },
  },
  restore_password: {
    meta: {
      en: {
        canonical: 'https://dinolingo.com/auth/restore-password',
        title:
          'Restore password - Dinolingo',
        reachName: '',
        description:
          "Restore password - Dinolingo",
      },
      ru: {
        canonical: 'https://dinolingo.com/ru/auth/restore-password',
        title:
          'Восстановить пароль - Dinolingo',
        reachName: '',
        description:
          "Восстановить пароль - Dinolingo",
      },
	  ua: {
        canonical: 'https://dinolingo.com/ua/auth/restore-password',
         title:
          'Відновити пароль - Dinolingo',
        reachName: '',
        description:
          "Відновити пароль - Dinolingo",
      },
	  de: {
        canonical: 'https://dinolingo.com/de/auth/restore-password',
         title:
          'Passwort wiederherstellen - Dinolingo',
        reachName: '',
        description:
          "Passwort wiederherstellen - Dinolingo",
      },
	  fr: {
        canonical: 'https://dinolingo.com/fr/auth/restore-password',
         title:
          'Restaurer le mot de passe - Dinolingo',
        reachName: '',
        description:
          "Restaurer le mot de passe - Dinolingo",
      },
	  ar: {
        canonical: 'https://dinolingo.com/ar/auth/restore-password',
         title:
          'استعادة كلمة المرور - Dinolingo',
        reachName: '',
        description:
          "استعادة كلمة المرور - Dinolingo",
      },
	  pt: {
        canonical: 'https://dinolingo.com/pt/auth/restore-password',
         title:
          'Redefinir Senha - Dinolingo',
        reachName: '',
        description:
          "Redefinir Senha - Dinolingo",
      },
	  hi: {
        canonical: 'https://dinolingo.com/hi/auth/restore-password',
         title:
          'पासवर्ड पुनर्स्थापित करें - Dinolingo',
        reachName: '',
        description:
          "पासवर्ड पुनर्स्थापित करें - Dinolingo",
      },
	  pl: {
        canonical: 'https://dinolingo.com/pl/auth/restore-password',
         title:
          'Przywróć hasło - Dinolingo',
        reachName: '',
        description:
          "Przywróć hasło - Dinolingo",
      },
	  es: {
        canonical: 'https://dinolingo.com/es/auth/restore-password',
         title:
          'Restaurar la Contraseña - Dinolingo',
        reachName: '',
        description:
          "Restaurar la Contraseña - Dinolingo",
      },
	  tr: {
        canonical: 'https://dinolingo.com/tr/auth/restore-password',
         title:
          'Şifre Değiştirme - Dinolingo',
        reachName: '',
        description:
          "Şifre Değiştirme - Dinolingo",
      },
	  zh: {
        canonical: 'https://dinolingo.com/zh/auth/restore-password',
         title:
          '恢复密码 - Dinolingo',
        reachName: '',
        description:
          "恢复密码 - Dinolingo",
      },
	  it: {
        canonical: 'https://dinolingo.com/it/auth/restore-password',
         title:
          'Ripristina password - Dinolingo',
        reachName: '',
        description:
          "Ripristina password - Dinolingo",
      },
	  ko: {
        canonical: 'https://dinolingo.com/ko/auth/restore-password',
         title:
          '비밀번호 복원 - Dinolingo',
        reachName: '',
        description:
          "비밀번호 복원 - Dinolingo",
      },
	  jp: {
        canonical: 'https://dinolingo.com/jp/auth/restore-password',
         title:
          'パスワードの復元 - Dinolingo',
        reachName: '',
        description:
          "パスワードの復元 - Dinolingo",
      },
    },
  },
  homepage: {
    meta: {
      en: {
        canonical: 'https://dinolingo.com',
        title:
          'The best online language learning program for kids - Start today - Dinolingo',
        reachName: 'DinoLingo: Language Learning for Kids',
        description:
          "World's largest language learning program for children. Learn 50 languages online with fun lessons, games, videos, books and worksheets for kids.",
      },
      ru: {
        canonical: 'https://dinolingo.com/ru',
        title:
          'Лучшая онлайн-программа по изучению иностранных языков для детей - Начните сегодня - Dinolingo',
        reachName: 'DinoLingo: Изучение иностранных языков для детей',
        description:
          "Крупнейшая в мире программа изучения иностранных языков для детей. Изучайте 50 языков онлайн с помощью забавных уроков, игр, видео, книг и рабочих тетрадей для детей.",
      },
      ua : {
        canonical: 'https://dinolingo.com/ua',
        title:
          'Найкраща онлайн-програма з вивчення іноземних мов для дітей - Почніть сьогодні - Dinolingo',
        reachName: 'DinoLingo: Вивчення іноземних мов для дітей',
        description:
          "Найбільша в світі програма вивчення мови для дітей. Вивчайте 50 мов онлайн за допомогою веселих уроків, ігор, відео, книг та робочих листів для дітей.",
      },
      de : {
        canonical: 'https://dinolingo.com/de',
        title:
          'Das beste Online-Sprachlernprogramm für Kinder - Starten Sie noch heute - Dinolingo',
        reachName: 'Dinolingo: Sprachen lernen für Kinder',
        description:
          "Das weltweit umfangreichste Sprachlernprogramm für Kinder. In mehr als 50 Sprachen verfügbar. Die Kinder lernen online spielerisch mit unterhaltsamen Lektionen, Spielen, Videos, Büchern und Arbeitsblättern.",
      },
      fr : {
        canonical: 'https://dinolingo.com/fr',
        title: 'Le meilleur programme d\'apprentissage des langues en ligne pour les enfants - Commencez dès aujourd\'hui - Dinolingo',
        reachName: 'DinoLingo: Apprentissage des langues pour les enfants',
        description:
          "Le plus grand programme d'apprentissage des langues pour enfants au monde. Apprenez 50 langues en ligne avec des leçons ludiques, des jeux, des vidéos, des livres et des fiches de travail pour les enfants..",
      },
      ar: {
        canonical: 'https://dinolingo.com/ar',
        title:
          'البرنامج الأفضل لتعلم اللغات عبر الإنترنت للأطفال - ابدأ اليوم - Dinolingo',
        reachName: 'DinoLingo: تعلم اللغات للأطفال',
        description:
          "برنامج تعلم اللغات للأطفال الأكبر في العالم. تعلم 50 لغة مختلفة عبر الإنترنت مع دروس وألعاب وفيديوهات وكتب وأوراق عمل مسلية وشيقة للأطفال.",
      },
      pt: {
        canonical: 'https://dinolingo.com/pt',
        title:
          'O melhor programa de aprendizado de idiomas online para crianças  - Comece hoje - Dinolingo',
        reachName: 'DinoLingo: Aprendizado de Idiomas para Crianças',
        description:
          "O maior programa de aprendizado de idiomas para crianças do mundo. Aprenda 50 idiomas online com aulas, jogos, vídeos, livros e exercícios divertidos para crianças.",
      },
      hi: {
        canonical: 'https://dinolingo.com/hi',
        title:
          'बच्चों के लिए सर्वश्रेष्ठ ऑनलाइन भाषा सीखने का कार्यक्रम - आज ही शुरू करें – Dinolingo (डिनोलिंगो)',
        reachName: 'Dinolingo (डिनोलिंगो): बच्चों के लिए भाषा सीखना',
        description:
          "बच्चों के लिए दुनिया का सबसे बड़ा भाषा सीखने का कार्यक्रम। बच्चों के लिए मजेदार टेक्स्ट, गेम, वीडियो, किताबें और वर्कशीट के साथ 50 भाषाएं ऑनलाइन सीखें.",
      },
      pl : {
        canonical: 'https://dinolingo.com/pl',
        title:
          'Najlepszy program do nauki języków online dla dzieci - Zacznij już dziś - Dinolingo',
        reachName: 'DinoLingo: Nauka języków dla dzieci',
        description:
          "Największy na świecie program do nauki języków dla dzieci. Naucz się 50 języków z ciekawymi lekcjami, grami, filmami, książkami i arkuszami dla dzieci.",
      },
      es : {
        canonical: 'https://dinolingo.com/es',
        title:
          'El mejor programa de aprendizaje de idiomas en línea para niños - Comience hoy - Dinolingo',
        reachName: 'DinoLingo: Aprendizaje de Idiomas para Niños',
        description:
          "El programa de aprendizaje de idiomas más grande del mundo. Aprenda 50 idiomas en línea con lecciones divertidas, juegos, videos, libros y hojas de ejercicios para niños.",
      },
      tr : {
        canonical: 'https://dinolingo.com/tr',
        title:
          'Çocuklar için en iyi online yabancı dil öğrenme programı - Bugün başla - Dinolingo',
        reachName: 'DinoLingo: Çocuklar için Dil Öğrenimi',
        description:
          "Çocuklar için dünyanın en büyük dil öğrenme programı. Çocuklar için eğlenceli dersler, oyunlar, videolar, kitaplar ve alıştırmalar ile internette 50 dil öğrenin.",
      },
      zh: {
        canonical: 'https://dinolingo.com/zh',
        title:
        '为儿童而设的最佳在线语言学习课程',
        reachName: 'DinoLingo: 为儿童而设的语言学习课程',
        description:
        "全球最大的儿童语言学习课程。在线学习 50 种语言，为儿童提供有趣的课程、游戏、视频、书籍与工作表。",
      },
      it : {
        canonical: 'https://dinolingo.com/it',
        title:
          'Il miglior programma di apprendimento delle lingue online per bambini – Inizia oggi – Dinolingo',
        reachName: 'DinoLingo: apprendimento delle lingue per bambini',
        description:
          "Il più grande programma di apprendimento delle lingue per bambini al mondo. Impara 50 lingue online con lezioni complete, giochi, video, libri ed esercizi per bambini.",
      },
      ko: {
        canonical: 'https://dinolingo.com/ko',
        title:
          '어린이를 위한 최고의 온라인 언어 학습 프로그램 - 오늘부터 시작하세요 - Dinolingo ',
        reachName: 'DinoLingo: 어린이를 위한 언어 학습 ',
        description:
          '세계 최대의 어린이 언어 학습 프로그램. 아이를 위한 재미있는 수업, 게임, 비디오, 책 및 워크시트를 통해 온라인에서 50개 언어를 배우세요.',
      },
      jp : {
        canonical: 'https://dinolingo.com/jp',
        title:
          '子どものための最適なオンライン言語学習 - 今すぐ始めよう - Dinolingo',
        reachName: 'DinoLingo: 子どものための言語学習',
        description:
          "世界最大規模の子ども向け言語オンライン学習プログラムです。子どもにぴったりな楽しいレッスン、ゲーム、ビデオ、絵本、ワークシートで50ヶ国語から言語を選んで学びましょう。",
      }
    },
  },
  learn: {
    albanian: {
      meta: {
        en : {
          canonical: 'https://dinolingo.com/learn-albanian-for-kids',
          title: 'Learn Albanian for Kids - Teach kids Albanian - Online Albanian for kids',
          reachName: 'Learn Albanian for Kids',
          description:
            'Online Albanian lessons for kids. Kids Albanian videos, games, lessons, songs and books.',
        }
      }
    },
    arabic: {
      meta: {
        en : {
          canonical: 'https://dinolingo.com/learn-arabic-for-kids',
          title: 'Learn Arabic for Kids - Teach kids Arabic - Online Arabic for kids',
          reachName: 'Learn Arabic for Kids',
          description:
            'Online Arabic lessons for kids. Kids Arabic videos, games, lessons, songs and books.',
        }
      }
    },
    armenian: {
      meta: {
        en : {
          canonical: 'https://dinolingo.com/learn-armenian-for-kids',
          title: 'Learn Armenian for Kids - Teach kids Armenian - Online Armenian for kids',
          reachName: 'Learn Armenian for Kids',
          description:
            'Online Armenian lessons for kids. Kids Armenian videos, games, lessons, songs and books.',
        }
      }
    },
    bulgarian: {
      meta: {
        en : {
          canonical: 'https://dinolingo.com/learn-bulgarian-for-kids',
          title: 'Learn Bulgarian for Kids - Teach kids Bulgarian - Online Bulgarian for kids',
          reachName: 'Learn Bulgarian for Kids',
          description:
            'Online Bulgarian lessons for kids. Kids Bulgarian videos, games, lessons, songs and books.',
        }
      }
    },
    cantonese: {
      meta: {
        en : {
          canonical: 'https://dinolingo.com/learn-cantonese-for-kids',
          title: 'Learn Cantonese for Kids - Teach kids Cantonese - Online Cantonese for kids',
          reachName: 'Learn Cantonese for Kids',
          description:
            'Online Cantonese lessons for kids. Kids Cantonese videos, games, lessons, songs and books.',
        }
      }
    },
    chinese: {
      meta: {
        en : {
          canonical: 'https://dinolingo.com/learn-chinese-for-kids',
          title: 'Learn Chinese for Kids - Teach kids Chinese - Online Chinese for kids',
          reachName: 'Learn Chinese for Kids',
          description:
            'Online Chinese lessons for kids. Kids Chinese videos, games, lessons, songs and books.',
        }
      }
    },
    creole: {
      meta: {
        en : {
          canonical: 'https://dinolingo.com/learn-creole-for-kids',
          title: 'Learn Creole for Kids - Teach kids Creole - Online Creole for kids',
          reachName: 'Learn Creole for Kids',
          description:
            'Online Creole lessons for kids. Kids Creole videos, games, lessons, songs and books.',
        }
      }
    },
    croatian: {
      meta: {
        en : {
          canonical: 'https://dinolingo.com/learn-croatian-for-kids',
          title: 'Learn Croatian for Kids - Teach kids Croatian - Online Croatian for kids',
          reachName: 'Learn Croatian for Kids',
          description:
            'Online Croatian lessons for kids. Kids Croatian videos, games, lessons, songs and books.',
        }
      }
    },
    czech: {
      meta: {
        en : {
          canonical: 'https://dinolingo.com/learn-czech-for-kids',
          title: 'Learn Czech for Kids - Teach kids Czech - Online Czech for kids',
          reachName: 'Learn Czech for Kids',
          description:
            'Online Czech lessons for kids. Kids Czech videos, games, lessons, songs and books.',
        }
      }
    },
    danish: {
      meta: {
        en : {
          canonical: 'https://dinolingo.com/learn-danish-for-kids',
          title: 'Learn Danish for Kids - Teach kids Danish - Online Danish for kids',
          reachName: 'Learn Danish for Kids',
          description:
            'Online Danish lessons for kids. Kids Danish videos, games, lessons, songs and books.',
        }
      }
    },
    dutch: {
      meta: {
        en : {
          canonical: 'https://dinolingo.com/learn-dutch-for-kids',
          title: 'Learn Dutch for Kids - Teach kids Dutch - Online Dutch for kids',
          reachName: 'Learn Dutch for Kids',
          description:
            'Online Dutch lessons for kids. Kids Dutch videos, games, lessons, songs and books.',
        }
      }
    },
    english: {
      meta: {
        en : {
          canonical: 'https://dinolingo.com/learn-english-for-kids',
          title: 'Learn English for Kids - Teach kids English - Online English for kids',
          reachName: 'Learn English for Kids',
          description:
            'Online English lessons for kids. Kids English videos, games, lessons, songs and books.',
        }
      }
    },
    euportuguese: {
      meta: {
        en : {
          canonical: 'https://dinolingo.com/learn-euportuguese-for-kids',
          title: 'Learn Europian Portuguese for Kids - Teach kids Europian Portuguese - Online Europian Portuguese for kids',
          reachName: 'Learn Europian Portuguese for Kids',
          description:
            'Online Europian Portuguese lessons for kids. Kids Europian Portuguese videos, games, lessons, songs and books.',
        }
      }
    },
    finnish: {
      meta: {
        en : {
          canonical: 'https://dinolingo.com/learn-finnish-for-kids',
          title: 'Learn Finnish for Kids - Teach kids Finnish - Online Finnish for kids',
          reachName: 'Learn Finnish for Kids',
          description:
            'Online Finnish lessons for kids. Kids Finnish videos, games, lessons, songs and books.',
        }
      }
    },
    french: {
      meta: {
        en : {
          canonical: 'https://dinolingo.com/learn-french-for-kids',
          title: 'Learn French for Kids - Teach kids French - Online French for kids',
          reachName: 'Learn French for Kids',
          description:
            'Online French lessons for kids. Kids French videos, games, lessons, songs and books.',
        }
      }
    },
    german: {
      meta: {
        en : {
          canonical: 'https://dinolingo.com/learn-german-for-kids',
          title: 'Learn German for Kids - Teach kids German - Online German for kids',
          reachName: 'Learn German for Kids',
          description:
            'Online German lessons for kids. Kids German videos, games, lessons, songs and books.',
        }
      }
    },
    greek: {
      meta: {
        en : {
          canonical: 'https://dinolingo.com/learn-greek-for-kids',
          title: 'Learn Greek for Kids - Teach kids Greek - Online Greek for kids',
          reachName: 'Learn Greek for Kids',
          description:
            'Online Greek lessons for kids. Kids Greek videos, games, lessons, songs and books.',
        }
      }
    },
    gujarati: {
      meta: {
        en : {
          canonical: 'https://dinolingo.com/learn-gujarati-for-kids',
          title: 'Learn Gujarati for Kids - Teach kids Gujarati - Online Gujarati for kids',
          reachName: 'Learn Gujarati for Kids',
          description:
            'Online Gujarati lessons for kids. Kids Gujarati videos, games, lessons, songs and books.',
        }
      }
    },
    hawaiian: {
      meta: {
        en : {
          canonical: 'https://dinolingo.com/learn-hawaiian-for-kids',
          title: 'Learn Hawaiian for Kids - Teach kids Hawaiian - Online Hawaiian for kids',
          reachName: 'Learn Hawaiian for Kids',
          description:
            'Online Hawaiian lessons for kids. Kids Hawaiian videos, games, lessons, songs and books.',
        }
      }
    },
    hebrew: {
      meta: {
        en : {
          canonical: 'https://dinolingo.com/learn-hebrew-for-kids',
          title: 'Learn Hebrew for Kids - Teach kids Hebrew - Online Hebrew for kids',
          reachName: 'Learn Hebrew for Kids',
          description:
            'Online Hebrew lessons for kids. Kids Hebrew videos, games, lessons, songs and books.',
        }
      }
    },
    hindi: {
      meta: {
        en : {
          canonical: 'https://dinolingo.com/learn-hindi-for-kids',
          title: 'Learn Hindi for Kids - Teach kids Hindi - Online Hindi for kids',
          reachName: 'Learn Hindi for Kids',
          description:
            'Online Hindi lessons for kids. Kids Hindi videos, games, lessons, songs and books.',
        }
      }
    },
    hungarian: {
      meta: {
        en : {
          canonical: 'https://dinolingo.com/learn-hungarian-for-kids',
          title: 'Learn Hungarian for Kids - Teach kids Hungarian - Online Hungarian for kids',
          reachName: 'Learn Hungarian for Kids',
          description:
            'Online Hungarian lessons for kids. Kids Hungarian videos, games, lessons, songs and books.',
        }
      }
    },
    indonesian: {
      meta: {
        en : {
          canonical: 'https://dinolingo.com/learn-indonesian-for-kids',
          title: 'Learn Indonesian for Kids - Teach kids Indonesian - Online Indonesian for kids',
          reachName: 'Learn Indonesian for Kids',
          description:
            'Online Indonesian lessons for kids. Kids Indonesian videos, games, lessons, songs and books.',
        }
      }
    },
    irish: {
      meta: {
        en : {
          canonical: 'https://dinolingo.com/learn-irish-for-kids',
          title: 'Learn Irish for Kids - Teach kids Irish - Online Irish for kids',
          reachName: 'Learn Irish for Kids',
          description:
            'Online Irish lessons for kids. Kids Irish videos, games, lessons, songs and books.',
        }
      }
    },
    italian: {
      meta: {
        en : {
          canonical: 'https://dinolingo.com/learn-italian-for-kids',
          title: 'Learn Italian for Kids - Teach kids Italian - Online Italian for kids',
          reachName: 'Learn Italian for Kids',
          description:
            'Online Italian lessons for kids. Kids Italian videos, games, lessons, songs and books.',
        }
      }
    },
    japanese: {
      meta: {
        en : {
          canonical: 'https://dinolingo.com/learn-japanese-for-kids',
          title: 'Learn Japanese for Kids - Teach kids Japanese - Online Japanese for kids',
          reachName: 'Learn Japanese for Kids',
          description:
            'Online Japanese lessons for kids. Kids Japanese videos, games, lessons, songs and books.',
        }
      }
    },
    kazakh: {
      meta: {
        en : {
          canonical: 'https://dinolingo.com/learn-kazakh-for-kids',
          title: 'Learn Kazakh for Kids - Teach kids Kazakh - Online Kazakh for kids',
          reachName: 'Learn Kazakh for Kids',
          description:
            'Online Kazakh lessons for kids. Kids Kazakh videos, games, lessons, songs and books.',
        }
      }
    },
    korean: {
      meta: {
        en : {
          canonical: 'https://dinolingo.com/learn-korean-for-kids',
          title: 'Learn Korean for Kids - Teach kids Korean - Online Korean for kids',
          reachName: 'Learn Korean for Kids',
          description:
            'Online Korean lessons for kids. Kids Korean videos, games, lessons, songs and books.',
        }
      }
    },
    latin: {
      meta: {
        en : {
          canonical: 'https://dinolingo.com/learn-latin-for-kids',
          title: 'Learn Latin for Kids - Teach kids Latin - Online Latin for kids',
          reachName: 'Learn Latin for Kids',
          description:
            'Online Latin lessons for kids. Kids Latin videos, games, lessons, songs and books.',
        }
      }
    },
    malay: {
      meta: {
        en : {
          canonical: 'https://dinolingo.com/learn-malay-for-kids',
          title: 'Learn Malay for Kids - Teach kids Malay - Online Malay for kids',
          reachName: 'Learn Malay for Kids',
          description:
            'Online Malay lessons for kids. Kids Malay videos, games, lessons, songs and books.',
        }
      }
    },
    norwegian: {
      meta: {
        en : {
          canonical: 'https://dinolingo.com/learn-norwegian-for-kids',
          title: 'Learn Norwegian for Kids - Teach kids Norwegian - Online Norwegian for kids',
          reachName: 'Learn Norwegian for Kids',
          description:
            'Online Norwegian lessons for kids. Kids Norwegian videos, games, lessons, songs and books.',
        }
      }
    },
    persian: {
      meta: {
        en : {
          canonical: 'https://dinolingo.com/learn-persian-for-kids',
          title: 'Learn Farsi - Persian for Kids - Teach kids Farsi - Persian - Online Farsi - Persian for kids',
          reachName: 'Learn Farsi - Persian for Kids',
          description:
            'Online Farsi - Persian lessons for kids. Kids Farsi - Persian videos, games, lessons, songs and books.',
        }
      }
    },
    polish: {
      meta: {
        en : {
          canonical: 'https://dinolingo.com/learn-polish-for-kids',
          title: 'Learn Polish for Kids - Teach kids Polish - Online Polish for kids',
          reachName: 'Learn Polish for Kids',
          description:
            'Online Polish lessons for kids. Kids Polish videos, games, lessons, songs and books.',
        }
      }
    },
    portuguese: {
      meta: {
        en : {
          canonical: 'https://dinolingo.com/learn-portuguese-for-kids',
          title: 'Learn Portuguese for Kids - Teach kids Portuguese - Online Portuguese for kids',
          reachName: 'Learn Portuguese for Kids',
          description:
            'Online Portuguese lessons for kids. Kids Portuguese videos, games, lessons, songs and books.',
        }
      }
    },
    punjabi: {
      meta: {
        en : {
          canonical: 'https://dinolingo.com/learn-punjabi-for-kids',
          title: 'Learn Punjabi for Kids - Teach kids Punjabi - Online Punjabi for kids',
          reachName: 'Learn Punjabi for Kids',
          description:
            'Online Punjabi lessons for kids. Kids Punjabi videos, games, lessons, songs and books.',
        }
      }
    },
    romanian: {
      meta: {
        en : {
          canonical: 'https://dinolingo.com/learn-romanian-for-kids',
          title: 'Learn Romanian for Kids - Teach kids Romanian - Online Romanian for kids',
          reachName: 'Learn Romanian for Kids',
          description:
            'Online Romanian lessons for kids. Kids Romanian videos, games, lessons, songs and books.',
        }
      }
    },
    russian: {
      meta: {
        en : {
          canonical: 'https://dinolingo.com/learn-russian-for-kids',
          title: 'Learn Russian for Kids - Teach kids Russian - Online Russian for kids',
          reachName: 'Learn Russian for Kids',
          description:
            'Online Russian lessons for kids. Kids Russian videos, games, lessons, songs and books.',
        }
      }
    },
    serbian: {
      meta: {
        en : {
          canonical: 'https://dinolingo.com/learn-serbian-for-kids',
          title: 'Learn Serbian for Kids - Teach kids Serbian - Online Serbian for kids',
          reachName: 'Learn Serbian for Kids',
          description:
            'Online Serbian lessons for kids. Kids Serbian videos, games, lessons, songs and books.',
        }
      }
    },
    slovak: {
      meta: {
        en : {
          canonical: 'https://dinolingo.com/learn-slovak-for-kids',
          title: 'Learn Slovak for Kids - Teach kids Slovak - Online Slovak for kids',
          reachName: 'Learn Slovak for Kids',
          description:
            'Online Slovak lessons for kids. Kids Slovak videos, games, lessons, songs and books.',
        }
      }
    },
    slovenian: {
      meta: {
        en : {
          canonical: 'https://dinolingo.com/learn-slovenian-for-kids',
          title: 'Learn Slovenian for Kids - Teach kids Slovenian - Online Slovenian for kids',
          reachName: 'Learn Slovenian for Kids',
          description:
            'Online Slovenian lessons for kids. Kids Slovenian videos, games, lessons, songs and books.',
        }
      }
    },
    spanish: {
      meta: {
        en : {
          canonical: 'https://dinolingo.com/learn-spanish-for-kids',
          title: 'Learn Spanish for Kids - Teach kids Spanish - Online Spanish for kids',
          reachName: 'Learn Spanish for Kids',
          description:
            'Online Spanish lessons for kids. Kids Spanish videos, games, lessons, songs and books.',
        }
      }
    },
    swahili: {
      meta: {
        en : {
          canonical: 'https://dinolingo.com/learn-swahili-for-kids',
          title: 'Learn Swahili for Kids - Teach kids Swahili - Online Swahili for kids',
          reachName: 'Learn Swahili for Kids',
          description:
            'Online Swahili lessons for kids. Kids Swahili videos, games, lessons, songs and books.',
        }
      }
    },
    swedish: {
      meta: {
        en : {
          canonical: 'https://dinolingo.com/learn-swedish-for-kids',
          title: 'Learn Swedish for Kids - Teach kids Swedish - Online Swedish for kids',
          reachName: 'Learn Swedish for Kids',
          description:
            'Online Swedish lessons for kids. Kids Swedish videos, games, lessons, songs and books.',
        }
      }
    },
    tagalog: {
      meta: {
        en : {
          canonical: 'https://dinolingo.com/learn-tagalog-for-kids',
          title: 'Learn Tagalog for Kids - Teach kids Tagalog - Online Tagalog for kids',
          reachName: 'Learn Tagalog for Kids',
          description:
            'Online Tagalog lessons for kids. Kids Tagalog videos, games, lessons, songs and books.',
        }
      }
    },
    thai: {
      meta: {
        en : {
          canonical: 'https://dinolingo.com/learn-thai-for-kids',
          title: 'Learn Thai for Kids - Teach kids Thai - Online Thai for kids',
          reachName: 'Learn Thai for Kids',
          description:
            'Online Thai lessons for kids. Kids Thai videos, games, lessons, songs and books.',
        }
      }
    },
    turkish: {
      meta: {
        en : {
          canonical: 'https://dinolingo.com/learn-turkish-for-kids',
          title: 'Learn Turkish for Kids - Teach kids Turkish - Online Turkish for kids',
          reachName: 'Learn Turkish for Kids',
          description:
            'Online Turkish lessons for kids. Kids Turkish videos, games, lessons, songs and books.',
        }
      }
    },
    ukrainian: {
      meta: {
        en : {
          canonical: 'https://dinolingo.com/learn-ukrainian-for-kids',
          title: 'Learn Ukrainian for Kids - Teach kids Ukrainian - Online Ukrainian for kids',
          reachName: 'Learn Ukrainian for Kids',
          description:
            'Online Ukrainian lessons for kids. Kids Ukrainian videos, games, lessons, songs and books.',
        }
      }
    },
    urdu: {
      meta: {
        en : {
          canonical: 'https://dinolingo.com/learn-urdu-for-kids',
          title: 'Learn Urdu for Kids - Teach kids Urdu - Online Urdu for kids',
          reachName: 'Learn Urdu for Kids',
          description:
            'Online Urdu lessons for kids. Kids Urdu videos, games, lessons, songs and books.',
        }
      }
    },
    vietnamese: {
      meta: {
        en : {
          canonical: 'https://dinolingo.com/learn-vietnamese-for-kids',
          title: 'Learn Vietnamese for Kids - Teach kids Vietnamese - Online Vietnamese for kids',
          reachName: 'Learn Vietnamese for Kids',
          description:
            'Online Vietnamese lessons for kids. Kids Vietnamese videos, games, lessons, songs and books.',
        }
      }
    },
    welsh: {
      meta: {
        en : {
          canonical: 'https://dinolingo.com/learn-welsh-for-kids',
          title: 'Learn Welsh for Kids - Teach kids Welsh - Online Welsh for kids',
          reachName: 'Learn Welsh for Kids',
          description:
            'Online Welsh lessons for kids. Kids Welsh videos, games, lessons, songs and books.',
        }
      }
    },
    
    
    meta: {
      en : {
        canonical: 'https://dinolingo.com%s',
        title: 'Learn %s for Kids - Teach kids %s - Online %s for kids',
        reachName: 'Learn %s for Kids',
        description:
          'Online %s lessons for kids. Kids %s videos, games, lessons, songs and books.',
      },
      ru: {
        canonical: 'https://dinolingo.com%s',
        title: 'Изучение %f2 для Детей - Обучайте детей %f3 - Онлайн %f1 для детей',
        reachName: 'Обучение %f3 для детей',
        description:
          'Онлайн уроки %f2 для детей. Детские видео, игры, уроки, песни и книжки на %f4.',
      },
      ua : {
        canonical: 'https://dinolingo.com%s',
        title: 'Вивчення %s для дітей – Навчайте дітей %s – Онлайн %s для дітей',
        reachName: 'Навчання %s для дітей',
        description: 'Онлайн уроки %s для дітей. Дитячі відео, ігри, уроки, пісні та книги %s.'
      },
      de : {
        canonical: 'https://dinolingo.com%s',
        title: '%s lernen für Kinder - Kindern %s beibringen - Online %s für Kinder',
        reachName: '%s lernen für Kinder',
        description:
          'Online %s-Unterricht für Kinder. Mit Videos, Spielen, Lektionen, Liedern und Büchern auf %s.',
      },
      fr : {
        canonical: 'https://dinolingo.com%s',
        title: ' Apprendre %s pour les enfants - Enseigner l\'%s aux enfants - %s en ligne pour les enfants',
        reachName: 'Apprendre l\'%s pour les enfants',
        description:
          'Cours d\'%s en ligne pour les enfants. Kids %s videos, games, lessons, songs and books.',
      },
      ar : {
        canonical: 'https://dinolingo.com%s',
        title: 'تعلم %s للأطفال - تعليم الأطفال %s - %s عبر الإنترنت للأطفال',
        reachName: 'تعلم %s للأطفال',
        description:
          'دروس %s عبر الإنترنت للأطفال. مقاطع فيديو وألعاب ودروس وأغاني وكتب %s للأطفال.',
      },
      pt: {
        canonical: 'https://dinolingo.com%s',
        title: 'Aprendizado de %s para Crianças - Curso de %s para crianças - %s online para crianças',
        reachName: 'Curso de %s para Crianças',
        description:
        'Aulas online de %s para crianças. Vídeos, jogos, aulas, músicas e livros em %s para crianças.',
      },
      hi: {
        canonical: 'https://dinolingo.com%s',
        title: 'बच्चों के लिए %s सीखें - बच्चों को %s पढ़ाएं - बच्चों के लिए ऑनलाइन %s',
        reachName: 'बच्चों के लिए %s सीखें',
        description:
          'बच्चों के लिए ऑनलाइन %s टेक्स्ट। बच्चे %s वीडियो, गेम, टेक्स्ट, गीत, और पुस्तकें.',
      },
      pl : {
        canonical: 'https://dinolingo.com%s',
        title: 'Nauka języka %s dla dzieci - Naucz dzieci języka  %s - język %s online dla dzieci',
        reachName: 'Nauka języka %s dla dzieci',
        description:
          'Lekcje online języka  %s dla dzieci. Filmy, gry, lekcje, piosenki i książki dla dzieci w języku %s.',
      },
      es : {
        canonical: 'https://dinolingo.com%s',
        title: 'Aprenda %s para Niños - Enseña %s a los niños - %s En línea para niños',
        reachName: 'Aprenda %s para Niños',
        description:
          'Lecciones de %s en línea para niños. Videos, juegos, lecciones, canciones y libros en %s para niños.',
      },
      tr : {
        canonical: 'https://dinolingo.com%s',
        title: 'Çocuklar için %s Öğrenme - Çocuklara %s öğret - Çocuklar için online %s',
        reachName: 'Çocuklar için %s Öğrenimi',
        description:
          'Çocuklar için online %s dersleri. Çocuklar için %s videolar, oyunlar, dersler, şarkılar ve kitaplar.',
      },
      zh: {
        canonical: 'https://dinolingo.com%s',
        title: '给儿童学习 %s - 教儿童 %s - 为儿童提供在线 %s',
        reachName: '给儿童学习 %s',
        description:
        '为儿童而设的在线 %s 课程。儿童 %s 视频、游戏、课程、歌曲与书籍。'
      },
      it : {
        canonical: 'https://dinolingo.com%s',
        title: 'Impara l\'%s per bambini – Insegna l\'%s ai bambini – %s online per bambini',
        reachName: 'Impara %s per bambini',
        description:
          'Lezioni di %s online per bambini. Video, giochi, lezioni, canzoni e libri di %s per bambini.',
      },
      ko: {
        canonical: 'https://dinolingo.com%s',
        title: '어린이를 위한 %s 배우기 - 아이들에게 %s 가르치기  - 아이들을 위한 %s 온라인 ',
        reachName: '어린이를 위한 %s 배우기',
        description:
          '어린이를 위한 온라인 %s 수업. 어린이용 %s 동영상, 게임, 수업, 노래, 책. ',
      },
      jp : {
        canonical: 'https://dinolingo.com%s',
        title: '子ども向けの %s を学びましょう - 子どもに %s を学ばせましょう- 子ども向けオンライン %s 学習',
        reachName: '子ども向け %s を学びましょう',
        description:
          '子ども向けのオンライン%s学習。子ども向け%sの動画、ゲーム、レッスン、歌や絵本で楽しく学べます。',
      }
    },
  },
  curriculum: {
    meta: {
      en : {
        canonical: 'https://dinolingo.com/curriculum',
        title: 'Dinolingo Curriculum - Lesson Plan',
        reachName: 'Dinolingo Curriculum',
        description:
          "Dinolingo's extensive curriculum includes thousands of language learning activities for kids",
      },
      ru: {
        canonical: 'https://dinolingo.com/ru/curriculum',
        title: 'Учебная программа Dinolingo - План урока',
        reachName: 'Учебная программа Dinolingo',
        description:
          "Обширная учебная программа Dinolingo включает в себя тысячи уроков по изучению иностранных языков для детей.",
      },
      ua : {
        canonical: 'https://dinolingo.com/ua/curriculum',
        title: 'Навчальна програма Dinolingo – План уроку',
        reachName: 'Навчальна програма Dinolingo ',
        description: "Розширена навчальна програма Dinolingo включає тисячі вправ з вивчення мови для дітей",
      },
      de : {
        canonical: 'https://dinolingo.com/de/curriculum',
        title: 'Dinolingo Curriculum - Lehrplan',
        reachName: 'Dinolingo Curriculum',
        description:
          "Der umfangreiche Lehrplan von Dinolingo umfasst mehrere tausend Übungen zum Sprachenlernen für Kinder",
      },
      fr : {
        canonical: 'https://dinolingo.com/fr/curriculum',
        title: 'Curriculum Dinolingo - Plan des cours',
        reachName: 'Curriculum Dinolinguo',
        description:
          "Grâce à son programme complet, Dinolingo propose des milliers d'activités d'apprentissage des langues pour les enfants.",
      },
      ar : {
        canonical: 'https://dinolingo.com/ar/curriculum',
        title: 'منهج Dinolingo - خطة الدروس',
        reachName: 'منهج Dinolingo',
        description:
          "منهج Dinolingo الشامل يحتوي على الآلاف من أنشطة تعلم اللغات للأطفال",
      },
      pt: {
        canonical: 'https://dinolingo.com/pt/curriculum',
        title: 'Programa de Ensino Dinolingo - Plano de Aulas',
        reachName: 'Programa de Ensino Dinolingo',
        description:
          "O amplo programa de ensino do Dinolingo inclui milhares de atividades de aprendizado de idiomas para crianças.",
      },
      hi: {
        canonical: 'https://dinolingo.com/hi/curriculum',
        title: 'Dinolingo (डिनोलिंगो) पाठ्यक्रम – लेसन प्लान ',
        reachName: 'Dinolingo (डिनोलिंगो) पाठ्यक्रम',
        description:
          "Dinolingo (डिनोलिंगो) के व्यापक पाठ्यक्रम में बच्चों के लिए भाषा सीखने की हज़ारों गतिविधियाँ शामिल हैं",
      },
      pl : {
        canonical: 'https://dinolingo.com/pl/curriculum',
        title: 'Program nauczania Dinolingo — Plan lekcji',
        reachName: 'Program nauczania Dinolingo',
        description:
          "Obszerny program Dinolingo obejmuje tysiące zajęć do nauki języków dla dzieci",
      },
      es : {
        canonical: 'https://dinolingo.com/es/curriculum',
        title: 'Programa de Estudios de Dinolingo  - Plan de Lecciones',
        reachName: 'Programa de Estudios de Dinolingo',
        description:
          "El extenso programa de estudios de Dinolingo incluye miles de actividades de aprendizaje de idiomas para niños",
      },
      tr : {
        canonical: 'https://dinolingo.com/tr/curriculum',
        title: 'Dinolingo Müfredatı - Ders Planı',
        reachName: 'Dinolingo Müfredatı',
        description:
          "Dinolingo'nun kapsamlı müfredatı, çocuklar için binlerce dil öğrenme aktivitesi içeriyor",
      },
      zh: {
        canonical: 'https://dinolingo.com/zh/curriculum',
        title: 'Dinolingo 课程 - 课程计划',
        reachName: 'Dinolingo 课程',
        description:
        "Dinolingo 课程内容广泛，包括数以千计的儿童语言学习活动",
      },
      it : {
        canonical: 'https://dinolingo.com/it/curriculum',
        title: 'Il curriculum di Dinolingo – Piano delle lezioni',
        reachName: 'Curriculum di Dinolingo',
        description:
          "Il vasto curriculum di Dinolingo include migliaia di attività di apprendimento delle lingue per bambini",
      },
      ko: {
        canonical: 'https://dinolingo.com/ko/curriculum',
        title: 'Dinolingo 커리큘럼 - 수업 계획',
        reachName: 'Dinolingo 커리큘럼',
        description:
          'Dinolingo의 광범위한 커리큘럼에는 아이를 위한 수천 개의 언어 학습 활동이 포함됩니다.',
      },
      jp : {
        canonical: 'https://dinolingo.com/jp/curriculum',
        title: 'Dinolingoのレッスン - レッスン内容',
        reachName: 'Dinolingoレッスン内容',
        description:
          "Dinolingoでは子どもが学びやすい言語学習のアクティビティを取り入れた幅広いレッスンを提供",
      }
    },
  },
  about_us: {
    meta: {
      en : {
        canonical: 'https://dinolingo.com/about-us',
        title: 'About Dinolingo language learning program for kids',
        reachName: 'About Dinolingo',
        description:
          'Dinolingo is an online language learning website and app for kids',
      },
      ru: {
        canonical: 'https://dinolingo.com/ru/about-us',
        title: 'О программе изучения языка для детей Dinolingo',
        reachName: 'О Dinolingo',
        description:
          'Dinolingo — это онлайн-сайт и приложение для изучения иностранных языков для детей.',
      },
      ua : {
        canonical: 'https://dinolingo.com/ua/about-us',
        title: 'Про програму вивчення мови Dinolingo для дітей',
        reachName: 'Про Dinolingo',
        description: 'Dinolingo — це веб-сайт і додаток для вивчення мов онлайн для дітей',
      },
      de : {
        canonical: 'https://dinolingo.com/de/about-us',
        title: 'Wissenswertes über das Dinolingo-Sprachlernprogramm für Kinder',
        reachName: 'Über Dinolingo',
        description:
          'Dinolingo ist eine Website und App zum Online-Sprachlernen für Kinder',
      },
      fr : {
        canonical: 'https://dinolingo.com/fr/about-us',
        title: 'À propos des programmes d\'apprentissage des langues pour les enfants',
        reachName: 'A propos de Dinolingo',
        description:
          'Dinolingo est un site internet d\'apprentissage des langues et une application pour les enfants',
      },
      ar : {
        canonical: 'https://dinolingo.com/ar/about-us',
        title: 'حول Dinolingo برنامج تعلم اللغات للأطفال',
        reachName: 'حول Dinolingo',
        description:
          'Dinolingo هو موقع إلكتروني وتطبيق للأطفال لتعلم اللغات عبر الإنترنت',
      },
      pt: {
        canonical: 'https://dinolingo.com/pt/about-us',
        title: 'Sobre o programa Dinolingo de aprendizado de idiomas para crianças',
        reachName: 'Sobre o Dinolingo',
        description:
          'Dinolingo é um site e aplicativo de aprendizado de idiomas para crianças',
      },
      hi: {
        canonical: 'https://dinolingo.com/hi/about-us',
        title: 'बच्चों के लिए Dinolingo (डिनोलिंगो) भाषा सीखने के कार्यक्रम के बारे में',
        reachName: 'Dinolingo (डिनोलिंगो) के बारे में',
        description:
        'Dinolingo (डिनोलिंगो) बच्चों के लिए एक ऑनलाइन भाषा सीखने की वेबसाइट और ऐप है',
      } ,
      pl : {
        canonical: 'https://dinolingo.com/pl/about-us',
        title: 'O programie do nauki języków dla dzieci Dinolingo',
        reachName: 'O Dinolingo',
        description:
          'Dinolingo to strona internetowa i aplikacja do nauki języków dla dzieci',
      },
      es : {
        canonical: 'https://dinolingo.com/es/about-us',
        title: 'Acerca del programa de aprendizaje de idiomas de Dinolingo para niños',
        reachName: 'Acerca de Dinolingo',
        description:
          'Dinolingo es un sitio web y una aplicación de aprendizaje de idiomas en línea para niños',
      },
      tr : {
        canonical: 'https://dinolingo.com/tr/about-us',
        title: 'Dinolingo çocuklar için dil öğrenme programı hakkında',
        reachName: 'Dinolingo Hakkında',
        description:
          'Dinolingo, çocuklar için 50 dilde online dil öğrenme sitesi ve uygulamasıdır',
      },
      zh: {
        canonical: 'https://dinolingo.com/zh/about-us',
        title: '关于 Dinolingo 儿童语言学习课程',
        reachName: '关于 Dinolingo',
        description:
        'Dinolingo 是为儿童而设的在线语言学习网站与应用程序',
      },
      it : {
        canonical: 'https://dinolingo.com/it/about-us',
        title: 'Informazioni sul programma di apprendimento delle lingue di Dinolingo per bambini',
        reachName: 'Informazioni su Dinolingo',
        description:
          'Dinolingo è un sito e un\'app di apprendimento delle lingue online per bambini',
      },
      ko: {
        canonical: 'https://dinolingo.com/ko/about-us',
        title: '어린이를 위한 Dinolingo 어학 학습 프로그램 소개',
        reachName: 'Dinolingo 소개',
        description:
          'Dinolingo는 아이를 위한 온라인 언어 학습 웹사이트이자 앱입니다.',
      },
      jp : {
        canonical: 'https://dinolingo.com/jp/about-us',
        title: 'Dinolingo 子どものための言語学習プログラムについて',
        reachName: 'Dinolingoについて',
        description:
          'Dinolingoは、子ども向けのオンライン言語学習ウェブサイトとアプリを提供しています。',
      }
    }
  },
  homeschool: {
    meta: {
      en : {
        canonical: 'https://dinolingo.com/homeschool',
        title: 'Study language from home withDinoLingo',
        reachName: 'Study language from home',
        description:
          'Dinolingo is a great language learning resource for French, Spanish and Latin students, homeschoolers and homeschool families.',
      },
      ru: {
        canonical: 'https://dinolingo.com/ru/homeschool',
        title: 'Изучайте иностранные языки дома с DinoLingo',
        reachName: 'Изучайте иностранные языки из дома',
        description:
          'Dinolingo - это отличный языковой ресурс для изучения французского, испанского и латинского языков учениками, школьниками и семьями которые обучаются на дому',
      },
      ua : {
        canonical: 'https://dinolingo.com/ua/homeschool',
        title: 'Вивчайте мову вдома з DinoLingo',
        reachName: 'Вивчайте мову вдома',
        description: 'Dinolingo — чудовий ресурс для вивчення французької, іспанської та латинської мов, школярами, учнями та сімями, які навчаються вдома.',
      },
      de : {
        canonical: 'https://dinolingo.com/de/homeschool',
        title: 'Sprachen lernen von zu Hause aus mit Dinolingo',
        reachName: 'Sprache lernen von zu Hause aus',
        description:
          'Dinolingo eignet sich hervorragend zum Fremdsprachenlernen für Französisch-, Spanisch- und Lateinschüler, Heimschüler und Familien, die zu Hause unterrichten.',
      },
      fr : {
        canonical: 'https://dinolingo.com/fr/homeschool',
        title: 'Etudier une langue depuis chez-soi avec DinoLingo',
        reachName: 'Etudier une langue depuis chez-soi',
        description:
          'Dinolingo : un excellent outil d\'apprentissage des langues pour les étudiants de français, d\'espagnol et de latin, ainsi que pour les élèves et les familles scolarisés à domicile.',
      },
      ar : {
        canonical: 'https://dinolingo.com/ar/homeschool',
        title: 'دراسة اللغات من المنزل مع DinoLingo',
        reachName: 'دراسة اللغات من المنزل',
        description:
          'Dinolingo هو مصدر رائع للطلاب من فرنسا وإسبانيا وأمريكا اللاتينية وكذلك للطلاب المنزليين والعائلات التي تعتمد على الدراسة المنزلية.',
      },
      pt: {
        canonical: 'https://dinolingo.com/pt/homeschool',
        title: 'Estude línguas em casa com o DinoLingo',
        reachName: 'Estude línguas em casa',
        description:
          'O Dinolingo é uma excelente fonte de aprendizado para os estudantes de francês, espanhol e latim e para uso no ensino domiciliar.',
      },
      hi: {
        canonical: 'https://dinolingo.com/hi/homeschool',
        title: 'Dinolingo (डिनोलिंगो) के साथ घर से भाषा का अध्ययन करें',
        reachName: 'घर से भाषा का अध्ययन करें',
        description:
          'Dinolingo (डिनोलिंगो) फ्रेंच, स्पेनिश और लैटिन छात्रों, होमस्कूलर्स और होमस्कूल परिवारों के लिए एक महान भाषा सीखने का संसाधन है.',
      },
      pl : {
        canonical: 'https://dinolingo.com/pl/homeschool',
        title: 'Ucz się języka w domu z DinoLingo',
        reachName: 'Ucz się języka w domu',
        description:
          'Dinolingo jest świetnym zasobem do nauki języków dla uczących się francuskiego, hiszpańskiego i łaciny, uczniów oraz rodzin, które prowadzą edukację domową.',
      },
      es : {
        canonical: 'https://dinolingo.com/es/homeschool',
        title: 'Estudie idiomas desde casa con DinoLingo',
        reachName: 'Estudie idiomas desde casa',
        description:
          'Dinolingo es un excelente recurso de aprendizaje de idiomas para estudiantes de Francés, Español y Latín, quienes se educan en el hogar y familias que educan en el hogar.',
      },
      tr : {
        canonical: 'https://dinolingo.com/tr/homeschool',
        title: 'DinoLingo ile evden yabancı dil çalışın',
        reachName: 'Evden yabancı dil çalışın',
        description:
          'Dinolingo, Fransızca, İspanyolca ve Latince öğrenciler, evde eğitim görenler ve evde eğitim veren aileler için harika bir dil öğrenme kaynağıdır.',
      },
      zh: {
        canonical: 'https://dinolingo.com/zh/homeschool',
        title: '与 DinoLingo 一起在家学习语言',
        reachName: '在家学习语言',
        description:
        'Dinolingo 是一套很好的语言学习资源，为学习法语、西班牙语及拉丁语的学生、在家学习的学生及在家学习的家庭而设。',
      },
      it : {
        canonical: 'https://dinolingo.com/it/homeschool',
        title: 'Studia le lingue da casa con DinoLingo',
        reachName: 'Studia le lingue da casa',
        description:
          'Dinolingo è una fantastica risorsa di apprendimento della lingua francese, spagnola e latina per gli alunni, gli studenti e le famiglie che scelgono l\'homeschooling.',
      },
      ko: {
        canonical: 'https://dinolingo.com/ko/homeschool',
        title: 'DinoLingo와 함께 집에서 배우는 언어',
        reachName: '집에서 배우는 언어',
        description:
          'Dinolingo는 프랑스어, 스페인어 및 라틴어를 공부하는 학생, 홈스쿨러 및 홈스쿨 가족을 위한 훌륭한 언어 학습 프로그램입니다. ',
      },
      jp : {
        canonical: 'https://dinolingo.com/jp/homeschool',
        title: 'DinoLingoで、家にいながら言語を学習しましょう',
        reachName: '家にいながら言語を学習',
        description:
          'Dinolingoは、フランス語、スペイン語、ラテン語の学習者や、自宅学習、ホームスクールをされているご家庭にとってすばらしい言語学習のリソースとなります。',
      }
    },
  },
  how_it_works: {
    meta: {
      en : {
        canonical: 'https://dinolingo.com/how-it-works',
        title: 'The science behind Dinolingo method - How it works?',
        reachName: 'Dinolingo method',
        description:
          'As a leading language learning program for kids, Dinolingo uses the immersion method, repetition technique, gamification and age-appropriate content.',
      },
      ru: {
        canonical: 'https://dinolingo.com/ru/how-it-works',
        title: 'Научный подход Dinolingo  - Как это работает?',
        reachName: 'Подход Dinolingo',
        description:
          'Как ведущая программа изучения языка для детей, Dinolingo использует метод погружения, технику повторения, геймификацию и контент, соответствующий возрасту.',
      },
      ua : {
        canonical: 'https://dinolingo.com/ua/how-it-works',
        title: 'Науковий підхід Dinolingo – Як це працює?',
        reachName: 'Підхід Dinolingo',
        description: 'Як провідна програма вивчення мови для дітей, Dinolingo використовує метод занурення, техніку повторення, гейміфікацію та відповідний віку контент.',
      },
      de : {
        canonical: 'https://dinolingo.com/de/how-it-works',
        title: 'Die Wissenschaft hinter der Dinolingo-Methode - Wie funktioniert das Ganze?',
        reachName: 'Dinolingo-Methode',
        description: 'Als führendes Sprachlernprogramm für Kinder nutzt Dinolingo die Immersionsmethode, die Wiederholungstechnik, Gamification und altersgemäße Inhalte.',
      },
      fr : {
        canonical: 'https://dinolingo.com/fr/how-it-works',
        title: 'La science derrière la méthode de Dinolingo. Comment ça marche ?',
        reachName: 'La méthode de Dinolingo',
        description:
          'Leader dans l\'apprentissage des langues pour les enfants, Dinolingo utilise la méthode d\'immersion, la technique de répétition, la gamification. Elle propose en outre un contenu adapté à l\'âge des enfants.',
      },
      ar : {
        canonical: 'https://dinolingo.com/ar/how-it-works',
        title: 'العلم وراء طريقة Dinolingo - كيف تعمل؟',
        reachName: 'طريقة Dinolingo',
        description:
          'بوصفه برنامجًا رائدًا لتعلم اللغات للأطفال، يستخدم Dinolingo طريقة التعليم بالغمر وتقنية التكرار والألعاب والمحتوى المناسب للسن.',
      },
      pt: {
        canonical: 'https://dinolingo.com/pt/how-it-works',
        title: 'A ciência por trás do método Dinolingo - Como ele funciona?',
        reachName: 'Dinolingo method',
        description:
          'Como um programa líder no aprendizado de idiomas para crianças, o Dinolingo utiliza o método de imersão, técnica de repetição, gamificação e conteúdo voltado para o público infantil.',
      },
      hi: {
        canonical: 'https://dinolingo.com/hi/how-it-works',
        title: 'Dinolingo (डिनोलिंगो) विधि के पीछे का विज्ञान - यह कैसे काम करता है?',
        reachName: 'Dinolingo method',
        description:
          'बच्चों के लिए एक अग्रणी भाषा सीखने के कार्यक्रम के रूप में, Dinolingo (डिनोलिंगो) इमर्शन विधि, दोहराव तकनीक, गेमिफिकेशन और आयु-उपयुक्त सामग्री का उपयोग करता है।.',
      },
      pl : {
        canonical: 'https://dinolingo.com/pl/how-it-works',
        title: 'Podstawy naukowe metody Dinolingo - Jak to działa?',
        reachName: 'Metoda Dinolingo',
        description:
          'Jako wiodący program do nauki języków dla dzieci, Dinolingo wykorzystuje metodę immersji, technikę powtórek, grywalizację oraz treści odpowiednie do wieku.',
      },
      es : {
        canonical: 'https://dinolingo.com/es/how-it-works',
        title: 'La ciencia detrás del método de Dinolingo method - ¿Cómo funciona?',
        reachName: 'Dinolingo method',
        description:
          'Como un programa líder en el aprendizaje de idiomas para niños, Dinolingo utiliza el método de inmersión, la técnica de repetición, ludificación y contenido adecuado a la edad.',
      },
      tr : {
        canonical: 'https://dinolingo.com/tr/how-it-works',
        title: 'Dinolingo’nun kullandığı yöntemin ardındaki bilim - Nasıl çalışıyor?',
        reachName: 'Dinolingo yöntemi',
        description:
          'Çocuklar için önde gelen yabancı dil öğrenme programı olan Dinolingo, daldırma (immersion) yöntemi, tekrarlama tekniği, oyunlaştırma ve yaşa uygun içerikler kullanıyor.',
      },
      zh: {
        canonical: 'https://dinolingo.com/zh/how-it-works',
        title: 'Dinolingo 方法背后的科学 - 怎样才能有效果？',
        reachName: 'Dinolingo 方法',
        description:
         '作为领先的儿童语言学习课程，Dinolingo 使用沉浸式方法、重复技术、游戏化及适合年龄的内容。',
      },
      it : {
        canonical: 'https://dinolingo.com/it/how-it-works',
        title: 'La scienza dietro al metodo Dinolingo – Come funziona?',
        reachName: 'Dinolingo method',
        description:
          'Essendo un programma di apprendimento linguistico per bambini leader nel settore, Dinolingo usa il metodo dell\'immersione, tecniche di ripetizione, gamification e contenuti appropriati per l\'età.',
      },
      ko: {
        canonical: 'https://dinolingo.com/ko/how-it-works',
        title: 'Dinolingo 만의 숨어 있는 과학은 - 어떻게 작동할까요?',
        reachName: 'Dinolingo만읜 비법',
        description:
          '아이들을 위한 선도적인 언어 학습 프로그램인 Dinolingo는 몰입 방법, 반복 기술, 게임화 및 연령에 맞는 콘텐츠를 사용합니다.',
      },
      jp : {
        canonical: 'https://dinolingo.com/jp/how-it-works',
        title: 'Dinolingoメソッド - 学習方法',
        reachName: 'Dinolingo method',
        description:
          '子ども向け言語学習プログラム業界トップのDinolingoでは、イマージョンメソッド、繰り返し技法、ゲーミフィケーション、年齢に合わせたコンテンツを使用しています。',
      }
    },
  },
  parents: {
    meta: {
      en : {
        canonical: 'https://dinolingo.com/parents',
        title: "Tips for parents on children's language learning",
        reachName: 'Tips for parents',
        description: "When it comes to children's language learning, Dinolingo recommends that parents be consistent.",
      },
      ru: {
        canonical: 'https://dinolingo.com/ru/parents',
        title: "Советы для родителей по изучению языка детьми",
        reachName: 'Советы для родителей',
        description:
          "Когда дело доходит до изучения языка детьми, Dinolingo рекомендует родителям быть последовательными.",
      },
      ua : {
        canonical: 'https://dinolingo.com/ua/parents',
        title: "Поради батькам щодо вивчення мови дітьми",
        reachName: 'Поради для батьків',
        description: "Коли справа доходить до вивчення мови дітьми, Dinolingo рекомендує батькам бути послідовними.",
      },
      de : {
        canonical: 'https://dinolingo.com/de/parents',
        title: "Tipps für Eltern zum Sprachenlernen ihrer Kinder",
        reachName: 'Tipps für Eltern',
        description:
          "Allem voran empfiehlt Dinolingo den Eltern Konsequenz, wenn es um das Sprachenlernen ihrer Kinder geht.",

      },
      fr : {
        canonical: 'https://dinolingo.com/fr/parents',
        title: "Conseils à l\'intention des parents concernant l\'apprentissage d\'une langue chez l\'enfant",
        reachName: 'Conseils à l\'intention des parents',
        description:
          "En matière d'apprentissage des langues par les enfants, Dinolingo recommande aux parents de faire preuve de constance.",
      },
      ar : {
        canonical: 'https://dinolingo.com/ar/parents',
        title: "نصائح للوالدين حول تعلم اللغات للأطفال",
        reachName: 'نصائح للوالدين',
        description:
          "عندما يتعلق الأمر بتعلم اللغات للأطفال، فإن Dinolingo يوصي الآباء والأمهات بالحفاظ على الاستمرارية.",
      },
      pt: {
        canonical: 'https://dinolingo.com/pt/parents',
        title: "Dicas para os pais no aprendizado de idiomas por crianças",
        reachName: 'Dicas para os pais',
        description:
          "Durante o aprendizado de um novo idioma, o Dinolingo recomenda que os pais mantenham uma rotina regular."
      },
      hi: {
        canonical: 'https://dinolingo.com/hi/parents',
        title: "बच्चों की भाषा सीखने पर माता-पिता के लिए टिप्स",
        reachName: 'माता-पिता के लिए टिप्स',
        description:
          "जब बच्चों की भाषा सीखने की बात आती है, तो Dinolingo (डिनोलिंगो) सलाह देते हैं कि माता-पिता सुसंगत रहें।",
      },
      pl : {
        canonical: 'https://dinolingo.com/pl/parents',
        title: "Wskazówki dla rodziców dotyczące nauki języków przez dzieci",
        reachName: 'Wskazówki dla rodziców',
        description:
          "Jeżeli chodzi o naukę języków przez dzieci, Dinolingo zaleca, aby rodzice byli konsekwentni.",
      },
      es : {
        canonical: 'https://dinolingo.com/es/parents',
        title: "Consejos para padres sobre el aprendizaje de idiomas de los niños",
        reachName: 'Consejos para padres',
        description:
          "Cuando se trata del aprendizaje de idiomas de los niños, Dinolingo recomienda que los padres sean consistentes.",
      },
      tr : {
        canonical: 'https://dinolingo.com/tr/parents',
        title: "Ebeveynler için çocuklarda yabancı dil öğretimi hakkında ipuçları",
        reachName: 'Ebeveynler için ipuçları',
        description:
          "Söz konusu çocukların dil öğrenimi olduğunda, Dinolingo ebeveynlerin tutarlı olmasını tavsiye ediyor.",
      },
      zh: {
        canonical: 'https://dinolingo.com/zh/parents',
         title: "给家长有关儿童语言学习的小贴士",
         reachName: '给家长的提示',
        description:
         "谈到有关孩子的语言学习，Dinolingo 建议父母应保持一致。",
      },
      it : {
        canonical: 'https://dinolingo.com/it/parents',
        title: "Consigli per i genitori per l'apprendimento linguistico dei bambini",
        reachName: 'Consigli per i genitori',
        description:
          "Per quanto riguarda l'apprendimento linguistico dei bambini, Dinolingo consiglia che i genitori siano costanti.",
      },
      ko: {
        canonical: 'https://dinolingo.com/ko/parents',
        title: '부모를 위한 어린이 언어 학습 팁',
        reachName: '부모를 위한 팁',
        description:
          '자녀의 언어 습득기가 오면, Dinolingo은 부모가 일관성을 지킬 것을 권고합니다.',
      },
      jp : {
        canonical: 'https://dinolingo.com/jp/parents',
        title: "子どもの言語学習に関する保護者へのアドバイス",
        reachName: '保護者へのアドバイス',
        description:
          "子どもの言語学習についてのDinolingoからのアドバイスは、保護者が、子どもに毎日継続的に学習するよう促すことが大切です。",
      }
    },
  },
  privacy: {
    meta: {
      en : {
        canonical: 'https://help.dinolingo.com/article/493-privacy',
        title: 'Privacy Policy Statement of Dinolingo',
        reachName: 'Dinolingo Privacy Policy',
        description:
          'Dinolingo website is 100% advertisement-free. We do not sell or share any customer data. We do not collect any information from our online visitors and or customers without their permission.',
      },
      ru: {
        canonical: 'https://dinolingo.com/ru/privacy',
        title: 'Положения о политике конфиденциальности Dinolingo',
        reachName: 'Политика конфиденциальности Dinolingo',
        description:
          'Веб-сайт Dinolingo не использует рекламу. Мы не продаем и не передаем какие-либо данные клиентов. Мы не собираем никакой информации о наших онлайн-посетителях и/или клиентах без их ведома.'
      },
      ua : {
        canonical: 'https://dinolingo.com/ua/privacy',
        title: 'Положення про політику конфіденційності Dinolingo',
        reachName: 'Політика конфіденційності Dinolingo',
        description: 'Веб-сайт Dinolingo не використовує реклами. Ми не продаємо і не передаємо жодних даних клієнтів. Ми не збираємо жодної інформації про наших онлайн-відвідувачів та/або клієнтів без їхнього відома.',
      },
      de : {
        canonical: 'https://dinolingo.com/de/privacy',
        title: 'Dinolingo Datenschutzerklärung',
        reachName: 'Dinolingo Datenschutzrichtlinie',
        description:
          'Die Website von Dinolingo ist 100% werbefrei. Wir verkaufen oder teilen keine Kundendaten. Wir sammeln keine Informationen von unseren Online-Besuchern oder Kunden ohne deren Zustimmung.',
      },
      fr : {
        canonical: 'https://dinolingo.com/fr/privacy',
        title: 'Déclaration de la politique de confidentialité de Dinolingo',
        reachName: 'Politique de confidentialité de Dinolingo',
        description:
          'Le site Web de Dinolingo ne contient aucune publicité. Nous ne vendons ni ne partageons aucune donnée sur nos clients. Nous ne collectons aucune information sur nos visiteurs en ligne et nos clients sans leur permission.',
      },
      ar : {
        canonical: 'https://dinolingo.com/ar/privacy',
        title: 'بيان سياسة الخصوصية لـ Dinolingo',
        reachName: 'سياسة خصوصية Dinolingo',
        description:
          'موقع Dinolingo خالٍ من الإعلانات بنسبة 100%. نحن لا نقوم ببيع أو مشاركة أي بيانات خاصة بالعميل. نحن لا نجمع أي معلومات من عملائنا أو زائرينا عبر الإنترنت دون إذنهم.',
      },
      pt: {
        canonical: 'https://dinolingo.com/pt/privacy',
        title: 'Política de Privacidade Dinolingo',
        reachName: 'Políica de Privacidade do Dinolingo',
        description:
          'O site Dinolingo é 100% livre de anúncios. Nós não vendemos ou compartilhamos dados dos nosssos usuários. Não coletamos nenhuma informação de nossos visitantes online ou usuários sem as devidas permissões.',
      },
      hi: {
        canonical: 'https://dinolingo.com/hi/privacy',
        title: 'Dinolingo (डिनोलिंगो) का गोपनीयता नीति वक्तव्य',
        reachName: 'Dinolingo (डिनोलिंगो) गोपनीयता नीति ',
        description:
          'Dinolingo (डिनोलिंगो) की वेबसाइट 100% विज्ञापन-मुक्त है। हम किसी भी ग्राहक डेटा को बेचते या साझा नहीं करते हैं। हम अपने ऑनलाइन विजिटर्स और या ग्राहकों से उनकी अनुमति के बिना कोई भी जानकारी एकत्र नहीं करते हैं।',
      },
      pl : {
        canonical: 'https://dinolingo.com/pl/privacy',
        title: 'Oświadczenie o ochronie prywatności Dinolingo',
        reachName: 'Polityka prywatności Dinolingo',
        description:
          'Strona Dinolingo jest całkowicie wolna od reklam. Nie sprzedajemy ani nie udostępniamy żadnych danych klientów. Nie zbieramy żadnych informacji od naszych gości online i/lub klientów bez ich zgody.',
      },
      es : {
        canonical: 'https://dinolingo.com/es/privacy',
        title: 'Política de Privacidad de Dinolingo',
        reachName: 'Política de Privacidad de Dinolingo',
        description:
          'El sitio web de Dinolingo website es 100% sin anuncios. No vendemos ni compartimos ninguna información del cliente. No recopilamos ninguna información de nuestros visitantes en línea y o clientes sin su permiso.',
      },
      tr : {
        canonical: 'https://dinolingo.com/tr/privacy',
        title: 'Dinolingo Gizlilik Politikası Beyanı',
        reachName: 'Dinolingo Gizlilik Politikası',
        description:
          'Dinolingo web sitesi %100 reklamsızdır. Hiçbir müşteri verisini satmıyoruz veya paylaşmıyoruz. Online ziyaretçilerimizden ve/veya müşterilerimizden izinleri olmadan herhangi bir bilgi toplamıyoruz.',
      },
      zh: {
        canonical: 'https://dinolingo.com/zh/privacy',
        title: 'Dinolingo 隐私政策声明',
        reachName: 'Dinolingo 隐私政策',
        description:
         'Dinolingo 网站 100% 无广告。我们不会出售或共享任何客户数据。未经在线访问者及客户的许可，我们不会收集他们的任何信息。',
      },
      it : {
        canonical: 'https://dinolingo.com/it/privacy',
        title: 'Informativa sulla privacy di Dinolingo',
        reachName: 'Politica sulla privacy di Dinolingo',
        description:
          'Il sito web Dinolingo è completamente privo di pubblicità. Non vendiamo né condividiamo i dati dei nostri clienti. Non raccogliamo nessuna informazione dei clienti e di chi visita il nostro sito online senza il loro permesso.',
      },
      ko: {
        canonical: 'https://dinolingo.com/ko/privacy',
        title: 'Dinolingo의 개인정보처리방침',
        reachName: 'Dinolingo 개인정보 보호정책',
        description:
          'Dinolingo 웹사이트는 100% 광고가 없습니다. 당사는 고객 데이터를 판매하거나 공유하지 않습니다. 당사는 온라인 방문자 및/또는 고객의 허가 없이는 어떠한 정보도 수집하지 않습니다.',
      },
      jp : {
        canonical: 'https://dinolingo.com/jp/privacy',
        title: 'Dinolingoに関する個人情報ポリシー',
        reachName: 'Dinolingo 個人情報ポリシー',
        description:
          'Dinolingoウェブサイトには、広告は一切掲載されません。顧客データを販売または共有することはありません。当社ウェブサイトへの訪問者から、または許可なく顧客からなんらかの情報を収集することもありません。',
      }
    },
  },
  terms: {
    meta: {
      en : {
        canonical: 'https://help.dinolingo.com/article/494-terms',
        title: 'Terms of Use and Submission of content of Dinolingo company',
        reachName: 'Dinolingo Terms of Use',
        description:
          'Description of website and service of Dinolingo. DinoLingo Site/mobile applications and other physical products and services are developed, produced and operated by DinoLingo, Inc.',
      },
      ru: {
        canonical: 'https://dinolingo.com/ru/terms',
        title: 'Условия использования и предоставления контента компанией Dinolingo',
        reachName: 'Условия использования Dinolingo',
        description:
          'Описание сайта и сервиса Dinolingo. DinoLingo Сайт/мобильные приложения и другие физические продукты и услуги разрабатываются, производятся и управляются DinoLingo, Inc.',
      },
      ua : {
        canonical: 'https://dinolingo.com/ua/terms',
        title: 'Умови використання та подання контенту компанії Dinolingo',
        reachName: 'Умови використання Dinolingo',
        description: 'Опис сайту та сервісу Dinolingo. Сайт DinoLingo/мобільні програми та інші фізичні продукти та послуги розробляються, виробляються та керуються DinoLingo, Inc.',
      },
      de : {
        canonical: 'https://dinolingo.com/de/terms',
        title: 'Nutzungsbedingungen und Weitergabe von Inhalten des Unternehmens Dinolingo',
        reachName: 'Nutzungsbedingungen von Dinolingo',
        description:
          'Darstellung der Website und des Dienstes von Dinolingo. Sowohl Website und App als auch andere physische Produkte und Dienstleistungen werden von DinoLingo, Inc. entwickelt, produziert und betrieben..',
      },
      fr : {
        canonical: 'https://dinolingo.com/fr/terms',
        title: 'Conditions d\'utilisation et soumission de contenu de la société Dinolingo',
        reachName: 'Conditions d\'utilisation de Dinolingo',
        description:
          'Présentation du site et des prestations de Dinolingo. Le site/les applications mobiles de DinoLingo ainsi que les autres produits et services physiques sont développés, produits et gérés par DinoLingo, Inc.',
      },
      ar : {
        canonical: 'https://dinolingo.com/ar/terms',
        title: 'شروط الاستخدام وتقديم محتوى شركة Dinolingo',
        reachName: 'شروط استخدام Dinolingo',
        description:
          'وصف لموقع Dinolingo والخدمة التي يقدمها. الموقع الإلكتروني وتطبيق الهاتف DinoLingo وغيرهما من الخدمات والمنتجات المادية يتم تطويرها وإنتاجها وتشغيلها بواسطة DinoLingo, Inc.',
      },
      pt: {
        canonical: 'https://dinolingo.com/pt/terms',
        title: 'Termos de Uso e Criação do Conteúdo da empresa Dinolingo',
        reachName: 'Termos de Uso do Dinolingo',
        description:
          'Descrição do site e dos serviços Dinolingo. O site, os aplicativos móveis DinoLingo e demais produtos e serviçis físicos são desenvolvidos, produzidos e operados pela DinoLingo, Inc.',
      },
      hi: {
        canonical: 'https://dinolingo.com/hi/terms',
        title: 'Dinolingo (डिनोलिंगो) कंपनी की उपयोग की शर्तें और सामग्री प्रस्तुत करना',
        reachName: 'Dinolingo (डिनोलिंगो) उपयोग की शर्तें',
        description:
          'Dinolingo (डिनोलिंगो) साइट/मोबाइल एप्लिकेशन और अन्य भौतिक उत्पाद और सेवाएं Dinolingo (डिनोलिंगो), Inc द्वारा विकसित, निर्मित और संचालित की जाती हैं।',
      },
      pl : {
        canonical: 'https://dinolingo.com/pl/terms',
        title: 'Warunki użytkowania i przesyłanie treści firmy Dinolingo',
        reachName: 'Warunki użytkowania Dinolingo',
        description:
          'Opis strony i usługi Dinolingo. Strona/aplikacje mobilne DinoLingo oraz inne produkty i usługi fizyczne są opracowywane, produkowane i obsługiwane przez DinoLingo, Inc..',
      },
      es : {
        canonical: 'https://dinolingo.com/es/terms',
        title: 'Términos de Uso y Envío de contenido de la compañía Dinolingo',
        reachName: 'Términos de Uso de Dinolingo',
        description:
          'Descripción del sitio web y el servicio de Dinolingo. Las aplicaciones en el sitio/móviles de DinoLingo y otros productos físicos y servicios son desarrollados, producidos y gestionados por DinoLingo, Inc.',
      },
      tr : {
        canonical: 'https://dinolingo.com/tr/terms',
        title: 'Kullanım Şartları ve Dinolingo şirketinin içeriğinin sunulması',
        reachName: 'Dinolingo Kullanım Şartları',
        description:
          'Web sitesi ve hizmet tanımı. DinoLingo Sitesi/mobil uygulamaları ve diğer fiziksel ürün ve hizmetleri DinoLingo, Inc. tarafından geliştirilir, üretilir ve işletilir.',
      },
      zh: {
        canonical: 'https://dinolingo.com/zh/terms',
         title: 'Dinolingo 公司的使用条款与内容提交',
        reachName: '使用条款',
        description:
         '有关 Dinolingo 网站与服务的描述。DinoLingo 网站/移动应用程序及其他实物产品与服务均由 DinoLingo, Inc. 开发、生产和运营。',
      },
      it : {
        canonical: 'https://dinolingo.com/it/terms',
        title: 'Condizioni d\'uso e inserimento di contenuti della società Dinolingo',
        reachName: 'Condizioni d\'uso di Dinolingo',
        description:
          'Descrizione del sito web e del servizio di Dinolingo. Il sito/le applicazioni mobile di DinoLingo, altri servizi e prodotti fisici sono sviluppati, realizzati e gestiti da DinoLingo, Inc.',
      },
      ko: {
        canonical: 'https://dinolingo.com/ko/terms',
        title: 'Dinolingo의 콘텐츠 이용 약관 및 제출',
        reachName: 'Dinolingo 이용 약관',
        description:
          'Dinolingo의 웹사이트 및 서비스에 대한 설명 Dinolingo 사이트/모바일 애플리케이션 및 기타 물리적 제품 및 서비스는 DinoLingo Inc.,에서 개발, 제작 및 운영합니다.',
      },
      jp : {
        canonical: 'https://dinolingo.com/jp/terms',
        title: 'Dinolingo社のコンテンツの利用および提出に関する規約',
        reachName: 'Dinolingo 利用規約',
        description:
          'Dinolingoのウェブサイトおよびサービスについて：DinoLingoのウェブサイト / アプリケーション、ならびにその他の商品およびサービスは、DinoLingo, Inc.によって開発、制作および運営されています。',
      }
    },
  },
  reviews: {
    meta: {
      en : {
        canonical: 'https://dinolingo.com/reviews',
        title: 'DinoLingo Reviews, Success Stories',
        reachName: 'DinoLingo Reviews',
        description:
          'We subscribed to DinoLingo French, my children love it, I recommend it to everyone.',
      },
      ru: {
        canonical: 'https://dinolingo.com/ru/reviews',
        title: 'DinoLingo Отзывы, Истории успеха',
        reachName: 'DinoLingo Отзывы',
        description:
          'У нас подписка на  французский от DinoLingo, моим детям очень нравится, всем рекомендую.',
      },
      ua : {
        canonical: 'https://dinolingo.com/ua/reviews',
        title: 'Відгуки DinoLingo, історії успіху',
        reachName: 'DinoLingo Відгуки',
        description: 'У нас підписка на уроки французької від DinoLingo, моїм дітям дуже подобається, всім рекомендую.',
      },
      de : {
        canonical: 'https://dinolingo.com/de/terms',
        title: 'Nutzungsbedingungen und Weitergabe von Inhalten des Unternehmens Dinolingo',
        reachName: 'Nutzungsbedingungen von Dinolingo',
        description:
          'Darstellung der Website und des Dienstes von Dinolingo. Sowohl Website und App als auch andere physische Produkte und Dienstleistungen werden von DinoLingo, Inc. entwickelt, produziert und betrieben..',
      },
      fr : {
        canonical: 'https://dinolingo.com/fr/reviews',
        title: 'Commentaires sur DinoLingo, Success Stories',
        reachName: 'Commentaires sur DinoLingo',
        description:
          'Nous nous sommes abonnés à DinoLingo pour le Français. Mes enfants adorent ça, Je recommande vivement.',
      },
      ar : {
        canonical: 'https://dinolingo.com/ar/reviews',
        title: 'مراجعات DinoLingo، قصص النجاح',
        reachName: 'مراجعات DinoLingo',
        description:
          'لقد قمنا بالاشتراك في دورة DinoLingo للغة الفرنسية، أطفالي يحبونه، وأوصي الجميع باستخدامه.',
      },
      pt: {
        canonical: 'https://dinolingo.com/pt/reviews',
        title: 'Avaliações do DinoLingo, Histórias de Sucesso',
        reachName: 'Avaliações do Dinolingo',
        description:
          'Assinamos o DinoLingo em francês e meus filhos adoraram, recomendo a todos.',
      },
      hi: {
        canonical: 'https://dinolingo.com/hi/reviews',
        title: 'Dinolingo (डिनोलिंगो) समीक्षाएं, सफलता की कहानियां',
        reachName: 'Dinolingo (डिनोलिंगो) समीक्षाएं',
        description:
          'हमने Dinolingo (डिनोलिंगो) फ्रेंच की सदस्यता ली है, मेरे बच्चे इसे पसंद करते हैं, मैं इसे सभी को सुझाता हूं.',
      },
      pl : {
        canonical: 'https://dinolingo.com/pl/reviews',
        title: 'Recenzje DinoLingo, historie sukcesu',
        reachName: 'Recenzje DinoLingo',
        description:
          'Zapisaliśmy się na francuski w DinoLingo, moje dzieci to uwielbiają, polecam wszystkim.',
      },
      es : {
        canonical: 'https://dinolingo.com/es/reviews',
        title: 'Opiniones de DinoLingo, Historias de Éxito',
        reachName: 'Opiniones de DinoLingo',
        description:
          'Nos suscribimos a DinoLingo Francés, a mi hijo le encanta, lo recomiendo a todos.',
      },
      tr : {
        canonical: 'https://dinolingo.com/tr/reviews',
        title: 'DinoLingo İncelemeleri, Başarı Hikayeleri',
        reachName: 'DinoLingo İncelemeleri',
        description:
          'DinoLingo Fransızca’ya abone olduk, çocuklarım bayıldı herkese tavsiye ederim.',
      },
      zh: {
        canonical: 'https://dinolingo.com/zh/reviews',
        title: 'DinoLingo 点评，成功案例',
        reachName: 'DinoLingo 点评',
        description:
         '我们订阅了法语版本 DinoLingo，我的孩子们很喜欢，我向大家推荐。',
      },
      it : {
        canonical: 'https://dinolingo.com/it/reviews',
        title: 'Recensioni di DinoLingo – Storie di successo',
        reachName: 'Recensioni di DinoLingo',
        description:
          'Ci siamo iscritti a DinoLingo Francese. I miei bambini lo adorano. Lo consiglio a tutti.',
      },
      ko: {
        canonical: 'https://dinolingo.com/ko/reviews',
        title: 'DinoLingo 리뷰, 성공 사례',
        reachName: 'DinoLingo 리뷰',
        description:
          '당사는 DinoLingo 불어를 구독했습니다. 제 아이들이 넘 좋아합니다.',
      },
      jp : {
        canonical: 'https://dinolingo.com/jp/reviews',
        title: 'DinoLingoのレビュー、利用者の声',
        reachName: 'DinoLingo利用者の声',
        description:
          '子どものためにDinoLingoのフランス語コースに登録しています。子どもは大好きで、みなさんにお勧めします。',
      }
    },
  },
  schools: {
    meta: {
      en : {
        canonical: 'https://dinolingo.com/schools-teachers',
        title: 'Dinolingo Language Learning for Schools and Teachers',
        reachName: 'Dinolingo for Schools',
        description:
          'Dinolingo is a great language learning resource for teachers for classroom and remote learning.',
      },
      ru: {
        canonical: 'https://dinolingo.com/ru/schools-teachers',
        title: 'Изучение иностранных языков с Dinolingo для школ и преподавателей',
        reachName: 'Dinolingo для школ',
        description:
          'Dinolingo является отличным языковым ресурсом для преподавателей для школьного и дистанционного обучения',
      },
      ua : {
        canonical: 'https://dinolingo.com/ua/schools-teachers',
        title: 'Вивчення мови Dinolingo для шкіл і вчителів',
        reachName: 'Dinolingo для шкіл',
        description: 'Dinolingo — чудовий ресурс для вивчення мов для вчителів для класного та дистанційного навчання.',
      },
      de : {
        canonical: 'https://dinolingo.com/de/schools-teachers',
        title: 'Dinolingo Sprachenlernen für Schulen und Lehrer',
        reachName: 'Dinolingo für Schulen',
        description:
          'Dinolingo unterstützt Lehrerinnen und Lehrer beim Lehren von Sprachen, sowohl im Klassenzimmer als auch beim Selbststudium.',

      },
      fr : {
        canonical: 'https://dinolingo.com/fr/schools-teachers',
        title: 'Apprentissage des langues pour les écoles et les enseignants',
        reachName: 'Dinolingo pour les écoles',
        description:
          'Dinolingo est une formidable ressource d\'apprentissage des langues pour les enseignants, que ce soit pour l\'apprentissage en classe ou à distance.',
      },
      ar : {
        canonical: 'https://dinolingo.com/ar/schools-teachers',
        title: 'Dinolingo لتعلم اللغات للمدارس والمعلمين',
        reachName: 'Dinolingo للمدارس',
        description:
          'Dinolingo هو مصدر رائع لتعليم اللغة للمعلمين عبر الفصول الدراسية والتعلم عن بعد.',
      },
      pt: {
        canonical: 'https://dinolingo.com/pt/schools-teachers',
        title: 'Curso de Idiomas Dinolingo Para Escolas e Professores',
        reachName: 'Dinolingo para Escolas',
        description:
          'Dinolingo é um excelente recurso de ensino para professores, tanto em cursos presenciais quanto para aulas à distância.',
      },
      hi: {
        canonical: 'https://dinolingo.com/hi/schools-teachers',
        title: 'स्कूलों और शिक्षकों के लिए Dinolingo (डिनोलिंगो) भाषा सीखना',
        reachName: 'स्कूलों के लिए Dinolingo (डिनोलिंगो)',
        description:
         'Dinolingo (डिनोलिंगो) शिक्षकों के लिए कक्षा और दूरस्थ शिक्षा के लिए एक महान भाषा-शिक्षण संसाधन है।',
      },
      pl : {
        canonical: 'https://dinolingo.com/pl/schools-teachers',
        title: 'Nauka języka w Dinolingo dla szkół i nauczycieli',
        reachName: 'Dinolingo dla szkół',
        description:
          'Dinolingo jest świetnym źródłem dla nauczycieli do nauczania języków w klasie i nauce zdalnej.',
      },
      es : {
        canonical: 'https://dinolingo.com/es/schools-teachers',
        title: 'Aprendizaje de Idiomas de Dinolingo para Escuelas y Maestros',
        reachName: 'Dinolingo para Escuelas',
        description:
          'Dinolingo es un excelente recurso de aprendizaje para maestros para el salón de clase y el aprendizaje a distancia.',
      },
      tr : {
        canonical: 'https://dinolingo.com/tr/schools-teachers',
        title: 'Okulllar ve Mğretmenler için Dinolingo Dil Öğrenimi',
        reachName: 'Okullar için Dinolingo',
        description:
          'Dinolingo, sınıfta ve uzaktan eğitim için öğretmenlere yönelik harika bir dil öğrenme kaynağıdır.',
      },
      zh: {
        canonical: 'https://dinolingo.com/zh/schools-teachers',
         title: '为学校及教师而设的 Dinolingo 语言学习',
         reachName: '为学校而设的 Dinolingo',
        description:
         'Dinolingo 是很棒的语言学习资源，可供教师进行课堂及远程学习',
      },
      it : {
        canonical: 'https://dinolingo.com/it/schools-teachers',
        title: 'Apprendimento delle lingue Dinolingo per scuole e insegnanti',
        reachName: 'Dinolingo per le scuole',
        description:
          'Dinolingo è una fantastica risorsa di apprendimento delle lingue per gli insegnanti nella didattica in aula e da remoto.',
      },
      ko: {
        canonical: 'https://dinolingo.com/ko/schools-teachers',
        title: '학교와 교사를 위한 DinoLingo 언어 학습',
        reachName: '학교를 위한 DinoLingo',
        description:
          'DinoLingo는 교실 및 원격 학습을 위한 교사들을 위한 훌륭한 언어 학습 리소스입니다. ',
      },
      jp : {
        canonical: 'https://dinolingo.com/jp/schools-teachers',
        title: '学校関係者および教師向けDinolingo言語学習',
        reachName: '学校関係者向けDinolingo',
        description:
          'Dinolingoは教室での授業や遠隔学習を行う教師にとってすばらしい言語学習のリソースです。',
      }
    },
  },
  courses: {
    meta: {
      en : {
        canonical: 'https://dinolingo.com/language-courses',
        title: 'Dinolingo - Language courses for kids',
        reachName: 'Language courses for kids',
        description:
          "Dinolingo is the world's largest online language-learning program for children",
      },
      ru: {
        canonical: 'https://dinolingo.com/ru/language-courses',
        title: 'Dinolingo - Языковые курсы для детей',
        reachName: 'Языковые курсы для детей',
        description:
          "Dinolingo - крупнейшая в мире онлайн-программа по изучению языков для детей",
      },
      ua : {
        canonical: 'https://dinolingo.com/ua/language-courses',
        title: 'Dinolingo - Мовні курси для дітей',
        reachName: 'Мовні курси для дітей',
        description:
          "Dinolingo — це найбільша у світі програма з вивчення мов для дітей",
      },
      de : {
        canonical: 'https://dinolingo.com/de/language-courses',
        title: 'Dinolingo - Sprachkurse für Kinder',
        reachName: 'Sprachkurse für Kinder',
        description:
          "Dinolingo ist das weltweit umfangreichste Online-Sprachlernprogramm für Kinder",

      },
      fr : {
        canonical: 'https://dinolingo.com/fr/language-courses',
        title: 'Dinolingo - cours de langues pour les enfants',
        reachName: 'cours de langues pour les enfants',
        description:
          "Dinolingo est le plus grand programme d\'apprentissage en ligne du monde pour les enfants",
      },
      ar : {
        canonical: 'https://dinolingo.com/ar/language-courses',
        title: 'Dinolingo - دورات تعليم اللغات للأطفال',
        reachName: 'دورات تعليم اللغات للأطفال',
        description:
          "Dinolingo هو أكبر برنامج في العالم لتعلم اللغة للأطفال عبر الإنترنت",
      },
      pt: {
        canonical: 'https://dinolingo.com/pt/language-courses',
        title: 'Dinolingo - Cursos de idiomas para crianças',
        reachName: 'Cursos de idiomas para crianças',
        description:
          "Dinolingo é o maior programa de aprendizado de idiomas online para crianças do mundo",
      },
      hi: {
        canonical: 'https://dinolingo.com/hi/language-courses',
        title: 'Dinolingo (डिनोलिंगो) - बच्चों के लिए भाषा कोर्स',
        reachName: 'बच्चों के लिए भाषा कोर्स',
        description:
          "डिनोलिंगो बच्चों के लिए दुनिया का सबसे बड़ा ऑनलाइन भाषा-शिक्षण कार्यक्रम है",
      },
      pl : {
        canonical: 'https://dinolingo.com/pl/language-courses',
        title: 'Dinolingo - kursy językowe dla dzieci',
        reachName: 'Kursy językowe dla dzieci',
        description:
          "Dinolingo to największy na świecie program do nauki języków online dla dzieci",
      },
      es : {
        canonical: 'https://dinolingo.com/es/language-courses',
        title: 'Dinolingo - Cursos de idiomas para niños',
        reachName: 'Cursos de idiomas para niños',
        description:
          "Dinolingo es el programa de aprendizaje de idiomas en línea para niños más grande del mundo",
      },
      tr : {
        canonical: 'https://dinolingo.com/tr/language-courses',
        title: 'Dinolingo - Çocuklar için dil kursları',
        reachName: 'Çocuklar için dil kursları',
        description:
          "Dinolingo, çocuklar için dünyanın en büyük online dil öğrenme programıdır",
      },
      zh: {
        canonical: 'https://dinolingo.com/zh/language-courses',
        title: 'Dinolingo - 儿童语言课程',
        reachName: '儿童语言课程',
        description:
         "Dinolingo 是全球最大的儿童在线语言学习课程",
      },
      it : {
        canonical: 'https://dinolingo.com/it/language-courses',
        title: 'Dinolingo – Corsi di lingue per bambini',
        reachName: 'Corsi di lingue per bambini',
        description:
          "Dinolingo è il programma di apprendimento linguistico online per bambini più grande al mondo",
      },
      ko: {
        canonical: 'https://dinolingo.com/ko/language-courses',
        title: ' Dinolingo- 어린이를 위한 어학연수',
        reachName: '어린이를 위한 어학 코스',
        description:
          'Dinolingo는 아이들을 위한 세계 최대의 온라인 언어 학습 프로그램입니다.'
      },
      jp : {
        canonical: 'https://dinolingo.com/jp/language-courses',
        title: 'Dinolingo - 子どものための言語学習コース',
        reachName: '子どものための言語学習コース',
        description:
          "Dinolingoは世界最大規模の子ども向けオンライン言語学習プログラム",
      }
    },
  },
}
