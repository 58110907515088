import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store, select } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { catchError, exhaustMap, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators'
import { IThumbnail } from 'src/app/models/Avatar.model'
import { ISubuser, ISubuserCourseRequest, ISubuserRequest } from 'src/app/models/Subuser.model'
import { NotificationService } from 'src/app/services/notification.service'
import { SubuserService } from 'src/app/services/subuser.service'
import { LocalStorageService } from '../../services/localStorage'
import { AddCourseOpenDialog, SetCurrentCourse } from '../actions/courses.actions'
import {
  AddCourseToSubuser,
  AddCourseToSubuserSuccess,
  AddSubuser,
  AddSubuserFailure,
  AddSubuserSuccess,
  DeleteCourseFromSubuser,
  DeleteCourseFromSubuserFailure,
  DeleteCourseFromSubuserSuccess,
  ESubuserActions,
  GetAllSubuser,
  GetAllSubuserCourses,
  GetAllSubuserCoursesFailure,
  GetAllSubuserCoursesSuccess,
  GetAllSubuserSuccess,
  GetSubuser,
  GetSubuserReport,
  GetSubuserReportSuccess,
  GetSubuserSuccess,
  RemoveSubuser,
  RemoveSubuserStatistic,
  RemoveSubuserStatisticSuccess,
  RemoveSubuserSuccess,
  SelectSubuser,
  SetCurrentUnit,
  SetCurrentUnitSuccess,
  SetSubuserUnlockLessons,
  SetSubuserUnlockLessonsSuccess,
  UpdateSubuser,
  UpdateSubuserAvatar,
  UpdateSubuserAvatarSuccess,
  UpdateSubuserSuccess,
} from '../actions/subuser.actions'
import { GetUser } from '../actions/user.actions'
import { selectSubuserState } from '../selectors/subuser.selectors'
import { IAppState } from '../state/app.state'

@Injectable()
export class SubuserEffects {
  getSubuser$: Observable<GetSubuserSuccess> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetSubuser>(ESubuserActions.GetSubuser),
      map((action: GetSubuser) => action.payload),
      exhaustMap((payload: number) => this._subuserService.getSubuser(payload)),
      exhaustMap((subuser: ISubuser) => of(new GetSubuserSuccess(subuser))),
      catchError((err, caught) => {
        const { error } = err
        this._notificationService.showNotificationError(error.clientMessage, 2)
        return caught
      })
    )
  )

  getAllSubuser$: Observable<GetAllSubuserSuccess> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetAllSubuser>(ESubuserActions.GetAllSubuser),
      exhaustMap(() => this._subuserService.getAllSubuser()),
      exhaustMap((subuser: Array<ISubuser>) => of(new GetAllSubuserSuccess(subuser))),
      catchError((err, stream) => {
        const { error } = err
        this._notificationService.showNotificationError(error.clientMessage, 2)
        return stream
      })
    )
  )

  selectSubuserInit$ = createEffect(() =>
    this._actions$.pipe(
      ofType<GetAllSubuserSuccess>(ESubuserActions.GetAllSubuserSuccess),
      withLatestFrom(this._store.pipe(select(selectSubuserState))),
      filter(([action, subuserState]) => !subuserState.currentSubuser),
      map(([action, subuserState]) => subuserState.subusers),
      switchMap((subuser) => {
        // if (subuser.length > 1) return of(new SelectSubuserOpenDialog());
        return of(new SelectSubuser(subuser[0]))
        // return of(new AddSubuserOpenDialog());
      }),
      catchError((err, stream) => {
        const { error } = err
        this._notificationService.showNotificationError(error.clientMessage, 2)
        return stream
      })
    )
  )

  addSubuser$: Observable<AddSubuserSuccess | AddSubuserFailure> = createEffect(() =>
    this._actions$.pipe(
      ofType<AddSubuser>(ESubuserActions.AddSubuser),
      map((action: AddSubuser) => action.payload),
      switchMap((payload: ISubuserRequest) => this._subuserService.createSubuser(payload)),
      exhaustMap((subuser: ISubuser) => of(new AddSubuserSuccess(subuser))),
      // catchError((error) => of(new AddSubuserFailure(error))),
      catchError((error) => {
        const { err } = error
        this._notificationService.showNotificationError(err.clientMessage, 2)
        return of(new AddSubuserFailure(error))
      })
    )
  )

  deleteCourse$: Observable<DeleteCourseFromSubuserSuccess | DeleteCourseFromSubuserFailure> = createEffect(() =>
    this._actions$.pipe(
      ofType<DeleteCourseFromSubuser>(ESubuserActions.DeleteCourseFromSubuser),
      map((action: DeleteCourseFromSubuser) => action.payload),
      switchMap((payload: ISubuserCourseRequest) => this._subuserService.deleteCourseFromSubuser(payload)),
      switchMap((deletedCourse: any) => {
        return of(new DeleteCourseFromSubuserSuccess(deletedCourse))
      }),
      // catchError((err, caught) => caught),
      catchError((err, caught) => {
        const { error } = err
        this._notificationService.showNotificationError(error.clientMessage, 2)
        return caught
      })
    )
  )

  removeSubuser$: Observable<RemoveSubuserSuccess | GetAllSubuser> = createEffect(() =>
    this._actions$.pipe(
      ofType<RemoveSubuser>(ESubuserActions.RemoveSubuser),
      map((action: RemoveSubuser) => action.payload),
      switchMap((payload: string | number) => this._subuserService.removeSubuser(payload)),
      switchMap((removedSubuser: any) => {
        return [new RemoveSubuserSuccess(removedSubuser), new GetAllSubuser()]
      }),
      catchError((err, stream) => {
        const { error } = err
        this._notificationService.showNotificationError(error.clientMessage, 2)
        return stream
      })
    )
  )

  removeSubuserStatistic$: Observable<RemoveSubuserStatisticSuccess> = createEffect(() =>
    this._actions$.pipe(
      ofType<RemoveSubuserStatistic>(ESubuserActions.RemoveSubuserStatistic),
      map((action: RemoveSubuserStatistic) => action.payload),
      switchMap((payload: any) => this._subuserService.removeSubuserStatistic(payload)),
      switchMap((removedSubuserStatictic: any) => {
        return of(new RemoveSubuserStatisticSuccess(removedSubuserStatictic))
      }),
      catchError((err, stream) => {
        const { error } = err
        this._notificationService.showNotificationError(error.clientMessage, 2)
        return stream
      })
    )
  )

  updateSubuser$: Observable<UpdateSubuserSuccess> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateSubuser>(ESubuserActions.UpdateSubuser),
      map((action: UpdateSubuser) => action.payload),
      switchMap((payload: ISubuser) => this._subuserService.updateSubuser(payload)),
      exhaustMap((updatedSubuser: any) => {
        this._store.dispatch(new GetAllSubuser())
        return of(new UpdateSubuserSuccess(updatedSubuser))
      }),
      catchError((err, stream) => {
        const { error } = err
        this._notificationService.showNotificationError(error.clientMessage, 2)
        return stream
      })
    )
  )

  setCurrentUnit$: Observable<SetCurrentUnitSuccess> = createEffect(() =>
    this._actions$.pipe(
      ofType<SetCurrentUnit>(ESubuserActions.SetCurrentUnit),
      map((action: SetCurrentUnit) => action.payload),
      switchMap((payload: any) => this._subuserService.setCurrentUnit(payload)),
      switchMap((updatedSubuser: ISubuser) => {
        this._store.dispatch(new SelectSubuser(updatedSubuser))
        return of(new SetCurrentUnitSuccess(updatedSubuser))
      }),
      catchError((err, stream) => {
        const { error } = err
        this._notificationService.showNotificationError(error.clientMessage, 2)
        return stream
      })
    )
  )

  updateSubuserAvatar$ = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdateSubuserAvatar>(ESubuserActions.UpdateSubuserAvatar),
      map((action: UpdateSubuserAvatar) => action.payload),
      switchMap((payload: IThumbnail) => this._subuserService.updateSubuserAvatar(payload)),
      switchMap((updatedSubuser: any) => {
        return [new GetAllSubuser(), new GetSubuser(updatedSubuser.subuserId), new UpdateSubuserAvatarSuccess(updatedSubuser)]
      }),
      catchError((err, stream) => {
        const { error } = err
        this._notificationService.showNotificationError(error.clientMessage, 2)
        return stream
      })
    )
  )

  getSubuserReport$: Observable<GetSubuserReportSuccess> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetSubuserReport>(ESubuserActions.GetSubuserReport),
      map((action: GetSubuserReport) => action.payload),
      exhaustMap((payload: any) => this._subuserService.getSubuserReport(payload)),
      exhaustMap((subuserReport: ISubuser) => of(new GetSubuserReportSuccess(subuserReport))),
      catchError((err, stream) => {
        const { error } = err
        this._notificationService.showNotificationError(error.clientMessage, 2)
        return stream
      })
    )
  )

  setUnlockLessons$: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<SetSubuserUnlockLessons>(ESubuserActions.SetSubuserUnlockLessons),
      map((action: SetSubuserUnlockLessons) => action.payload),
      switchMap((request: any) => this._subuserService.unlockLessons(request)),
      switchMap((subuser: any) => {
        this._notificationService.showNotification(`Lessons ${subuser.lessonMode ? 'unlocked ' : 'locked '}`, 2)
        this.localStorageService.setItem('currentSubuser', JSON.stringify(subuser))
        this._store.dispatch(new SelectSubuser(subuser))
        this._store.dispatch(new GetUser())
        return [new SetSubuserUnlockLessonsSuccess(subuser)]
      }),
      catchError((err, stream) => {
        const { error } = err
        this._notificationService.showNotificationError(error.clientMessage, 2)
        return stream
      })
    )
  )

  addCourse$: Observable<AddCourseToSubuserSuccess> = createEffect(() =>
    this._actions$.pipe(
      ofType<AddCourseToSubuser>(ESubuserActions.AddCourseToSubuser),
      map((action: AddCourseToSubuser) => action.payload),
      switchMap((payload: ISubuserCourseRequest) => this._subuserService.addCourseToSubuser(payload)),
      switchMap((course: any) => of(new AddCourseToSubuserSuccess(course))),
      catchError((err, stream) => {
        const { error } = err
        this._notificationService.showNotificationError(error.clientMessage, 2)
        return stream
      })
    )
  )

  selectSubuser$ = createEffect(() =>
    this._actions$.pipe(
      ofType<SelectSubuser>(ESubuserActions.SelectSubuser),
      map((action) => action.payload),
      switchMap((subuser: ISubuser) => {
        if (subuser && subuser.courses.length > 0) {
          const currentCourse = this.localStorageService.getItem(`${subuser.id}`)
          if (currentCourse) {
            return of(new SetCurrentCourse(JSON.parse(currentCourse)))
          }
          this.localStorageService.setItem(`${subuser.id}`, JSON.stringify(subuser.courses[0]))
          const defoltCourse = this.localStorageService.getItem(`${subuser.id}`)
          return of(new SetCurrentCourse(JSON.parse(defoltCourse)))
        }
        return of(new AddCourseOpenDialog(subuser))
      }),
      catchError((err, stream) => {
        const { error } = err
        if (error && error.clientMessage) {
          this._notificationService.showNotificationError(error.clientMessage, 2)
        }
        return stream
      })
    )
  )

  getAllSubuserCourses$: Observable<GetAllSubuserCoursesSuccess | GetAllSubuserCoursesFailure> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetAllSubuserCourses>(ESubuserActions.GetAllSubuserCourses),
      mergeMap(() => {
        return this._subuserService.getAllSubuserCourses().pipe(
          map((courses: any) => new GetAllSubuserCoursesSuccess(courses)),
          catchError((error) => {
            this._notificationService.showNotificationError(error.clientMessage, 2)
            return of(new GetAllSubuserCoursesFailure(error))
          })
        )
      })
    )
  )

  constructor(private _actions$: Actions, private _subuserService: SubuserService, private _store: Store<IAppState>, private _notificationService: NotificationService, private localStorageService: LocalStorageService) {}
}
