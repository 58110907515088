import { Component, ViewChild, OnInit, OnDestroy, Inject } from '@angular/core'
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators } from '@angular/forms'
import { SignUp, EAuthActions, SignUpSuccess } from 'src/app/store/actions/auth.actions'
import { IAppState } from 'src/app/store/state/app.state'
import { Store, select } from '@ngrx/store'
import { Actions, ofType } from '@ngrx/effects'
import { IUser } from 'src/app/models/User.model'
import { DOCUMENT } from '@angular/common'
import { AddSubuser, AddSubuserSuccess, ESubuserActions } from 'src/app/store/actions/subuser.actions'
import { ISubuserRequest } from 'src/app/models/Subuser.model'
import { ActivatedRoute, Router } from '@angular/router'
import { MatStepper } from '@angular/material/stepper'
import { ICourse } from 'src/app/models/Course.model'
import { GetAllCourses } from 'src/app/store/actions/courses.actions'
import { selectAllCourses } from 'src/app/store/selectors/courses.selectors'
import { DeviceDetectorService } from 'ngx-device-detector'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { filter, takeUntil } from 'rxjs/operators'
import { IThumbnail } from 'src/app/models/Avatar.model'
import { SocialAuthService, GoogleLoginProvider, SocialUser } from 'angularx-social-login'

import { TranslateService, LangChangeEvent } from '@ngx-translate/core'
// import { CookiesComponent } from 'src/app/shared/components/cookies/cookies.component';
import { CookieService } from 'ngx-cookie-service'
import { Subject, Subscription } from 'rxjs'
import { Meta, Title } from '@angular/platform-browser'
import { GetIpUserService } from 'src/app/services/getIp.service'
import { AvatarSelectPopupComponent } from 'src/app/shared/popups/Subuser-Dialogs/avatar-select/avatar-select.component'
import { LocalStorageService } from '../../../services/localStorage'
import { FormatTextService } from '../../../services/formatText.service'
import { LocalizHelperService } from 'src/app/services/localizHelper.service'
import { SEOService } from 'src/app/services/seo.service'
import { PasswordValidator } from 'src/app/helpers/validators/password.validator'
import { environment } from 'src/environments/environment'

@Component({
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class AuthRegistrationComponent implements OnInit, OnDestroy {
  public formSent = false
  public timer

  public monthlyPlanId = environment.monthlyPlanId
  public annualPlanId = environment.annualPlanId

  @ViewChild('matStepper') stepper: MatStepper
  hidePassword = true
  private unsubscribe$ = new Subject()

  public courses$ = this.store.pipe(takeUntil(this.unsubscribe$), select(selectAllCourses))

  public deviceInfo = this.deviceService.getDeviceInfo()

  public signUpFormGroup: UntypedFormGroup

  public selectedUserAvatar: IThumbnail
  public selectedSubuserAvatar: IThumbnail

  public addSubuserFormGroup: UntypedFormGroup

  private user: SocialUser
  private loggedIn: boolean
  public currentLang = 'en' // this.localStorageService.getItem('currentLan');
  // public invintationCode = 'supersecretcode';
  public subsriptions: Subscription = new Subscription()

  public title = 'Dinolingo - signup, subscribe'
  public description = 'Create your free Dinolingo account today to access thousands of learning activities available in 50 languages'

  public country

  public currentLan: string

  public lURL

  constructor(
    private updates$: Actions,
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private store: Store<IAppState>,
    private deviceService: DeviceDetectorService,
    private router: Router,
    private authService: SocialAuthService,
    public translate: TranslateService,
    public activeRouter: ActivatedRoute,
    private titleService: Title,
    private meta: Meta,
    private _getIpUserService: GetIpUserService,
    private localStorageService: LocalStorageService,
    private textService: FormatTextService,
    @Inject(DOCUMENT) private dom,
    private _lhServise: LocalizHelperService,
    private _seoService: SEOService,
    private passwordValidator: PasswordValidator
  ) {
    this.store.dispatch(new GetAllCourses())

    this.updates$.pipe(ofType<SignUpSuccess>(EAuthActions.SignUpSuccess)).subscribe(() => {
      this.stepper.next()
    })
    this.updates$.pipe(ofType<AddSubuserSuccess>(ESubuserActions.AddSubuserSuccess)).subscribe(() => {
      this.router.navigate(['/profile/parents/subscriptions/stripe'])
    })
  }

  public langParam = this.activeRouter.parent.snapshot.params && this.activeRouter.parent.snapshot.params.lang ? this.activeRouter.parent.snapshot.params.lang : ''

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
    this.subsriptions.unsubscribe()
    clearTimeout(this.timer)
  }

  public selectedCourse = ''
  public selectedAge = ''

  ngOnInit() {
    this.subsriptions.add(
      this._getIpUserService.getUserCountry().subscribe((res) => {
        this.country = res
      })
    )

    this.subsriptions.add(
      this.authService.authState.subscribe((user) => {
        this.user = user
        this.loggedIn = user != null
      })
    )

    const { meta } = this.activeRouter.snapshot.data

    this._lhServise.setURLLang(this.langParam)

    this.currentLan = this._lhServise.getLang()

    this.lURL = this._lhServise.getURLLang()

    const localMeta = meta[this.currentLan] == undefined ? meta['en'] : meta[this.currentLan]

    this._seoService.updateTitle(localMeta.title)
    this._seoService.updateDescription(localMeta.description)
    this._seoService.createCanonicalLink(localMeta.canonical)
    this._lhServise.createLangMetaLinks()

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.lURL = this._lhServise.getURLLang()
      this.currentLan = event.lang
      this._seoService.updateTitle(localMeta.title)
      this._seoService.updateDescription(localMeta.description)
      this._seoService.createCanonicalLink(localMeta.canonical)
    })

    this.signUpFormGroup = this.fb.group({
      avatarId: [''],
      country: [''],
      email: ['', [Validators.email, Validators.required]],
      password: ['', [Validators.maxLength(20), Validators.minLength(8), Validators.required, this.passwordValidator.passwordValidator]],
      pin: ['', [Validators.minLength(1), Validators.maxLength(4)]],
      os: ['', Validators.required],
      browser: ['', Validators.required],
      userAgent: ['', Validators.required],
      courseId: ['', [Validators.required]],
      ageRange: [''],
      nickname: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(10)]],
      planId: [this.monthlyPlanId, [Validators.required]],
      // secretCode: ['', [Validators.required]],
    })

    this.addSubuserFormGroup = this.fb.group({
      avatarId: [''],
      courseId: ['', [Validators.required]],
      nickname: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(10)]],
      ageRange: ['', [Validators.required]],
      pin: ['', [Validators.minLength(1), Validators.maxLength(4)]],
    })
    this.signUpFormGroup.patchValue({
      os: this.deviceInfo.os,
      browser: this.deviceInfo.browser,
      userAgent: this.deviceInfo.userAgent,
    })
  }

  public formatLangName(lang) {
    return this.textService.getFullLangName(lang)
  }

  public formatCourseName(course) {
    return this.textService.formatLangName(course)
  }

  /**
   * Sign in with google
   */
  public signInWithGoogle(): void {
    if (this.user && this.loggedIn) {
      const request = {
        email: this.user.email,
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        googleId: this.user.id,
        os: this.deviceInfo.os,
        browser: this.deviceInfo.browser,
        userAgent: this.deviceInfo.userAgent,
        courseId: Number(this.selectedCourse),
        ageRange: this.selectedAge,
      }

      return this.store.dispatch(new SignUp(request))
    }

    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID)

    this.subsriptions.add(
      this.authService.authState.subscribe((user) => {
        if (user) {
          const request = {
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            googleId: user.id,
            os: this.deviceInfo.os,
            browser: this.deviceInfo.browser,
            userAgent: this.deviceInfo.userAgent,
            courseId: this.selectedCourse,
            ageRange: this.selectedAge,
          }

          this.store.dispatch(new SignUp(request))
        }
      })
    )
  }

  /**
   * Select language from list of languages
   * @firstStep
   * And then, go to next step of stepper
   * @param {string} language
   * language - language for learning
   */
  public selectLanguage(language: ICourse): void {
    this.addSubuserFormGroup.patchValue({ courseId: language.id })
    this.stepper.next()
  }

  /**
   * Create new user on second step of stepper
   * @secondStep
   * And then, go to next step of stepper
   * @param {formGroup} request
   * request - all values from SignUpFormGroup
   */
  registerUser(requestForm: UntypedFormGroup) {
    this.formSent = true
    this.timer = setTimeout(() => {
      this.formSent = false
    }, 2000)

    this.signUpFormGroup.patchValue({
      country: this.country ? this.country.country : 'USA',
    })

    const request: IUser = requestForm.value

    this.store.dispatch(new SignUp(request))
  }

  /**
   * Create new subuser for user on third step of stepper
   * @thirdStep
   * And then, go to lessons
   * @param {formGroup} request
   * request - all values from addSubuserFormGroup
   */
  registerSubuser(requestForm: UntypedFormGroup) {
    const request: ISubuserRequest = requestForm.value

    this.store.dispatch(new AddSubuser(request))
  }

  /**
   * Open select avatar dialog for user
   * @secondStep
   * And then, patch formGroup value
   * @param {IThumbnail} thumbnail
   */
  openSelectParentAvatarPopup() {
    const dialog = this.dialog.open(AvatarSelectPopupComponent, {
      hasBackdrop: true,
      width: '32rem',
      height: '32rem',
    })

    dialog
      .afterClosed()
      .pipe(filter((avatar) => avatar !== null))
      .subscribe((avatar) => {
        this.selectedUserAvatar = avatar
        this.signUpFormGroup.patchValue({
          avatarId: avatar.id,
        })
      })
  }

  /**
   * Open select avatar dialog for subuser
   * @secondStep
   * And then, patch formGroup value
   * @param {IThumbnail} thumbnail
   */
  openSelectSubuserAvatarPopup() {
    const dialog = this.dialog.open(AvatarSelectPopupComponent, {
      hasBackdrop: true,
      width: '32rem',
      height: '32rem',
    })

    dialog
      .afterClosed()
      .pipe(filter((avatar) => avatar != null))
      .subscribe((avatar) => {
        this.selectedSubuserAvatar = avatar

        this.addSubuserFormGroup.patchValue({
          avatarId: avatar.id,
        })
      })
  }

  public isLottie(url) {
    if (url.includes('.json')) {
      return true
    }
    return false
  }

  public getURLLangPart() {
    let p = this.lURL
    return p ? `/${p}` : ''
  }
}
