import * as FileSaver from 'file-saver'

const isIpad = (): boolean => {
  return navigator.userAgent.match(/Safari/i) !== null
}

const openPdfLink = (pdf) => {
  const linkSource = pdf.split(',')

  const byteCharacters = atob(linkSource[1])
  const byteNumbers = new Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }
  const byteArray = new Uint8Array(byteNumbers)
  const file = new Blob([byteArray], { type: 'application/pdf;base64' })
  const fileURL = URL.createObjectURL(file)
  window.open(fileURL)
}

const downloadPdfLink = (pdf, name, path = false, isIpad = false) => {
  if (isIpad) {
    const linkSource = pdf.split(',')

    const byteCharacters = atob(linkSource[1])
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    const blob = new Blob([byteArray], { type: 'application/pdf;base64' })
    const pdfUrl = URL.createObjectURL(blob)

    FileSaver.saveAs(blob, name)
  } else {
    const linkSource = pdf
    const downloadLink = document.createElement('a')

    const fileName = path ? `${name}-Test.pdf` : `${name}`

    downloadLink.href = linkSource
    downloadLink.download = fileName
    downloadLink.click()
    downloadLink.remove()
  }
}

export { openPdfLink, downloadPdfLink, isIpad }
