import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ArrangeTextGameComponent } from './arrange-text.component'
import { FormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatIconModule } from '@angular/material/icon'
import { MatRippleModule } from '@angular/material/core'
import { TranslateModule } from '@ngx-translate/core'
import { ReportProblemModule } from "src/app/shared/components/report-problem/report-problem.module";

@NgModule({
    declarations: [ArrangeTextGameComponent],
    exports: [ArrangeTextGameComponent],
    imports: [CommonModule, FormsModule, RouterModule, MatButtonModule, MatIconModule, MatRippleModule, TranslateModule, ReportProblemModule]
})
export class ArrangeTextGameModule {}
