<section
  [ngClass]="{
    test: test
  }"
  class="game audioToPicture"
>
  <div class="game-help-text">
    <button mat-icon-button color="primary" (click)="audiohelpPlay()">
      <audio #audiohelp>
        <source src="/assets/images/sounds/instructions/{{ currentlang }}/ATI.mp3" type="audio/mp3" />
      </audio>
      <mat-icon color="primary"> volume_down </mat-icon>
    </button>
    {{ 'GAME.PICTURE_TO_AUDIO.HELP_TEXT' | translate }}
  </div>
  <ng-container *ngIf="game">
    <img class="trees left" src="/assets/images/games/audio-to-picture/gameBackground.svg" alt=" " />
    <img class="trees right" src="/assets/images/games/audio-to-picture/gameBackground2.svg" alt=" " />
    <img class="trees center" src="/assets/images/games/audio-to-picture/gameBackground3.svg" alt=" " />
  </ng-container>
  <ng-container *ngIf="test">
    <img class="trees left" src="/assets/images/tests/testBackground.svg" alt=" " />
    <img class="trees right" src="/assets/images/tests/testBackground2.svg" alt=" " />
    <img class="trees center" src="/assets/images/tests/testBackground3.svg" alt=" " />
  </ng-container>
  <article class="contentWrapper">
    <div class="card">
      <div class="image" [ngStyle]="{ 'background-color': cardColors[0] }">
        <img [src]="altImage[0]" alt="{{ question.text }}" *ngIf="imgType != 'json'" />
        <ng-container *ngIf="imgType == 'json'">
          <ng-lottie *ngFor="let altm of altImage" class="lottie_p" style="width: 100%; height: 100%" [options]="{ path: altm }"> </ng-lottie>
        </ng-container>
      </div>
      <span class="title" *ngIf="game">{{ question.text }}</span>
    </div>
    <div class="audios">
      <div class="audio" *ngFor="let answer of altAnswers" (click)="selectAnswer(answer)">
        <ng-container *ngIf="checked && answer.correct">
          <button mat-fab color="primary" class="correct selected bottom"></button>
        </ng-container>
        <ng-container *ngIf="selectedAnswer?.id === answer.id">
          <button
            mat-fab
            color="primary"
            [ngClass]="{
              correct: checked && checkRightAnswer,
              incorrect: checked && !checkRightAnswer
            }"
            class="selected bottom"
          ></button>
        </ng-container>

        <img src="/assets/images/games/picture-to-audio/audio.svg" alt="play-back-sound" />
      </div>
    </div>
  </article>
  <ng-container>
    <div *ngIf="checked && checkRightAnswer" class="anim stars">
      <img src="/assets/images/anim/check_stars.png" alt="check" />
    </div>
    <div class="continue" [ngClass]="{ 'continue-visible': selectedAnswer && !checked }" *ngIf="!checked" (click)="checkAnswer()" mat-button>
      <img src="/assets/images/icons/check.svg" alt="check" />
      <span>{{ 'BUTTONS.CHECK' | translate }}</span>
    </div>
    <div appSounds class="continue" [ngClass]="{ 'continue-visible': checked }" *ngIf="checked" (click)="setAnswer(selectedAnswer)" mat-button>
      <span>{{ 'BUTTONS.CONTINUE' | translate }}</span>
    </div>
  </ng-container>

  <app-report-problem (click)="openReportPopup(question)"></app-report-problem>
</section>
