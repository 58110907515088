import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table'
import { MatSort } from '@angular/material/sort'
import { Subject } from 'rxjs'
import { IStudent } from '../../../../models/Student.model'
import { EditStudentDialogComponent } from '../../../../shared/popups/Student-Dialogs/edit-student/edit-student.component'
import { RemoveStudent } from '../../../../store/actions/students.actions'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { Store, select } from '@ngrx/store'
import { IAppState } from '../../../../store/state/app.state'
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar'
import { ConfirmationPopupComponent } from '../../../../shared/popups/Control-Dialogs/confirmation/confirmation.component'
import { selectStudentsForClassroom } from '../../../../store/selectors/students.selectors'
import { filter, takeUntil } from 'rxjs/operators'
import { StudentAssignmentsDialogComponent } from '../../../../shared/popups/Assignment-Dialogs/student-assignments/student-assignments.component'

@Component({
  selector: 'app-list-students',
  templateUrl: './list-students.component.html',
  styleUrls: ['./list-students.component.scss'],
})
export class ListStudentsComponent implements OnInit, OnDestroy {
  @Input() schoolId: number
  @Input() classroomId: number
  // TODO: ADD TRANSLATION
  constructor(private _dialog: MatDialog, private _snackBar: MatSnackBar, private _store: Store<IAppState>) {}

  private unsubscribe$ = new Subject()

  @ViewChild(MatSort) sort: MatSort

  public displayedColumns: string[] = ['index', 'subuser.name', 'studentCode', 'incompleteAssignments', 'actions']

  public dataSource: MatTableDataSource<IStudent>

  public data = false

  ngOnInit() {
    this._store
      .pipe(
        takeUntil(this.unsubscribe$),
        select(selectStudentsForClassroom, { classroomId: this.classroomId }),
        filter((students) => !!students.length)
      )
      .subscribe((students: Array<IStudent>) => {
        this.data = true
        this.dataSource = new MatTableDataSource<IStudent>(students as IStudent[])
        this.dataSource.sortingDataAccessor = (item, property) => {
          if (property.includes('.')) return property.split('.').reduce((o, i) => o[i], item)
          return item[property]
        }
        this.dataSource.sort = this.sort
      })
  }

  ngOnDestroy() {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  editStudent(student) {
    const dialogRef = this._dialog.open(EditStudentDialogComponent, {
      disableClose: false,
      hasBackdrop: true,
      width: '28rem',
      data: student,
    })
    dialogRef.afterClosed().subscribe((result) => {
      if (!result) return

      this._snackBar.open('Student successfully updated', 'cancel', {
        duration: 2000,
      })
    })
  }

  removeStudent(student) {
    const dialog = this._dialog.open(ConfirmationPopupComponent, {
      panelClass: 'medium-adaptive-popup',
      closeOnNavigation: true,
      hasBackdrop: true,
      data: {
        question: 'STUDENT.REMOVE_CONFIRM',
        extraConfirmation: true,
      },
    })

    dialog.afterClosed().subscribe((answer) => {
      if (answer === true) {
        this._store.dispatch(new RemoveStudent(student))

        this._snackBar.open('Student successfully deleted', 'cancel', {
          duration: 2000,
        })
      }
    })
  }

  openAssignmentsDialog(student) {
    this._dialog.open(StudentAssignmentsDialogComponent, {
      panelClass: 'medium-adaptive-popup',
      closeOnNavigation: true,
      hasBackdrop: true,
      data: {
        inDashboard: true,
        schoolId: this.schoolId,
        classroomId: this.classroomId,
        student,
      },
    })
  }
}
