<section [ngClass]="{ test: test }" class="game whatComesAfter">
  <div class="game-help-text">{{ 'GAME.WHAT_COMES_AFTER.HELP_TEXT' | translate }}</div>
  <ng-container *ngIf="game">
    <img class="trees left" src="/assets/images/games/picture-to-audio/gameBackground.svg" alt=" " />
    <img class="trees right" src="/assets/images/games/picture-to-audio/gameBackground2.svg" alt=" " />
    <img class="trees center" src="/assets/images/games/picture-to-audio/gameBackground3.svg" alt=" " />
  </ng-container>
  <ng-container *ngIf="test">
    <img class="trees left" src="/assets/images/tests/testBackground.svg" alt=" " />
    <img class="trees right" src="/assets/images/tests/testBackground2.svg" alt=" " />
    <img class="trees center" src="/assets/images/tests/testBackground3.svg" alt=" " />
  </ng-container>

  <article class="contentWrapper">
    <div class="questionBlock">
      <img class="audio" (click)="playAudio(question.audioSrc)" src="/assets/images/games/picture-to-audio/audio.svg" alt="play-back-sound" />
      <img class="questionImage" [src]="question.pictureSrc" alt="{{ question.text }}" />
    </div>
    <div class="cards">
      <div (click)="selectAnswer(answer); playAudio(answer.audioSrc)" *ngFor="let answer of altAnswers; let index = index" class="card">
        <ng-container *ngIf="game && checked && answer.correct">
          <button mat-fab color="primary" class="correct selected bottom"></button>
        </ng-container>
        <ng-container *ngIf="selectedAnswer?.id === answer.id && game">
          <button
            mat-fab
            color="primary"
            [ngClass]="{
              correct: checked,
              incorrect: checked && !checkRightAnswer
            }"
            class="selected"
          ></button>
        </ng-container>

        <ng-container *ngIf="selectedAnswer?.id === answer.id && test">
          <button mat-fab color="primary" class="selected"></button>
        </ng-container>

        <span *ngIf="game" class="title">{{ answer.text }}</span>
      </div>
    </div>
  </article>
  <div *ngIf="checked && checkRightAnswer && game" class="anim stars">
    <img src="/assets/images/anim/check_stars.png" alt="check" />
  </div>
  <ng-container *ngIf="game">
    <div class="continue" [ngClass]="{ 'continue-visible': selectedAnswer && !checked }" *ngIf="!checked" (click)="checkAnswer()" mat-button>
      <img src="/assets/images/icons/check.svg" alt="check" />
      <span>{{ 'BUTTONS.CHECK' | translate }}</span>
    </div>
    <div class="continue" [ngClass]="{ 'continue-visible': checked }" *ngIf="checked" (click)="setAnswer(selectedAnswer)" mat-button>
      <span>{{ 'BUTTONS.CONTINUE' | translate }}</span>
    </div>
  </ng-container>
  <ng-container *ngIf="test">
    <div class="continue" [ngClass]="{ 'continue-visible': selectedAnswer && !checked }" (click)="setAnswerTest(selectedAnswer)" mat-button>
      <span>{{ 'BUTTONS.CONTINUE' | translate }}</span>
    </div>
  </ng-container>

  <app-report-problem (click)="openReportPopup(question)"></app-report-problem>
</section>
