import { Component, OnInit } from '@angular/core'
import { LangChangeEvent, TranslateService } from '@ngx-translate/core'
// import { LocalStorageService } from '../../../../services/localStorage';
import { FormatTextService } from '../../../../services/formatText.service'
import { LocalizHelperService } from 'src/app/services/localizHelper.service'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  public lURL: string
  constructor(
    public translate: TranslateService,
    // private localStorageService: LocalStorageService,
    private textService: FormatTextService,
    private _lhServise: LocalizHelperService
  ) {}
  ngOnInit() {
    this.lURL = this._lhServise.getURLLang()

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.lURL = this._lhServise.getURLLang()
    })
  }

  public formatLangName(lang) {
    return this.textService.getFullLangName(lang)
  }

  selectLocalization(lang) {
    this.translate.use(lang)
    this._lhServise.setLang(lang)
  }

  public getURLLangPart() {
    let p = this.lURL
    return p ? `/${p}` : ''
  }

  public checkReplacement(text, replacement) {
    if (text.includes('%s')) {
      return text.replace('%s', replacement)
    } else {
      return `${replacement} ${text}`
    }
  }
}
