import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, OnChanges, ElementRef, ViewChild } from '@angular/core'
import { of } from 'rxjs'
import { delay } from 'rxjs/operators'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { AudioService } from 'src/app/services/audio.service'
import { TranslateService } from '@ngx-translate/core'
import { RandomizeService } from 'src/app/services/randomize.service'
import { DefaultPopupSettings } from 'src/app/models/Dialog.model'
import { ReportProblemPopupComponent } from '../../popups/Report-Dialogs/report-problem/report-problem.component'
import { LocalizHelperService } from 'src/app/services/localizHelper.service'

@Component({
  selector: 'memory-cards',
  templateUrl: './memory-cards.component.html',
  styleUrls: ['./memory-cards.component.scss'],
})
export class MemoryCardsGameComponent implements OnInit, OnChanges, OnDestroy {
  @Input() question: any
  @Input() test: boolean
  @Input() game: boolean
  @Input() comparison: boolean
  @Output() success = new EventEmitter()
  @Output() failed = new EventEmitter()

  @ViewChild('audiohelp') audiohelp: ElementRef

  public currentlang

  constructor(private _dialog: MatDialog, public translate: TranslateService, private _RandomizeService: RandomizeService, private _lhServise: LocalizHelperService, private audioService: AudioService) {}

  public rotatedCards = []

  public cards = []

  public cardColors = []

  public delayTimer

  /* Clear selected answer and checked answer when inputs are changed */
  ngOnChanges() {
    this.rotatedCards = []
  }

  ngOnDestroy() {
    this.audioService.stopAllAudio()
    clearTimeout(this.delayTimer)
  }

  ngOnInit() {
    this.currentlang = this._lhServise.getLangForced()

    for (let i = 0; i < this.question.answers.length; i++) {
      this.audioService.preloadAudio(this.question.answers[i].audioSrc, this.question.answers[i].audioSrc)

      this.cards.push(this.copyObject(this.question.answers[i]))
      this.cards[i].pictureSrc = this._RandomizeService.getRandomAnswerImage(this.cards[i])
      this.cards[i].imgType = this.cards[i].pictureSrc.includes('.json') ? 'json' : ''
    }
    for (let i = 0; i < this.question.answers.length; i++) {
      this.cards.push(this.copyObject(this.cards[i]))
    }
    this.cards = this._RandomizeService.shuffle(this.cards)

    this.cardColors = this._RandomizeService.getRandom12Colors()
  }

  public audiohelpPlay() {
    this.audiohelp.nativeElement.play()
  }

  public rotateCard(card, index) {
    if (card.checked || card.rotated) return
    if (this.rotatedCards.length === 2) return

    this.rotatedCards.push(card)
    this.audioService.playAudio(card.audioSrc, card.audioSrc, false)
    this.cards[index].rotated = true

    if (this.rotatedCards.length === 2) {
      const match = this.compareCards(this.rotatedCards)
      if (!match) return this.hideAll()
      this.hideTwo(card.id)
    }
  }

  public hideTwo(id) {
    var self = this
    this.delayTimer = setTimeout(function () {
      self.cards = self.cards.map((card) => {
        if (card.id === id) {
          const newCard = card
          newCard.checked = true
          return newCard
        }
        return card
      })
      self.rotatedCards = []
    }, 1000)
  }

  public hideTwoOld(id) {
    of(true)
      .pipe(delay(1000))
      .subscribe((res) => {
        this.cards = this.cards.map((card) => {
          if (card.id === id) {
            const newCard = card
            newCard.checked = true
            return newCard
          }
          return card
        })
        this.rotatedCards = []
      })
  }

  public hideAll() {
    var self = this
    this.delayTimer = setTimeout(function () {
      self.cards = self.cards.map((card) => {
        const newCard = card
        newCard.rotated = false
        return newCard
      })
      self.rotatedCards = []
    }, 1000)
  }

  public hideAllOld() {
    of(true)
      .pipe(delay(1000))
      .subscribe((res) => {
        this.cards = this.cards.map((card) => {
          const newCard = card
          newCard.rotated = false
          return newCard
        })
        this.rotatedCards = []
      })
  }

  public get isAnswered() {
    return this.cards.every((e) => e.checked)
  }

  public compareCards(array) {
    if (array[0].id === array[1].id) return true
    false
  }

  public copyObject(object: any) {
    const objCopy = {}
    let key

    for (key in object) {
      objCopy[key] = object[key]
    }
    return objCopy
  }

  public openReportPopup(question) {
    this._dialog.open(ReportProblemPopupComponent, new DefaultPopupSettings(question))
  }

  public playAnim(event: Event) {
    ;(<HTMLAudioElement>event.target).play()
  }

  public stopAnim(event: Event) {
    ;(<HTMLAudioElement>event.target).pause()
  }
}
