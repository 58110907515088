import { Component } from '@angular/core'
import { CookieService } from 'ngx-cookie-service'
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'

@Component({
  selector: 'app-message',
  templateUrl: 'app-message.component.html',
  styleUrls: ['app-message.component.scss'],
})
export class AppMessageComponent {
  constructor(public dialogRef: MatDialogRef<AppMessageComponent>, private _cookieService: CookieService) {}

  close() {
    this._cookieService.set('gotAppMessage', '1', 30, '/', 'dinolingo.com') // dinolingo.com localhost
    this.dialogRef.close()
  }
}
