import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { FormsModule } from '@angular/forms'
import { AudioToPictureGameComponent } from './audio-to-picture.component'
import { MatIconModule } from '@angular/material/icon'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatRippleModule } from '@angular/material/core'
import { TranslateModule } from '@ngx-translate/core'
import { SoundsModule } from 'src/app/helpers/directives/sounds-directive/sounds.module'
import { LottieModule } from 'ngx-lottie'
import { ReportProblemModule } from "src/app/shared/components/report-problem/report-problem.module"

@NgModule({
  imports: [CommonModule, FormsModule, RouterModule, MatButtonModule, MatIconModule, MatRippleModule, TranslateModule, SoundsModule, LottieModule, ReportProblemModule],
  declarations: [AudioToPictureGameComponent],
  exports: [AudioToPictureGameComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AudioToPictureGameModule {}
