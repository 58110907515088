<section
  [ngClass]="{
    test: test
  }"
  class="game memoryCards"
>
  <div class="game-help-text">
    <button mat-icon-button color="primary" (click)="audiohelpPlay()">
      <audio #audiohelp>
        <source src="/assets/images/sounds/instructions/{{ currentlang }}/MG.mp3" type="audio/mp3" />
      </audio>
      <mat-icon color="primary"> volume_down </mat-icon>
    </button>
    {{ 'GAME.MEMORY_CARDS.HELP_TEXT' | translate }}
  </div>
  <ng-container *ngIf="game">
    <img class="trees left" src="/assets/images/games/memory-cards/gameBackground.svg" alt=" " />
    <img class="trees right" src="/assets/images/games/memory-cards/gameBackground2.svg" alt=" " />
    <img class="trees center" src="/assets/images/games/memory-cards/gameBackground3.svg" alt=" " />
  </ng-container>
  <ng-container *ngIf="test">
    <img class="trees left" src="/assets/images/tests/testBackground.svg" alt=" " />
    <img class="trees right" src="/assets/images/tests/testBackground2.svg" alt=" " />
    <img class="trees center" src="/assets/images/tests/testBackground3.svg" alt=" " />
  </ng-container>
  <article class="contentWrapper">
    <div class="cards">
      <ng-container *ngFor="let card of cards; let even = even; let index = index">
        <div class="card" *ngIf="even" [ngClass]="{ flipped: card.rotated, checked: card.checked }" (click)="rotateCard(card, index)">
          <div class="face front" [ngStyle]="{ 'background-color': cardColors[index] }">
            <div class="image"></div>
          </div>
          <div class="face back" [ngStyle]="{ 'background-color': cardColors[index] }">
            <div class="image">
              <img [src]="card.pictureSrc" alt="{{ card.text }}" *ngIf="card.imgType != 'json'" />
              <ng-lottie *ngIf="card.imgType == 'json'" class="lottie_p" style="width: 100%; height: 100%" [options]="{ path: card.pictureSrc }"> </ng-lottie>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="cards">
      <ng-container *ngFor="let card of cards; let odd = odd; let index = index">
        <div class="card" *ngIf="odd" [ngClass]="{ flipped: card.rotated, checked: card.checked }" (click)="rotateCard(card, index)">
          <div class="face front" [ngStyle]="{ 'background-color': cardColors[index] }">
            <div class="image"></div>
          </div>
          <div class="face back" [ngStyle]="{ 'background-color': cardColors[index] }">
            <div class="image">
              <img [src]="card.pictureSrc" alt="{{ card.text }}" *ngIf="card.imgType != 'json'" />
              <ng-lottie *ngIf="card.imgType == 'json'" class="lottie_p" style="width: 100%; height: 100%" [options]="{ path: card.pictureSrc }"> </ng-lottie>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </article>
  <div appSounds (click)="success.emit(true)" class="continue" [ngClass]="{ 'continue-visible': isAnswered }" mat-button>
    <span>{{ 'BUTTONS.CONTINUE' | translate }}</span>
  </div>

  <app-report-problem (click)="openReportPopup(question)"></app-report-problem>
</section>
