import { Injectable } from '@angular/core'
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { Store, Action, select } from '@ngrx/store'
import { IAppState } from '../../store/state/app.state'
import { Actions, ofType } from '@ngrx/effects'
import { Observable } from 'rxjs'
import { take } from 'rxjs/operators'
import { GetLesson, GetLessonSuccess, ELessonsActions } from '../../store/actions/lessons.actions'
import { selectCurrentSubuser } from '../../store/selectors/subuser.selectors'
import { ISubuser } from '../../models/Subuser.model'
import { LocalStorageService } from '../../services/localStorage'

@Injectable()
export class LessonResolver implements Resolve<Action> {
  public subuser: ISubuser

  constructor(private store: Store<IAppState>, private action$: Actions, private localStorageService: LocalStorageService) {
    this.store.pipe(select(selectCurrentSubuser)).subscribe((subuser) => {
      if (subuser) {
        this.subuser = subuser
        this.localStorageService.setItem('currentSubuser', JSON.stringify(subuser))
      }
    })
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Action> {
    this.store.dispatch(new GetLesson({ lessonId: route.params.id, subuserId: this.subuser?.id }))
    return this.action$.pipe(ofType<GetLessonSuccess>(ELessonsActions.GetLessonSuccess), take(1))
  }
}
