<section [ngClass]="{ test: test }" class="game pictureToAudio">
  <div class="game-help-text">
    <button mat-icon-button color="primary">
      <audio #audiohelp>
        <source src="/assets/images/sounds/instructions/{{ currentlang }}/ITA.mp3" type="audio/mp3" />
      </audio>
      <mat-icon color="primary" (click)="audiohelpPlay()"> volume_down </mat-icon>
    </button>
    {{ 'GAME.AUDIO_TO_PICTURE.HELP_TEXT' | translate }}
  </div>
  <ng-container *ngIf="game">
    <img class="trees left" src="/assets/images/games/picture-to-audio/gameBackground.svg" alt=" " />
    <img class="trees right" src="/assets/images/games/picture-to-audio/gameBackground2.svg" alt=" " />
    <img class="trees center" src="/assets/images/games/picture-to-audio/gameBackground3.svg" alt=" " />
  </ng-container>
  <ng-container *ngIf="test">
    <img class="trees left" src="/assets/images/tests/testBackground.svg" alt=" " />
    <img class="trees right" src="/assets/images/tests/testBackground2.svg" alt=" " />
    <img class="trees center" src="/assets/images/tests/testBackground3.svg" alt=" " />
  </ng-container>

  <article class="contentWrapper">
    <img class="audio" (click)="playAudio(question.audioSrc)" src="/assets/images/games/picture-to-audio/audio.svg" alt="play-back-sound" />
    <div class="cards">
      <div (click)="selectAnswer(answer)" *ngFor="let answer of altAnswers; let index = index" class="card">
        <ng-container *ngIf="checked && answer.correct">
          <button mat-fab color="primary" class="correct selected bottom"></button>
        </ng-container>
        <ng-container *ngIf="selectedAnswer?.id === answer.id">
          <button
            mat-fab
            color="primary"
            [ngClass]="{
              correct: checked,
              incorrect: checked && !checkRightAnswer
            }"
            class="selected"
          ></button>
        </ng-container>

        <div class="image" [ngStyle]="{ 'background-color': cardColors[index] }">
          <img [src]="answer.pictureSrc" alt="{{ answer.text }}" *ngIf="answer.imgType != 'json'" />
          <lottie-player *ngIf="answer.imgType == 'json' && comparison" class="lottie_p" autoplay="true" mode="normal" (frame)="lottieFrameChanged($event)" [src]="answer.pictureSrc" style="width: 100%; height: 100%"> </lottie-player>
          <ng-lottie
            *ngIf="answer.imgType == 'json' && !comparison"
            (animationCreated)="animationCreated($event, index)"
            class="lottie_p"
            style="width: 100%; height: 100%"
            (mouseenter)="playAnim(index)"
            (mouseleave)="stopAnim(index)"
            [options]="{ path: answer.pictureSrc, autoplay: false }"
          >
          </ng-lottie>
        </div>
        <span *ngIf="game" class="title">{{ answer.text }}</span>
      </div>
    </div>
  </article>
  <div *ngIf="checked && checkRightAnswer" class="anim stars">
    <img src="/assets/images/anim/check_stars.png" alt="check" />
  </div>

  <ng-container>
    <div class="continue" [ngClass]="{ 'continue-visible': selectedAnswer && !checked }" *ngIf="!checked" (click)="checkAnswer()" mat-button>
      <img src="/assets/images/icons/check.svg" alt="check" />
      <span>{{ 'BUTTONS.CHECK' | translate }}</span>
    </div>
    <div appSounds class="continue" [ngClass]="{ 'continue-visible': checked }" *ngIf="checked" (click)="setAnswer(selectedAnswer)" mat-button>
      <span>{{ 'BUTTONS.CONTINUE' | translate }}</span>
    </div>
  </ng-container>

  <app-report-problem (click)="openReportPopup(question)"></app-report-problem>
</section>
