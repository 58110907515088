import { Component, Inject, OnInit } from '@angular/core'
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'
import { TranslateService } from '@ngx-translate/core'
import { StudentService } from '../../../../services/student.service'
import { openPdfLink } from '../../../../helpers/utils/LessonUtil/lessonTest.util'

@Component({
  templateUrl: './export-gradebook.component.html',
  styleUrls: ['./export-gradebook.component.scss'],
})
export class ExportGradebookDialogComponent implements OnInit {
  public selectedFormat = 'pdf'

  public formats = ['pdf', 'csv']

  public selectedStudent = false

  constructor(private _dialog: MatDialog, public dialogRef: MatDialogRef<ExportGradebookDialogComponent>, public translate: TranslateService, private _studentService: StudentService, @Inject(MAT_DIALOG_DATA) public $data: any) {}

  ngOnInit(): void {
    this.selectedStudent = !!this.$data.student
  }

  export() {
    const schoolId = this.$data.classroom.schoolId
    const classroomId = this.$data.classroom.id

    if (this.$data.student) {
      const studentId = this.$data.student.id

      this._studentService.exportStudentGradebook(schoolId, classroomId, studentId, this.selectedFormat).subscribe((res: any) => {
        const filename = `${this.$data.student.subuser.name} Gradebook.${this.selectedFormat.toLowerCase()}`
        if (this.selectedFormat === 'csv') {
          this.downloadCsv(res, filename)
        } else if (this.selectedFormat === 'pdf') {
          openPdfLink(res.pdf)
        }
        this.dialogRef.close()
      })
    } else {
      this._studentService.exportGradebook(schoolId, classroomId).subscribe((res: any) => {
        const filename = `${this.$data.classroom.classroomName} Gradebook.csv`
        this.downloadCsv(res, filename)
        this.dialogRef.close()
      })
    }
  }

  downloadCsv(data, filename) {
    const downloadLink = document.createElement('a')

    downloadLink.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(data)
    downloadLink.download = filename
    downloadLink.click()
    downloadLink.remove()
  }
}
