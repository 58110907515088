import { AbstractControl, FormControl } from '@angular/forms'

export const passwordMatcher = (abstractControl: AbstractControl) => {
  let password = abstractControl.get('password').value
  let confirmPassword = abstractControl.get('confirmPassword').value
  if (password != confirmPassword) {
    abstractControl.get('confirmPassword').setErrors({
      matchPassword: true,
    })
  }
  return null
}

export class PasswordValidator {
  passwordValidator(control: FormControl): { [key: string]: boolean } {
    const regexp: RegExp = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/
    if (control.value && !regexp.test(control.value)) {
      return { invalidPassword: true }
    }
  }
}
