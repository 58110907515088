import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from 'src/environments/environment'
import { IGetUnitWithLessonsRequest } from '../models/Lesson.model'

@Injectable({
  providedIn: 'root',
})
export class LessonsService {
  private gatewayUrl: string
  private apiUrl: string

  constructor(private http: HttpClient) {
    this.gatewayUrl = `${environment.gatewayUrl}course`
    this.apiUrl = `${environment.gatewayUrl}`
  }

  public getLessons(request: any) {
    const params: any = {
      courseId: request.courseId,
    }
    if (request.subuserId) {
      params.subuserId = request.subuserId
    }
    if (request.classroomId) {
      params.classroomId = request.classroomId
    }
    return this.http.get(`${this.gatewayUrl}/lessons`, {
      params,
    })
  }

  public getUnitWithLessons(request: IGetUnitWithLessonsRequest) {
    const params: any = {
      courseId: request.courseId,
      unitId: request.unitId,
    }
    if (request.subuserId) {
      params.subuserId = request.subuserId
    }
    if (request.ageRange) {
      params.ageRange = request.ageRange
    }
    if (request.classroomId) {
      params.classroomId = request.classroomId
    }
    return this.http.get(`${this.gatewayUrl}/lessons/unit/get`, {
      params,
    })
  }

  public getLesson(request: any) {
    const params: any = {
      lessonId: request.lessonId,
    }
    if (request.subuserId) {
      params.subuserId = request.subuserId
    }
    if (request.classroomId) {
      params.classroomId = request.classroomId
    }
    return this.http.get(`${this.gatewayUrl}/lessons/get`, {
      params,
    })
  }

  public getLessonWordsList(lessonId: any) {
    return this.http.get(`${this.gatewayUrl}/lessons/wordsList`, {
      params: {
        lessonId,
      },
    })
  }

  public getPrints(request: any) {
    const params: any = {
      courseId: request.courseId,
      printName: request.printName,
    }
    if (request.subuserId) {
      params.subuserId = request.subuserId
    }
    if (request.classroomId) {
      params.classroomId = request.classroomId
    }
    return this.http.get(`${this.gatewayUrl}/lessons/prints`, {
      params,
    })
  }

  public getCommonPrints() {
    return this.http.get(`${this.apiUrl}prints/common/get`)
  }

  public completeLesson(payload) {
    return this.http.post(`${this.gatewayUrl}/lessons/complete`, payload)
  }

  public completeUnit(payload) {
    return this.http.post(`${this.gatewayUrl}/unit/complete`, payload)
  }

  public getLessonsByType(request) {
    const params: any = {
      courseId: request.courseId,
      lessonsType: request.lessonsType,
    }
    if (request.subuserId) {
      params.subuserId = request.subuserId
    }
    if (request.classroomId) {
      params.classroomId = request.classroomId
    }
    return this.http.get(`${this.gatewayUrl}/lessons/type`, {
      params,
    })
  }

  public getLessonsByName(request) {
    const params: any = {
      courseId: request.courseId,
      lessonName: request.lessonName,
      lessonsType: request.lessonsType,
    }
    if (request.subuserId) {
      params.subuserId = request.subuserId
    }
    if (request.classroomId) {
      params.classroomId = request.classroomId
    }
    return this.http.get(`${this.gatewayUrl}/lessons/name`, {
      params,
    })
  }

  public getFiltersPrints(request) {
    return this.http.get(`${this.gatewayUrl}/lessons/filter/prints`, {
      params: {
        courseId: request.courseId,
        printName: request.printName,
      },
    })
  }

  public getLocalizations(lessonId: number, locale: string) {
    return this.http.get(`${environment.gatewayUrl}lesson/get/localizations`, {
      params: {
        id: lessonId,
        locale,
      },
    })
  }

  public getAvailableLocales(lessonId: number) {
    return this.http.get(`${environment.gatewayUrl}lesson/get/availablelocales`, {
      params: {
        id: lessonId,
      },
    })
  }
}
