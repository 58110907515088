import { Component, Inject } from '@angular/core'
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'
import { TranslateService } from '@ngx-translate/core'
import { ProgressReportsService } from '../../../../services/progress-reports.service'
import { openPdfLink } from '../../../../helpers/utils/LessonUtil/lessonTest.util'

@Component({
  templateUrl: './export-reports.component.html',
  styleUrls: ['./export-reports.component.scss'],
})
export class ExportReportsDialogComponent {
  public selectedFormat = 'pdf'

  public formats = ['pdf', 'csv']

  public startDate

  public endDate

  constructor(private _dialog: MatDialog, public dialogRef: MatDialogRef<ExportReportsDialogComponent>, public translate: TranslateService, private _progressReportsService: ProgressReportsService, @Inject(MAT_DIALOG_DATA) public $data: any) {}

  export() {
    const schoolId = this.$data.classroom.schoolId
    const classroomId = this.$data.classroom.id

    const payload = {
      startDate: this.startDate,
      endDate: this.endDate,
      format: this.selectedFormat,
    }

    this._progressReportsService.exportStudentsReports(schoolId, classroomId, payload).subscribe((res: any) => {
      const filename = `${this.$data.classroom.classroomName} Reports.${this.selectedFormat.toLowerCase()}`
      if (this.selectedFormat === 'csv') {
        this.downloadCsv(res, filename)
      } else if (this.selectedFormat === 'pdf') {
        openPdfLink(res.pdf)
      }
      this.dialogRef.close()
    })
  }

  downloadCsv(data, filename) {
    const downloadLink = document.createElement('a')

    downloadLink.href = 'data:text/csv;charset=utf-8, ' + encodeURIComponent(data)
    downloadLink.download = filename
    downloadLink.click()
    downloadLink.remove()
  }
}
