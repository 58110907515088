<section class="successfully">
  <div class="header">
    <div class="titleWrapper">
      <img src="assets/images/logos/logo-wy.svg" routerLink="/auth/login" class="logo" alt="DinoLingo Logo" />
    </div>
  </div>
  <article class="contentWrapper">
    <div>
      <h1 class="baloo">
        {{ 'SUCCESSFULLY.TITLE' | translate }}
      </h1>
      <h2 class="baloo">
        {{ 'SUCCESSFULLY.SUB_TITLE_STR_1' | translate }}
      </h2>
    </div>

    <div class="video-cont">
      <h3>{{ 'SUCCESSFULLY.INFO' | translate }}</h3>
      <video controls controlsList="nodownload">
        <source src="https://dinolingo-shared.s3.amazonaws.com/video/tutorial/video-tutorial.mp4" type="video/mp4" />
      </video>
    </div>

    <div>
      <h3>{{ 'SUCCESSFULLY.SUB_TITLE_STR_2' | translate }}</h3>
      <button appSounds routerLink="/profile/lessons" mat-raised-button color="accent">
        {{ 'BUTTONS.START' | translate }}
      </button>
    </div>
  </article>
</section>
