import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { select, Store } from '@ngrx/store'
import { TranslateService } from '@ngx-translate/core'
import { Subject } from 'rxjs'
import { filter, map, mergeMap, takeUntil } from 'rxjs/operators'
import { ConfirmationPopupComponent } from 'src/app/shared/popups/Control-Dialogs/confirmation/confirmation.component'
import { TestPlanComponent } from 'src/app/shared/popups/Lesson-Dialogs/test-plan/test-plan.component'
import { SetCurrentCourse } from 'src/app/store/actions/courses.actions'
import { selectCurrentLesson } from 'src/app/store/selectors/lessons.selectors'
import { downloadPdfLink, isIpad } from 'src/app/helpers/utils/LessonUtil/lessonTest.util'
import { ICourse } from '../../models/Course.model'
import { ISubuser } from '../../models/Subuser.model'
import { IUser } from '../../models/User.model'
import { FormatTextService } from '../../services/formatText.service'
import { LessonsService } from '../../services/lessons.service'
import { LocalStorageService } from '../../services/localStorage'
import { PrintsService } from '../../services/prints.service'
import { AddAssignmentDialogComponent } from '../../shared/popups/Assignment-Dialogs/add-assignment/add-assignment.component'
import { EditAssignmentDialogComponent } from '../../shared/popups/Assignment-Dialogs/edit-assignment/edit-assignment.component'
import { LessonSubscribePopupComponent } from '../../shared/popups/Lesson-Dialogs/lesson-subscribe/lesson-subscribe.component'
import { DisplayHeaderLesson, GetLesson, GetLessons, HideHeaderLesson } from '../../store/actions/lessons.actions'
import { selectClassrooms } from '../../store/selectors/classrooms.selectors'
import { selectUnitsWithLessons } from '../../store/selectors/lessons.selectors'
import { selectCurrentSubuser } from '../../store/selectors/subuser.selectors'
import { selectCurrentUser } from '../../store/selectors/user.selectors'
import { IAppState } from '../../store/state/app.state'
import { isAccessleCourses } from '../../helpers/utils/LessonUtil/lesson.util'
import { rangomSpinner } from '../../helpers/utils/spiner.util'

@Component({
  selector: 'app-curriculum',
  templateUrl: './curriculum.component.html',
  styleUrls: ['./curriculum.component.scss'],
})
export class CurriculumComponent implements OnInit, OnDestroy {
  @Input() isDialog
  @Output() loaded = new EventEmitter<boolean>()
  @Output() close = new EventEmitter<boolean>()

  constructor(
    private _store: Store<IAppState>,
    public translate: TranslateService,
    public testService: LessonsService,
    public _lessonsService: LessonsService,
    public _dialog: MatDialog,
    private _printService: PrintsService,
    private localStorageService: LocalStorageService,
    private textService: FormatTextService,
    private elementRef: ElementRef
  ) {}

  public panelOpenState = false
  public selectedSubuser: ISubuser
  public selectedCourse: ICourse
  public units = []
  public subuserCurrent
  public userSubuser
  public courseCurrent = null
  public courseUnits
  public prints
  public courseContentLength = 0

  public totalActivities: number

  public user: IUser
  public userType
  public type
  private unsubscribe$ = new Subject()

  public classrooms = []
  public selectedClassroom
  public virtualSubuser = null

  public userHasAccess = false
  public displayCourseId = 0
  public course: any
  public selectType
  public lessonName
  public data = false
  public isTeacherArea = false
  public loaderClass: string = rangomSpinner()
  isIpad = false

  public printLinkInactive = []

  public pdfLoading = false
  public pdfKeysAvailableLang = ['Spanish', 'French', 'German', 'Japanese', 'Korean', 'Polish', 'Portuguese - BR', 'Italian', 'English']

  @ViewChild('lessonsCont') lessonsCont: ElementRef
  @ViewChild('notFoundBlock') notFoundBlock: ElementRef

  ngOnInit() {
    // );
    this.data = false
    if (this.isDialog !== true) {
      this._store.dispatch(new HideHeaderLesson())
    }
    this.getLessonPlanContent()
  }

  ngAfterViewInit() {
    // scroll browser to the current lesson
    if (this.elementRef.nativeElement.querySelector('.current')) {
      this.elementRef.nativeElement.querySelector('.current').scrollIntoView({ behavior: 'smooth' })
    }
  }

  openTestPlanPopup(lesson) {
    this._dialog.open(TestPlanComponent, {
      panelClass: 'big-popup',
      closeOnNavigation: true,
      hasBackdrop: true,
      data: lesson,
    })
  }

  public formatLangName(lang) {
    return this.textService.formatLangName(lang)
  }

  public formatCourseName(course) {
    return this.textService.formatCourseName(course)
  }

  public formatMultiName(course) {
    return this.textService.formatMultiName(course)
  }

  ngOnDestroy() {
    if (this.isDialog !== true) {
      this._store.dispatch(new DisplayHeaderLesson())
    }
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  get subuserId() {
    if (['teacherAdmin', 'teacher'].includes(this.userType)) {
      this.isTeacherArea = true
      return null
    }

    return this.subuserCurrent.id
  }

  get courseId() {
    if (['teacherAdmin', 'teacher'].includes(this.userType)) {
      if (!this.selectedClassroom) {
        return null
      }

      return this.selectedClassroom.courseId
    }
    if (this.courseCurrent) {
      return this.courseCurrent.id
    }

    return this.subuserCurrent.courses[0].id
  }

  get courseName() {
    if (this.type === 'school') {
      return this.selectedClassroom.classroomName
    }

    return this.courseCurrent.name
  }

  get closeRoute() {
    if (this.type === 'school') {
      return '/profile/teachers/students'
    }

    return '/profile/lessons'
  }

  /**
   * GET LESSON CONTENT BY TYPE
   * @param lessonType
   */
  public getLessonsByType(lessonType) {
    const request: any = {
      subuserId: this.subuserId,
      courseId: this.courseId,
      lessonsType: lessonType,
    }

    if (this.type === 'school') {
      request.classroomId = this.selectedClassroom.id
    }

    this._lessonsService.getLessonsByType(request).subscribe((course) => {
      this.courseUnits = course
      this._rebuildUnits(this.courseUnits)
    })
  }
  /**
   * GET LESSONS CONTENT BY CURRENT CHILD
   */
  getLessonPlanContent() {
    this._store
      .select(selectCurrentUser)
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((user) => !!user),
        mergeMap((user) => {
          if (['teacherAdmin', 'teacher'].includes(user.userType)) {
            return this._store.select(selectClassrooms).pipe(
              takeUntil(this.unsubscribe$),
              map((classrooms) => {
                return {
                  user,
                  classrooms: classrooms.classrooms,
                  type: 'school',
                }
              })
            )
          }
          return this._store.select(selectCurrentSubuser).pipe(
            takeUntil(this.unsubscribe$),
            map((subuser) => {
              return {
                user,
                subuser,
                type: 'parent',
              }
            })
          )
        })
      )
      .subscribe((res: any) => {
        this.user = res.user
        this.userType = this.user.userType
        this.type = res.type
        if (this.type === 'school') {
          if (!res.classrooms.length) {
            return
          }

          this.classrooms = res.classrooms
          this.selectedClassroom = res.classrooms[0]
          if (res.user.user.subusers.length > 0) {
            this.virtualSubuser = res.user.user.subusers[0]
          }
        } else {
          this.userSubuser = res.user.user.subusers
          if (this.isDialog || this.userType == 'student') {
            this.subuserCurrent = res.subuser
            const localData = JSON.parse(this.localStorageService.getItem(`${this.subuserCurrent.id}`))
            this.courseCurrent = localData
          } else {
            const cch = JSON.parse(this.localStorageService.getItem('currentSubuser'))
            this.userSubuser.forEach((el, i) => {
              if (el.id == cch.id) {
                this.subuserCurrent = this.userSubuser[i]
                return
              }
            })
            const localDataCourse = JSON.parse(this.localStorageService.getItem(`${this.subuserCurrent.id}`))
            this.subuserCurrent.courses.forEach((el, i) => {
              if (localDataCourse.id == el.id) {
                this.courseCurrent = this.subuserCurrent.courses[i]
                return
              }
            })
          }
        }
        this.getLessonsContent('all')

        const course = {
          id: this.courseId,
        }

        this.userHasAccess = isAccessleCourses(this.user.accessibleCourses, course)
      })
  }

  getLessonsContent(lessonsType) {
    this.selectType = lessonsType

    const request: any = {
      subuserId: this.subuserId,
      courseId: this.courseId,
    }

    if (this.type === 'school') {
      request.classroomId = this.selectedClassroom.id
    }

    this._store.dispatch(new GetLessons(request))

    this.selectUnitLessons()
  }

  selectUnitLessons() {
    this.data = false
    this._store.pipe(takeUntil(this.unsubscribe$), select(selectUnitsWithLessons)).subscribe((res) => {
      if (res) {
        this.courseUnits = res
        this._rebuildUnits(this.courseUnits)
        this.totalActivities = 0
        res.units.forEach((element) => {
          this.totalActivities += element.lessons.length
          element.lessons.forEach((lesson) => {
            if (lesson.prints) {
              this.totalActivities += lesson.prints.length
            }
          })
        })
        this.data = true
        if (this.isDialog === true) {
          this.loaded.emit(true)
        }
      }
    })
  }

  filterLessons(value) {
    this.lessonsCont.nativeElement.childNodes.forEach((element, i) => {
      if (element.innerHTML) {
        let cnt = 0
        if (element.innerHTML.toLowerCase().includes(value.toLowerCase())) {
          element.classList.remove('invisible')
          this.notFoundBlock.nativeElement.classList.add('invisible')
        } else {
          element.classList.add('invisible')
          cnt++
          if (cnt === i) {
            this.notFoundBlock.nativeElement.classList.remove('invisible')
          }
        }
      }
    })
  }

  /**
   * FILTER LESSONS BY NAME
   * @param value
   * @returns
   */
  filterLessonsOld(value) {
    const request: any = {
      subuserId: this.subuserId,
      courseId: this.courseId,
      lessonName: value,
      lessonsType: this.selectType ? this.selectType : '',
    }

    if (this.type === 'school') {
      request.classroomId = this.selectedClassroom.id
    }

    this._lessonsService.getLessonsByName(request).subscribe((course) => {
      if (!course) {
        this.courseUnits = {
          units: [],
        }
      } else {
        this.courseUnits = course
      }
      this.courseContentLength = this.courseUnits.units.length
      this._rebuildUnits(this.courseUnits)
    })
  }

  private _rebuildUnits(units) {
    if (!units.length) {
      return
    }

    units.units.forEach((unit, a) => {
      this.units.push({
        name: unit.name,
        groups: [[]],
      })

      unit.lessons.forEach((lesson, b) => {
        if (lesson.typeId === 3 || lesson.typeId === 4) {
          this.units[a].groups[this.units[a].groups.length - 1].push(lesson)
          if (b < unit.lessons.length - 1) {
            this.units[a].groups.push([])
          }
        } else {
          this.units[a].groups[this.units[a].groups.length - 1].push(lesson)
        }
      })
    })
  }

  public showLessonPlan() {
    this.localStorageService.setItem(`${this.subuserCurrent.id}`, JSON.stringify(this.courseCurrent))

    const localCourse = this.localStorageService.getItem(`${this.subuserCurrent.id}`)

    const courses = JSON.parse(localCourse)
    this._store.dispatch(new SetCurrentCourse(courses))

    this.getLessonsContent('all')
  }

  /**
   * GET ALL PRINTS BY LESSONS
   */
  getPrints() {
    const request: any = {
      printName: null,
      subuserId: this.subuserId,
      courseId: this.courseId,
    }

    if (this.type === 'school') {
      request.classroomId = this.selectedClassroom.id
    }

    this._lessonsService.getPrints(request).subscribe((prints) => {
      this.prints = prints
      this.totalActivities = this.prints.count
    })
  }

  /**
   * REDIRECT TO LESSON IF USER HAS ACCESS TO THIS LESSON
   * @param lesson
   */
  goToLesson(lesson) {
    if (!lesson.isFree || !this.userHasAccess) {
      this._dialog.open(LessonSubscribePopupComponent, {})
    }
  }

  /**
   * SAVE PROGRESS PRINT MATERIAL
   * @param printId
   * @param repeat
   */
  completePrint(printId, repeat) {
    const request = {
      subuserId: this.subuserId ? this.subuserId : this.virtualSubuser.id,
      printId,
      repeat: repeat.length == 0 ? 1 : repeat[0].repeat + 1,
    }

    this._printService.completedPrint(request).subscribe(() => {
      if (this.selectType === 'prints') {
        this.getPrints()
        return
      }
      this.getLessonsContent('all')
    })
  }

  selectClassroom(classroom) {
    this.selectedClassroom = classroom
    this.getLessonsContent('all')
  }

  openAddAssignmentDialog(lesson) {
    this._dialog.open(AddAssignmentDialogComponent, {
      disableClose: false,
      hasBackdrop: true,
      width: '28rem',
      data: {
        schoolId: this.selectedClassroom.schoolId,
        classroomId: this.selectedClassroom.id,
        lessonId: lesson.id,
      },
    })
  }

  openEditAssignmentDialog(assignment) {
    this._dialog.open(EditAssignmentDialogComponent, {
      disableClose: false,
      hasBackdrop: true,
      width: '28rem',
      data: {
        schoolId: this.selectedClassroom.schoolId,
        classroomId: this.selectedClassroom.id,
        assignment,
      },
    })
  }

  closeCurriculum() {
    this.close.emit(true)
  }

  downloadPdf(lesson, index) {
    const dialog = this._dialog.open(ConfirmationPopupComponent, {
      panelClass: 'medium-adaptive-popup',
      closeOnNavigation: true,
      hasBackdrop: true,
      data: 'MODAL_CONFIRM.DOWNLOAD_PDF',
    })

    dialog.afterClosed().subscribe((answer) => {
      if (!answer) return
      this.openPrint(lesson, index)
    })
  }

  public getPDFKeywords() {
    if (!this.userHasAccess) {
      this._dialog.open(LessonSubscribePopupComponent, {})
      return
    }
    const dialog = this._dialog.open(ConfirmationPopupComponent, {
      panelClass: 'medium-adaptive-popup',
      closeOnNavigation: true,
      hasBackdrop: true,
      data: 'MODAL_CONFIRM.DOWNLOAD_HEAVY_PDF',
    })

    dialog.afterClosed().subscribe((answer) => {
      if (!answer) return
      let slug = `printed/${this.courseCurrent.name.replace(/\s/g, '')}/Worksheets/${this.courseCurrent.name.toUpperCase().replace(/\s/g, '')}-ANSWER-KEY-1.pdf`
      this.pdfLoading = true
      this._printService.getPrintPDF(slug).subscribe((pdf) => {
        downloadPdfLink(pdf, `${this.courseCurrent.name} Worksheets - Answer Keys.pdf`, false, isIpad())
        this.pdfLoading = false
      })
    })
  }

  openPrint(lesson, index) {
    if (this.printLinkInactive[index]) {
      return
    }
    this.printLinkInactive[index] = true

    const src = lesson.questions[0].src.match(/printed[^\.]+\.pdf/gi)
    this._printService.getPrintPDF(src).subscribe((pdf) => {
      // openPdfLink(pdf);

      downloadPdfLink(pdf, `${lesson.name}.pdf`, false, isIpad())
      this.printLinkInactive = []
    })

    // window.open(lesson.questions[0].src, '_blank');
    const progress = 100
    const repeats = lesson.completed ? lesson.completed.repeat : 0
    const request = {
      subuserStars: 0,
      subuserId: this.subuserId ? this.subuserId : this.virtualSubuser.id,
      lessonId: lesson.id,
      progress,
      courseId: this.courseId,
      dinosaurId: null,
      repeat: repeats + 1,
    }
    this._lessonsService.completeLesson(request).subscribe(() => {
      this.getLessonsContent('all')
    })
  }

  openPrintOld(printId) {
    this._store.dispatch(
      new GetLesson({
        lessonId: printId,
        subuserId: this.subuserId ? this.subuserId : this.virtualSubuser.id,
      })
    )
    this._store.pipe(takeUntil(this.unsubscribe$), select(selectCurrentLesson)).subscribe((lesson) => {
      if (lesson) {
        window.open(lesson.questions[0].src, '_blank')
        const progress = 100
        const repeats = lesson.completed ? lesson.completed.repeat : 0
        const request = {
          subuserStars: 0,
          subuserId: this.subuserId ? this.subuserId : this.virtualSubuser.id,
          lessonId: printId,
          progress,
          courseId: this.courseId,
          dinosaurId: null,
          repeat: repeats + 1,
        }
        this._lessonsService.completeLesson(request).subscribe(() => {
          this.getLessonsContent('all')
        })
      }
    })
  }
}
