import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common'
import { ParentsProfileComponent } from './profile.component'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { TranslateModule } from '@ngx-translate/core'
import { EditProfileDialogModule } from 'src/app/shared/popups/Parent-Dialogs/editProfile/editProfile.module'
import { ChangePasswordDialogModule } from 'src/app/shared/popups/Parent-Dialogs/changePassword/changePassword.module'
import { SoundsModule } from 'src/app/helpers/directives/sounds-directive/sounds.module'
import { LocalizationModule } from "../../../../shared/components/localization/localization.module";

@NgModule({
    declarations: [ParentsProfileComponent],
    // entryComponents: [
    //     EditProfileDialogComponent,
    //     ChangePasswordDialogComponent
    // ],
    providers: [],
    imports: [CommonModule, FormsModule, RouterModule, EditProfileDialogModule, ChangePasswordDialogModule, MatButtonModule, TranslateModule, SoundsModule, LocalizationModule]
})
export class ParentsProfileModule {}
