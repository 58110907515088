import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { FormsModule } from '@angular/forms'
import { TextToAudioGameComponent } from './text-to-audio.component'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatIconModule } from '@angular/material/icon'
import { TranslateModule } from '@ngx-translate/core'
import { GameLocalizationPipeModule } from '../../../helpers/pipes/game-localization/game-localization-pipe.module'
import { ReportProblemModule } from "../../components/report-problem/report-problem.module";

@NgModule({
    declarations: [TextToAudioGameComponent],
    exports: [TextToAudioGameComponent],
    imports: [CommonModule, FormsModule, RouterModule, MatButtonModule, MatIconModule, TranslateModule, GameLocalizationPipeModule, ReportProblemModule]
})
export class TextToAudioGameModule {}
