<section class="profile-sidebar">
  <nav class="navigation">
    <div class="navigation-content">
      <div class="logo">
        <img src="/assets/images/logos/dinolingo-brand-logo-1.svg" [routerLink]="logoLink" alt="DinoLingo Logo" />
      </div>
      <div class="menu">
        <!-- Go to Map Button -->
        <ng-container *ngIf="user?.userType === 'student' || user?.userType === 'parent'">
          <div class="icon-text" routerLink="/profile/lessons" routerLinkActive="active activeMobile">
            <div class="icon">
              <img src="/assets/images/icons/menuGoToMapGold.svg" alt="" />
            </div>
            <div class="text">
              <span>{{ 'HEADER_MENU.LESSON' | translate }}</span>
            </div>
          </div>
        </ng-container>
        <!-- Lesson Plan -->
        <ng-container *ngIf="user?.userType !== 'student'">
          <div class="icon-text bordered" routerLink="/profile/lesson-plan" routerLinkActive="active activeMobile">
            <div class="icon">
              <img src="/assets/images/icons/menuLessonGold.svg" alt="" />
            </div>
            <div class="text">
              <span>{{ 'LINKS.LESSON_PLAN' | translate }}</span>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="user?.userType == 'student'">
          <div class="icon-text bordered" routerLink="/profile/lesson-plan" routerLinkActive="active activeMobile">
            <div class="icon">
              <img src="/assets/images/icons/menuLessonGold.svg" alt="" />
            </div>
            <div class="text">
              <span>{{ 'LINKS.LESSON_PLAN' | translate }}</span>
            </div>
          </div>
        </ng-container>
        <!-- Gradebook -->
        <ng-container *ngIf="user?.userType == 'student'">
          <div class="icon-text bordered" routerLink="/profile/parents/gradebook" routerLinkActive="active activeMobile">
            <div class="icon">
              <img src="/assets/images/icons/report-btn-blue.svg" alt="" />
            </div>
            <div class="text">
              <span>{{ 'LINKS.GRADEBOOK' | translate }}</span>
            </div>
          </div>
        </ng-container>
        <!-- Progress Report -->
        <ng-container *ngIf="teacherOrParent">
          <div class="icon-text bordered" routerLink="/profile/{{ teacherOrParent }}/progress-reports" routerLinkActive="active activeMobile">
            <div class="icon">
              <img src="/assets/images/icons/menuProgressReportGold.svg" alt="" />
            </div>
            <div class="text">
              <span>{{ 'LINKS.PROGRESS' | translate }}</span>
            </div>
          </div>
        </ng-container>
        <!-- Users Design If Parents -->
        <ng-container *ngIf="user?.userType === 'parent'">
          <div class="icon-text bordered" routerLink="/profile/parents/subusers" routerLinkActive="active activeMobile">
            <div class="icon">
              <img src="/assets/images/icons/menuUsersGold.svg" alt="" />
            </div>
            <div class="text">
              <span>{{ 'LINKS.CHILDREN' | translate }}</span>
            </div>
          </div>
        </ng-container>
        <!-- Classroom -->
        <ng-container *ngIf="['teacher', 'teacherAdmin'].includes(user?.userType)">
          <div class="icon-text bordered" routerLink="/profile/teachers/students" routerLinkActive="active activeMobile">
            <div class="icon">
              <img src="/assets/images/icons/menuClassroomGold.svg" alt="" />
            </div>
            <div class="text">
              <span>{{ 'LINKS.TEACHER_DASHBOARD' | translate }}</span>
            </div>
          </div>
        </ng-container>
        <!-- Gradebook -->
        <ng-container *ngIf="user.userType === 'teacher' || user.userType === 'teacherAdmin'">
          <div class="icon-text bordered" routerLink="/profile/teachers/gradebook" routerLinkActive="active activeMobile">
            <div class="icon">
              <img src="/assets/images/icons/menuGradebookGold.svg" alt="" />
            </div>
            <div class="text">
              <span>{{ 'LINKS.GRADEBOOK' | translate }}</span>
            </div>
          </div>
        </ng-container>
        <!-- Teachers -->
        <ng-container *ngIf="user.userType === 'teacherAdmin'">
          <div class="icon-text bordered" routerLink="/profile/teachers/teachers" routerLinkActive="active activeMobile">
            <div class="icon">
              <img src="/assets/images/icons/menuUsersGold.svg" alt="" />
            </div>
            <div class="text">
              <span>{{ 'LINKS.TEACHERS' | translate }}</span>
            </div>
          </div>
        </ng-container>
        <!-- My Profile -->
        <ng-container *ngIf="teacherOrParent">
          <div class="icon-text bordered" routerLink="/profile/{{ teacherOrParent }}/profile" routerLinkActive="active activeMobile">
            <div class="icon">
              <img src="/assets/images/icons/menuProfileGold.svg" alt="" />
            </div>
            <div class="text">
              <span>{{ 'LINKS.PROFILE' | translate }}</span>
            </div>
          </div>
        </ng-container>

        <!-- Subscriptions -->
        <ng-container *ngIf="user.userType === 'parent'">
          <div class="icon-text bordered" routerLink="/profile/{{ teacherOrParent }}/subscriptions/stripe" routerLinkActive="active activeMobile">
            <div class="icon">
              <img src="/assets/images/icons/Group 723.svg" alt="" />
            </div>
            <div class="text">
              <span>{{ 'LINKS.SUBSCRIPTIONS' | translate }}</span>
            </div>
          </div>
        </ng-container>

        <!-- Tutorials -->
        <ng-container *ngIf="teacherOrParent">
          <div class="icon-text bordered" routerLink="/profile/{{ teacherOrParent }}/tutorials" routerLinkActive="active activeMobile">
            <div class="icon">
              <img src="/assets/images/icons/menuTutorialGold.svg" alt="" />
            </div>
            <div class="text">
              <span>{{ 'LINKS.TUTORIALS' | translate }}</span>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="user.userType === 'student'">
          <div class="icon-text bordered" routerLink="/profile/parents/studentTutorials" routerLinkActive="active activeMobile">
            <div class="icon">
              <img src="/assets/images/icons/menuTutorialGold.svg" alt="" />
            </div>
            <div class="text">
              <span>{{ 'LINKS.TUTORIALS' | translate }}</span>
            </div>
          </div>
        </ng-container>
        <!-- Help -->
        <div class="icon-text" (click)="goToHelp()">
          <div class="icon">
            <img src="/assets/images/icons/menuHelpGold.svg" alt="" />
          </div>
          <div class="text">
            <span>{{ 'LINKS.HELP' | translate }}</span>
          </div>
        </div>
      </div>
    </div>
  </nav>
</section>
