import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { FormsModule } from '@angular/forms'
import { WhatComeAfterGameComponent } from './what-come-after.component'
import { MatIconModule } from '@angular/material/icon'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatRippleModule } from '@angular/material/core'
import { TranslateModule } from '@ngx-translate/core'
import { ReportProblemModule } from "../../components/report-problem/report-problem.module";

@NgModule({
    declarations: [WhatComeAfterGameComponent],
    exports: [WhatComeAfterGameComponent],
    imports: [CommonModule, FormsModule, RouterModule, MatButtonModule, MatIconModule, MatRippleModule, TranslateModule, ReportProblemModule]
})
export class WhatComeAfterGameModule {}
