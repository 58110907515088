<section class="testResult">
  <article>
    <div class="user-name">
      <div class="close" (click)="dialogRef.close()"><img src="/assets/images/icons/vectorCross.svg" alt="vectorCross" /></div>
      <div class="title">{{ this.data.nickname }}</div>
    </div>
    <div class="actions">
      <div>
        <span class="title">{{ data.lessonName }} {{ 'TEST_RESULT.TITLE' | translate }} </span>
        <span class="download" (click)="downlodResultPdf()">{{ 'BUTTONS.DOWNLOAD' | translate }}</span>
        <span class="show" (click)="showPdf()">{{ 'BUTTONS.PRINT' | translate }}</span>
      </div>
    </div>
    <div class="content scroll-modern">
      <div class="date">
        {{ data.reportData.createdAt | date : 'MMM dd, yyyy' }}
      </div>
      <div class="common">
        <span>{{ data.reportData.progress }}%</span>
        {{ 'TEST_RESULT.CORRECT' | translate }}
      </div>

      <div class="common">
        <span>{{ data.reportData.correctAnswers }}</span>
        {{ 'TEST_RESULT.TEXT_1' | translate }}
        <span>{{ data.reportData.allAnswers }}</span>
        {{ 'TEST_RESULT.TEXT_2' | translate }}
      </div>
      <div class="details">
        <!-- START cycle -->
        <div class="qa-container" *ngFor="let record of data.reportData.report; let recordIndex = index">
          <div class="type">
            <span>{{ recordIndex + 1 }}. </span><span>{{ record.question.questionType.name }}</span>
          </div>
          <div *ngIf="record.question.questionType.slug == 'atp'" class="description">
            {{ 'TEST_RESULT.AUDIO' | translate }}
            <span>{{ record.question.text }}</span>
          </div>
          <div *ngIf="record.question.questionType.slug == 'pta'" class="description">
            {{ 'TEST_RESULT.IMG' | translate }}
            <span>{{ record.question.text }}</span>
          </div>
          <ng-container *ngIf="record.question.answers[0].correct">
            <div *ngIf="record.question.questionType.slug == 'tof'" class="description">
              {{ 'TEST_RESULT.IMG_AUDIO' | translate }}
              <span>{{ record.question.text }}</span>
            </div>
          </ng-container>
          <ng-container *ngIf="!record.question.answers[0].correct">
            <div *ngIf="record.question.questionType.slug == 'tof'" class="description">
              {{ 'TEST_RESULT.IMG' | translate }}
              <span>{{ record.question.text }}</span
              >, {{ 'TEST_RESULT.AUDIO' | translate }}
              <span>{{ record.question.answers[0].text }}</span>
            </div>
          </ng-container>
          <div *ngIf="['ttt_target', 'ttt_local', 'tta_target', 'tta_local'].includes(record.question.questionType.slug)" class="description">
            {{ 'TEST_RESULT.TEXT' | translate }}
            <span>{{ record.question.text }}</span>
          </div>
          <div *ngIf="['ata_target', 'ata_local', 'att_target', 'att_local'].includes(record.question.questionType.slug)" class="description">
            {{ 'TEST_RESULT.AUDIO' | translate }}
            <span>{{ record.question.text }}</span>
          </div>
          <div class="answers">
            <div class="title">
              {{ 'TEST_RESULT.ANSW' | translate }}
              <span *ngIf="record.question.questionType.slug == 'atp'"> ({{ 'TEST_RESULT.TEXT_IMG' | translate }})</span>
              <span *ngIf="record.question.questionType.slug == 'pta'"> ({{ 'TEST_RESULT.TEXT_AUDIO' | translate }})</span>
              :
            </div>
            <ng-container *ngIf="!['tof', 'art'].includes(record.question.questionType.slug)">
              <ng-container *ngFor="let answer of record.question.answers">
                <div
                  class="answer"
                  [ngClass]="{
                    correct: answer.correct === true,
                    incorrect: !answer.correct && answer.subuserAnswer === true
                  }"
                >
                  <mat-icon *ngIf="answer.subuserAnswer === true && answer.correct === true" class="arrow right">check</mat-icon><span>{{ answer.text }}</span>

                  <mat-icon *ngIf="answer.subuserAnswer === true && !answer.correct" class="arrow right"> close</mat-icon>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="record.question.questionType.slug == 'tof'">
              <div
                class="answer"
                [ngClass]="{
                  correct: record.question.answers[0].correct === true,
                  incorrect: !record.question.answers[0].correct && !record.question.answers[0].subuserAnswer
                }"
              >
                <mat-icon *ngIf="record.question.answers[0].correct === true && record.question.answers[0].subuserAnswer" class="arrow right">check</mat-icon>
                <mat-icon *ngIf="!record.question.answers[0].correct && !record.question.answers[0].subuserAnswer" class="arrow right">close</mat-icon>
                <span> {{ 'TEST_RESULT.TRUE' | translate }}</span>
              </div>
              <div
                class="answer"
                [ngClass]="{
                  correct: !record.question.answers[0].correct,
                  incorrect: record.question.answers[0].correct === true && !record.question.answers[0].subuserAnswer
                }"
              >
                <mat-icon *ngIf="!record.question.answers[0].correct && record.question.answers[0].subuserAnswer" class="arrow right">check</mat-icon>
                <mat-icon *ngIf="record.question.answers[0].correct && !record.question.answers[0].subuserAnswer" class="arrow right">close</mat-icon>
                <span>{{ 'TEST_RESULT.FALSE' | translate }}</span>
              </div>
            </ng-container>
            <ng-container *ngIf="record.question.questionType.slug === 'art'">
              <ng-container *ngFor="let answer of arrangeTextAnswers(record)">
                <div
                  class="answer"
                  [ngClass]="{
                    correct: answer.subuserAnswer.correct === true,
                    incorrect: answer.subuserAnswer.correct === false
                  }"
                >
                  <mat-icon *ngIf="answer.subuserAnswer.correct === true" class="arrow right">check</mat-icon><span>{{ answer.text }}</span>

                  <mat-icon *ngIf="answer.subuserAnswer.correct === false" class="arrow right"> close</mat-icon>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
        <!-- END cycle -->
      </div>
      <div class="end">{{ 'TEST_RESULT.END' | translate }}</div>
    </div>
  </article>
</section>
