import { Component, Inject, OnInit } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'
import { Actions, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { interval, Subject } from 'rxjs'
import { map, takeUntil } from 'rxjs/operators'
import { IAssignmentRequest } from '../../../../models/Assignment.model'
import { DeleteAssignment, EAssignmentActions, EditAssignment, EditAssignmentSuccess } from '../../../../store/actions/assignment.actions'
import { IAppState } from '../../../../store/state/app.state'

@Component({
  templateUrl: './edit-assignment.component.html',
  styleUrls: ['./edit-assignment.component.scss'],
})
export class EditAssignmentDialogComponent implements OnInit {
  public form: UntypedFormGroup

  private destroyed$ = new Subject()

  private unsubscribe$ = new Subject()

  public deleteMode = false

  public deleteSecondsLeft = 5

  private countdown$

  constructor(private _actions$: Actions, public dialogRef: MatDialogRef<EditAssignmentDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private _store: Store<IAppState>) {
    this._actions$
      .pipe(
        takeUntil(this.destroyed$),
        ofType<EditAssignmentSuccess>(EAssignmentActions.EditAssignmentSuccess),
        map((res: EditAssignmentSuccess) => res.assignment)
      )
      .subscribe((assignment) => {
        this.dialogRef.close(assignment)
      })
  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      dueDate: new UntypedFormControl(this.data.assignment.dueDate, [Validators.required]),
    })
  }

  editAssignment(form: UntypedFormGroup) {
    if (form.invalid) return

    const request: IAssignmentRequest = this.form.value

    this._store.dispatch(new EditAssignment(this.data.schoolId, this.data.classroomId, this.data.assignment.id, request))
  }

  deleteAssignment() {
    this.deleteMode = true
    this.countdown$ = interval(1000)
    this.countdown$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.deleteSecondsLeft--

      if (this.deleteSecondsLeft === 0) {
        this.unsubscribe$.next()
      }
    })
  }

  cancelDelete() {
    this.deleteMode = false
    this.deleteSecondsLeft = 5
    this.unsubscribe$.next()
  }

  confirmDelete() {
    this._store.dispatch(new DeleteAssignment(this.data.schoolId, this.data.classroomId, this.data.assignment.id))
    this._actions$.pipe(ofType(EAssignmentActions.DeleteAssignmentSuccess)).subscribe((res: any) => {
      if (res.payload.status === 204) {
        this.dialogRef.close()
      }
    })
  }
}
