import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Observable, of } from 'rxjs'
import { catchError, exhaustMap, map, mergeMap } from 'rxjs/operators'
import { NotificationService } from 'src/app/services/notification.service'
import { SubuserService } from 'src/app/services/subuser.service'
import { TestReportService } from 'src/app/services/testReport.service'
import { LocalStorageService } from '../../services/localStorage'
import {
  EStatisticsActions,
  GetAchievements,
  GetAchievementsSuccess,
  GetDinosaurs,
  GetDinosaursSuccess,
  GetOpenDinosaurs,
  GetOpenDinosaursSuccess,
  GetStars,
  GetStarsSuccess,
  GetStatistics,
  GetStatisticsSuccess,
  GetTestReport,
  GetTestReportSuccess,
} from '../actions/statistics.actions'

@Injectable()
export class StatisticsEffects {
  getStatistics$: Observable<GetStatisticsSuccess> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetStatistics>(EStatisticsActions.GetStatistics),
      map((action: GetStatistics) => action.payload),
      exhaustMap((statistics: any) => this._subuserService.getSubuserStatistics(statistics)),
      exhaustMap((stats: any) => of(new GetStatisticsSuccess(stats))),
      catchError((err, caught) => {
        const { error } = err
        if (err.status === 500) {
          this._notificationService.showNotificationError(error.clientMessage, 2)
          this.localStorageService.clear()
          location.reload()
        }

        return caught
      })
    )
  )

  getDinosaurs$: Observable<GetDinosaursSuccess> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetDinosaurs>(EStatisticsActions.GetDinosaurs),
      map((action: GetDinosaurs) => action.payload),
      mergeMap((id: number) => this._subuserService.getSubuserDinosaurs(id)),
      mergeMap((dinosaurs: any) => of(new GetDinosaursSuccess(dinosaurs))),
      catchError((err, caught) => {
        const { error } = err
        this._notificationService.showNotificationError(error.clientMessage, 2)
        return caught
      })
    )
  )

  getOpenDinosaurs$: Observable<GetOpenDinosaursSuccess> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetOpenDinosaurs>(EStatisticsActions.GetOpenDinosaurs),
      map((action: GetOpenDinosaurs) => action.payload),
      mergeMap((id: number) => this._subuserService.getSubuserOpenDinosaurs(id)),
      mergeMap((dinosaurs: any) => of(new GetOpenDinosaursSuccess(dinosaurs))),
      catchError((err, caught) => {
        const { error } = err
        this._notificationService.showNotificationError(error.clientMessage, 2)
        return caught
      })
    )
  )

  getAchievements$: Observable<GetAchievementsSuccess> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetAchievements>(EStatisticsActions.GetAchievements),
      map((action: GetAchievements) => action.payload),
      mergeMap((id: number) => this._subuserService.getSubuserAchievements(id)),
      mergeMap((achievements: any) => of(new GetAchievementsSuccess(achievements))),
      catchError((err, caught) => {
        const { error } = err
        this._notificationService.showNotificationError(error.clientMessage, 2)
        return caught
      })
    )
  )

  getStars$: Observable<GetStarsSuccess> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetStars>(EStatisticsActions.GetStars),
      map((action: GetStars) => action.payload),
      mergeMap((id: number) => this._subuserService.getSubuserStars(id)),
      mergeMap((stars: any) => of(new GetStarsSuccess(stars))),
      catchError((err, caught) => {
        const { error } = err
        this._notificationService.showNotificationError(error.clientMessage, 2)
        return caught
      })
    )
  )

  getTestReport$: Observable<GetTestReportSuccess> = createEffect(() =>
    this._actions$.pipe(
      ofType<GetTestReport>(EStatisticsActions.GetTestReport),
      map((action: GetTestReport) => action.payload),
      exhaustMap((request: any) => this._testReportService.getTestReport(request)),
      exhaustMap((testReports: any) => of(new GetTestReportSuccess(testReports))),
      catchError((err, caught) => {
        const { error } = err
        this._notificationService.showNotificationError(error.clientMessage, 2)
        return caught
      })
    )
  )

  constructor(private _actions$: Actions, private _subuserService: SubuserService, private _testReportService: TestReportService, private _notificationService: NotificationService, private localStorageService: LocalStorageService) {}
}
