import { HttpRequest } from '@angular/common/http'
import { LocalStorageService } from '../../services/localStorage'

const localStorageService = new LocalStorageService()

export interface IAuthState {
  accessToken: string
  latestRequest: HttpRequest<any>
}

export const InitialAuthState: IAuthState = {
  accessToken: localStorageService.getItem('accessToken'),
  latestRequest: null,
}
