import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { LessonComponent } from './lesson.component'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { MatIconModule } from '@angular/material/icon'
import { MatStepperModule } from '@angular/material/stepper'
import { ProgressBarModule } from 'src/app/shared/components/dino-progressbar/progress.module'
import { AudioToPictureGameModule } from 'src/app/shared/games/audio-to-picture/audio-to-picture.module'
import { MemoryCardsGameModule } from 'src/app/shared/games/memory-cards/memory-cards.module'
import { CorrectOrIncorrectGameModule } from 'src/app/shared/games/correct-or-incorrect/correct-or-incorrect.module'
import { PictureToAudioGameModule } from 'src/app/shared/games/picture-to-audio/picture-to-audio.module'
import { ClickAndTellGameModule } from 'src/app/shared/games/click-and-tell/click-and-tell.module'
import { BookPlayerModule } from 'src/app/shared/components/book-player/media-player.module'
import { TranslateModule } from '@ngx-translate/core'
import { AudioToTextGameModule } from 'src/app/shared/games/audio-to-text/audio-to-text.module'
import { FillTheBlankGameModule } from 'src/app/shared/games/fill-the-blank/fill-the-blank.module'
import { WhatComeAfterGameModule } from 'src/app/shared/games/what-come-after/what-come-after.module'
import { TextToAudioGameModule } from 'src/app/shared/games/text-to-audio/text-to-audio.module'
import { LessonEndPopupModule } from 'src/app/shared/popups/Lesson-Dialogs/lesson-end/lesson-end.module'
import { SoundsModule } from 'src/app/helpers/directives/sounds-directive/sounds.module'
import { TextToTextGameModule } from '../../../../shared/games/text-to-text/text-to-text.module'
import { AudioToAudioGameModule } from '../../../../shared/games/audio-to-audio/audio-to-audio.module'
import { ArrangeTextGameModule } from '../../../../shared/games/arrange-text/arrange-text.module'
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select'
import { LocalizationDropdownModule } from './localization-dropdown/localization-dropdown.module'

@NgModule({
  declarations: [LessonComponent],

  imports: [
    RouterModule,
    CommonModule,
    MatButtonModule,
    MatStepperModule,
    ProgressBarModule,
    MatIconModule,
    AudioToPictureGameModule,
    MemoryCardsGameModule,
    CorrectOrIncorrectGameModule,
    PictureToAudioGameModule,
    ClickAndTellGameModule,
    AudioToTextGameModule,
    FillTheBlankGameModule,
    BookPlayerModule,
    LessonEndPopupModule,
    WhatComeAfterGameModule,
    TextToAudioGameModule,
    TranslateModule,
    SoundsModule,
    TextToTextGameModule,
    AudioToAudioGameModule,
    ArrangeTextGameModule,
    MatSelectModule,
    LocalizationDropdownModule,
  ],
})
export class LessonModule {}
