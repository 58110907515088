import { Injectable } from '@angular/core'
import { LocalStorageService } from './localStorage'

@Injectable({
  providedIn: 'root',
})
export class JWTService {
  private accessTokenName: string = 'accessToken'

  constructor(private localStorageService: LocalStorageService) {}

  public setToken(token: string): void {
    this.localStorageService.setItem(this.accessTokenName, token)
  }

  get getToken(): string | null {
    return localStorage.getItem(this.accessTokenName) || null
  }

  public deleteToken(): boolean {
    this.localStorageService.removeItem(this.accessTokenName)
    return true
  }

  public parseToken() {
    const base64Url = this.getToken.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join('')
    )

    return JSON.parse(jsonPayload)
  }
}
